<template>
<!-- Don't rely on css class in mint-ui directly -->
  <div class="close-icon">
    <!-- <i class="mintui mintui-close"></i> -->
    <i class="fas fa-times"></i>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped>

</style>
