import store from '@/store'
import updateService from './update'
import {
  Media
} from '@capacitor-community/media';

import 'capacitor-plugin-file-downloader';

import {
  Plugins,
  HapticsImpactStyle,
  FilesystemDirectory,
  FilesystemEncoding
} from '@capacitor/core';
const {
  Haptics,
  App,
  Browser,
  Clipboard,
  FileDownloader,
  Filesystem,
  Modals,
  Network,
  SplashScreen,
  StatusBar,
  Storage,
  Toast
} = Plugins;

const media = new Media();


export default {
  startScan(url, onFinish) {
    if (window.___env === 'webview')
      window.___startScan(url, onFinish);
  },

  async exitApp() {
    if (store.state.deviceInfo.platform === 'web') return;

    try {
      let confirmRet = await Modals.confirm({
        title: '退出',
        message: '确认退出门口易测吗?'
      });
      if (confirmRet.value) App.exitApp()
    } catch (err) {
      window.__err = err
    }
  },

  pushBackButtonCallback(key, callback) {
    if (window.___env === 'webview')
      window.___backbuttonCallbacks.push({
        key,
        callback
      });
  },

  popBackButtonCallback(key) {
    if (window.___env === 'webview')
      window.___backbuttonCallbacks = window.___backbuttonCallbacks.filter(c => c.key !== key);
  },

  wechatIsInstalled() {
    if (window.___env === 'webview')
      return window.___wechatIsInstalled ? true : false;
  },

  shareAppToWechat(type) {
    if (window.___env === 'webview')
      window.___shareAppToWechat(type);
  },

  authByWechat() {
    if (window.___env === 'webview')
      return window.___authByWechat();
  },

  openLink(link) {
    return Browser.open({
      url: link
    });
  },

  browserOpen(options) {
    return Browser.open(options);
  },

  async update(manual = true) {
    
    if (!manual) {
      // only run auto update checks once per 24 hours

      const get_lastAutoUpdate = await Storage.get({ key: 'lastAutoUpdate' });
      
      let lastAutoUpdate = Number(get_lastAutoUpdate.value);
      
      if (typeof lastAutoUpdate !== 'number')  lastAutoUpdate = 0;
      
      if (Date.now() - lastAutoUpdate < 86400000) return; // skip if last update check less than 24 hours ago
      
      await Storage.set({key:'lastAutoUpdate',value: JSON.stringify(Date.now())});

    }

    if (store.state.deviceInfo.platform === 'web') {
      
      updateService.pwa_update(manual)
    
    } else if (store.state.deviceInfo.platform === 'android') {
      
      updateService.android_update(manual)
    
    }
  },

  async downloadBase64Image(dataUrl, filename) {
    if (store.state.deviceInfo.platform === 'web') {
      // const base64url = dataUrl
      // const blob = await (await fetch(base64url)).blob();
      // const file = new File([blob], filename, {
      //   type: blob.type
      // });

      // if (navigator.canShare && navigator.canShare({
      //     files: [file]
      //   })) {
      //   navigator.share({
      //       title: '答题卡',
      //       text: '门口易测答题卡',
      //       files: [file],
      //     })
      //     .then(() => console.log('Share was successful.'))
      //     .catch((error) => console.log('Sharing failed', error));
      // } else {
      // console.log(`Your system doesn't support sharing files.`);
      // const tag = document.createElement('a');
      // tag.setAttribute('download', filename)
      // tag.href = dataUrl;
      // tag.click();
      // }

      const tag = document.createElement('a');
      tag.setAttribute('download', filename)
      tag.href = dataUrl;
      tag.click();

    } else {
      let appendFileRes
      try {
        appendFileRes = await Filesystem.writeFile({
          path: `${filename}`,
          data: dataUrl,
          directory: FilesystemDirectory.Documents,
          // encoding: FilesystemEncoding.UTF8
        });
      } catch (err) {
        console.log(err)
      }

      let saveOptions = {
        path: appendFileRes.uri
      }

      if (store.state.deviceInfo.platform === 'android') {
        saveOptions.album = '门口易测Mkyice'
      }

      if (store.state.deviceInfo.platform === 'ios') {
        try {
          const saveReq = await media.savePhoto(saveOptions);
        } catch (err) {
          console.log(err)
          Toast.show({
            text: '下载失败'
          });
        }
      }

      Toast.show({
        text: '已保存到相册'
      });
    }
  },

  changeStatusBarColor(colorStr) {
    try {
      StatusBar.setBackgroundColor({
        color: colorStr
      })
    } catch (err) {
      window.__err = err
    }
  },

  hideSplashScreen() {
    if (store.state.deviceInfo.platform === 'web') return;
    SplashScreen.hide();
  },

  downloadImage(url, onSuccess, onError) {
    if (window.___env === 'webview')
      window.___downloadImage(url, onSuccess, onError);
  },

  copyToClipboard(string) {
    Clipboard.write({
      string: string
    });
  },

  checkIsAndroid4() {
    if (window.___env === 'webview')
      return window.___checkIsAndroid4();
  },

  acoustic() {
    if (window.___env === 'webview')
      window.___nativeAcoustic();
  },

  haptic() {
    if (store.state.deviceInfo.platform === 'web') {
      try {
        window.navigator.vibrate(35)
      } catch(err) {
        console.log(err)
      }
    } else {
      try {
        Haptics.impact({
          style: HapticsImpactStyle.Light
        });
      } catch (err) {
        window.__err = err
        window.navigator.vibrate(35)
      }
    }
  },

  // Cordova  webview rely on 'cordova network information plugin'
  // Gradecam webview rely on 'browser web api'
  async getNetworkStatus() {
    let status = await Network.getStatus();
    if (status && status.connected) return 'online';
    return 'offline'
  },

  finishScan(result) {
    if (window.___env === 'webview_scan')
      window.___finishScan(result);
  },

  get_mId() {

    let getUrlParameter = (name, search) => {
      name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
      let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
      let results = regex.exec(search);
      return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    };

    let pathname = location.pathname.replace(/\/$/, '');
    let pageFile = pathname.substr(pathname.lastIndexOf('/') + 1).toLowerCase();

    //differentiate 'scan.html' vs 'index.html'

    if (pageFile.indexOf('scan') >= 0)
      return getUrlParameter('mId', location.hash);
    else
      return window.localStorage.getItem('mId');

  },

  get_uav() {
    if (window.___env === 'webview') {
      let getUrlParameter = (name, search) => {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        let results = regex.exec(search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
      };

      let pathname = location.pathname.replace(/\/$/, '');
      let pageFile = pathname.substr(pathname.lastIndexOf('/') + 1).toLowerCase();

      //differentiate 'scan.html' vs 'index.html'

      if (pageFile.indexOf('scan') >= 0)
        return getUrlParameter('uav', location.hash);
      else
        return window.AppVersionManager.current_uav();
    }
  },

  check_web_unsupported() {
    let unsupported = false;
    if (/MicroMessenger/.test(navigator.userAgent)) {
      unsupported = true;
    }

    if (store.state.deviceInfo.operatingSystem === 'ios') {
      const webkit = !!navigator.userAgent.match(/WebKit/i);
      const iOSSafari = webkit && !/crios|mqbhd|baidu|ucbrowser|aliapp|sogou/.test(navigator.userAgent.toLowerCase())
      if (!iOSSafari) unsupported = true;
    }

    return unsupported;
  }
};