const baseUrl = process.env.VUE_APP_WVS_BASE_URL + '/wvs/wvsv-1.2'

export default {

  mId() {
    return `${baseUrl}/_meta/mId`
  },

  session() {
    return `${baseUrl}/api/session`
  },

  enterVerificationCode() {
    return `${baseUrl}/api/enter/verificationCode`
  },

  verify() {
    return `${baseUrl}/api/enter/verify`
  },

  completeRegistration() {
    return `${baseUrl}/api/registration/teacher/complete`
  },

  passwordLogin() {
    return `${baseUrl}/api/session/login/password`
  },

  logout() {
    return `${baseUrl}/api/session/logout`
  },

  account() {
    return `${baseUrl}/api/teacher/account`
  },

  bindMobileVerificationCode() {
    return `${baseUrl}/api/teacher/account/security/mobile/bind/verificationCode`
  },

  bindMobile() {
    return `${baseUrl}/api/teacher/account/security/mobile/bind/confirm`
  },

  editFullname() {
    return `${baseUrl}/api/teacher/account/fullname`
  },

  editSchool() {
    return `${baseUrl}/api/teacher/account/schoolInfo`
  },

  editSubject() {
    return `${baseUrl}/api/teacher/account/subject`
  },

  editPassword() {
    return `${baseUrl}/api/teacher/account/security/password/change`
  },

  searchSchools({ search }) {
    return `${baseUrl}/api/school/directorySchools?search=${search}`
  },

  classrooms() {
    return `${baseUrl}/api/teacher/classrooms`
  },

  classroom({ classroomId }) {
    return `${baseUrl}/api/teacher/classrooms/${classroomId}`
  },

  classroomStudents({ classroomId }) {
    return `${baseUrl}/api/teacher/classrooms/${classroomId}/students`
  },

  quizzes() {
    return `${baseUrl}/api/teacher/quizzes`
  },

  quiz({ quizId }) {
    return `${baseUrl}/api/teacher/quizzes/${quizId}`
  },

  duplicateQuiz({ quizId }) {
    return `${baseUrl}/api/teacher/quizzes/${quizId}/duplicate`
  },

  bubbleSheetStructure({ quizId }) {
    return `${baseUrl}/api/teacher/quizzes/${quizId}/quizPaper/bubbleSheetStructure`
  },

  quizDataClassrooms({ quizId }) {
    return `${baseUrl}/api/teacher/quizzes/${quizId}/quizData/classrooms`
  },

  quizDataGcScan({ quizId }) {
    return `${baseUrl}/api/teacher/quizzes/${quizId}/quizData/gcScan`
  },

  quizReportClassroom({ quizId }) {
    return `${baseUrl}/api/teacher/quizzes/${quizId}/quizReport/classroom`
  },

  quizReportItem({ quizId }) {
    return `${baseUrl}/api/teacher/quizzes/${quizId}/quizReport/item`
  },

  quizAnswer({ quizId, quizAnswerEntryId }) {
    return `${baseUrl}/api/teacher/quizzes/${quizId}/quizData/quizAnswerEntries/${quizAnswerEntryId}/quizAnswer`
  },

  notifications() {
    return `${baseUrl}/api/teacher/notifications`
  }
}
