<template>
  <div class="enter">
    <div>
      <div class="enter__header">
        <div class="edit-fullname__header">
      <mt-header fixed title="修改手机号">
        <router-link to="/teacher/profile/edit" slot="left">
          <mt-button icon="back" @click="$emit('click-back-button')"></mt-button>
        </router-link>
      </mt-header>
    </div>
      </div>
      <div class="enter__main">
        <mt-field v-model.trim="mobile" placeholder="新手机号" type="tel" disableClear></mt-field>
        <span
          class="enter__reset-mobile-button"
          v-show="resetMobileButtonVisible"
          @click="mobile = ''"
        >
          <i class="fa fa-times"></i>
        </span>
        <mt-field v-model.trim="verificationCode" placeholder="验证码" type="text" disableClear></mt-field>
        <mt-button
          class="enter__send-verification-code-button"
          type="primary"
          @click="sendVerificationCode()"
          :disabled="!mobileIsValid || verificationCodeSending"
        >
          <span v-if="verificationCodeSending">已发送({{ verificationCodeCountdown }}s)</span>
          <span v-else>获取验证码</span>
        </mt-button>
        <mt-button type="primary" class="enter__enter-button" @click="bindMobile()">确定修改</mt-button>
      </div>
    </div>
  </div>
</template>

<script>
import Toast from "@/components/__mint-ui/Toast";
import Indicator from "@/components/__mint-ui/Indicator";

import urls from "@/api/teacher-urls";
import errorHandler from "@/api/error-handler";

export default {
  components: {},

  data() {
    return {
      mobile: "",
      verificationCode: "",
      verificationCodeSending: false,
      verificationCodeCountdown: 60,

      resetMobileButtonVisible: false
    };
  },

  watch: {
    mobile(val, oldVal) {
      if (oldVal === "") this.resetMobileButtonVisible = true;
      if (val === "") this.resetMobileButtonVisible = false;
    }
  },

  computed: {
    mobileIsValid() {
      return /^1(3|4|5|6|7|8|9)\d{9}$/.test(this.mobile);
    }
  },

  methods: {
    __startVerificationCodeTimer() {
      const intervalId = window.setInterval(() => {
        if (this.verificationCodeCountdown === 0) {
          window.clearInterval(intervalId);

          this.verificationCodeSending = false;
          this.verificationCodeCountdown = 60;
        } else {
          this.verificationCodeCountdown--;
        }
      }, 1000);
    },

    __sendVerificationCode({ mobile }) {
      const url = urls["bindMobileVerificationCode"]();
      return this.$http.post(url, { mobile });
    },

    sendVerificationCode() {
      Indicator.open("发送中...");
      this.__sendVerificationCode({ mobile: this.mobile }).then(
        rep => {
          Indicator.close();
          this.verificationCodeSending = true;
          this.__startVerificationCodeTimer();
        },
        err => {
          Indicator.close();
          errorHandler.handleError(err, {
            alert: true
          });
        }
      );
    },

    bindMobile() {
      if (!this.mobile) {
        Toast.showWarning(this.$i18n.t("Login.warning.inputMobile"));
        return;
      } else if (!this.mobileIsValid) {
        Toast.showWarning("手机格式错误");
        return;
      } else if (!this.verificationCode) {
        Toast.showWarning("请填写验证码");
        return;
      }

      Indicator.open();
      const url = urls["bindMobile"]();
      this.$http
        .post(url, {
          mobile: this.mobile,
          verificationCode: this.verificationCode
        })
        .then(res => {
          Indicator.close();
          Toast.showMessage('绑定成功')
          // this.$emit('click-back-button')

          this.$store.commit('UPDATE_SESSION_ACCOUNT', { key: 'mobile', newVal: this.mobile });
          this.$emit('click-confirm-button');

        })
        .catch(err => {
          Indicator.close();
          errorHandler.handleError(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .enter {
    /* Hack for ios issue: when long press the blank area, the background will turn gray. */
    height: 90vh;

    &__header {
      height: $header-height;
    }

    &__main {
      position: relative;
      text-align: center;
    }

    &__reset-mobile-button {
      position: absolute;
      top: .4rem;
      right: 3.8rem;
      color: $gray;
      font-size: $h4;

      &:active {
        opacity: .5;
      }
    }

    &__send-verification-code-button {
      position: absolute;
      top: .2rem;
      right: .2rem;
      width: 3.3rem;
      height: .8rem;
    }

    &__enter-button {
      margin-top: .5rem;
      width: 90%;
    }
  }
</style>
