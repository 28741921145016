<template>
  <div class="edit-password">
    <div class="edit-password__header">
      <mt-header fixed :title="$t('TeacherProfile.title.editPassword')">
        <router-link to="/teacher/profile/edit" slot="left">
          <mt-button icon="back" @click="$emit('click-back-button')"></mt-button>
        </router-link>
      </mt-header>
    </div>
    <div class="edit-password__main">
      <mt-field
        v-model="newPassword"
        :placeholder="$t('TeacherProfile.placeholder.newPassword')"
        class="edit-password__new-password"
        :type="newPasswordIsHidden ? 'password' : 'text'"
        disableClear>
      </mt-field>
      <span class="edit-password__new-password__eye-icon" :class="{ 'is-dark': newPasswordIsHidden }">
        <i
          class="fa fa-eye-slash"
          v-if="newPasswordIsHidden"
          @click="newPasswordIsHidden = false">
        </i>
        <i
          class="fa fa-eye"
          v-else
          @click="newPasswordIsHidden = true">
        </i>
      </span>
      <mt-field
        v-model="repeatedPassword"
        :placeholder="$t('TeacherProfile.placeholder.repeatedPassword')"
        class="edit-password__repeated-password"
        :type="repeatedPasswordIsHidden ? 'password' : 'text'"
        disableClear>
      </mt-field>
      <span class="edit-password__repeated-password__eye-icon" :class="{ 'is-dark': repeatedPasswordIsHidden }">
        <i
          class="fa fa-eye-slash"
          v-if="repeatedPasswordIsHidden"
          @click="repeatedPasswordIsHidden = false">
        </i>
        <i
          class="fa fa-eye"
          v-else
          @click="repeatedPasswordIsHidden = true">
        </i>
      </span>
      <mt-button
        class="edit-password__confirm-button"
        type="primary"
        @click="confirm()">
        {{ $t('TeacherProfile.label.confirm') }}
      </mt-button>
    </div>
  </div>
</template>

<script>
  import Toast      from '@/components/__mint-ui/Toast';
  import Indicator  from '@/components/__mint-ui/Indicator';

  import webviewService from '@/services/webview';
  import urls from '@/api/teacher-urls';

  export default {
    data () {
      return {
        newPassword: '',
        repeatedPassword: '',

        newPasswordIsHidden: true,
        repeatedPasswordIsHidden: true
      };
    },

    computed: {
      account () {
        return this.$store.state.session.account;
      },

      passwordIsContentValid () {
        return /^[0-9a-zA-Z]+$/.test(this.newPassword);
      },
      passwordIsLengthValid () {
        return this.newPassword.length >= 6;
      }
    },

    methods: {
      __editPassword ({ newPassword }) {
        const url = urls['editPassword']();
        return this.$http.put(url, { newPassword });
      },

      confirm () {
        if (!this.newPassword) {
          Toast.showWarning(this.$i18n.t('TeacherProfile.warning.inputNewPassword'));
        }
        else if (!this.repeatedPassword) {
          Toast.showWarning(this.$i18n.t('TeacherProfile.warning.inputRepeatedPassword'));
        }
        else if (!this.passwordIsContentValid) {
          Toast.showWarning(this.$i18n.t('TeacherProfile.warning.invalidPasswordContent'));
        }
        else if (!this.passwordIsLengthValid) {
          Toast.showWarning(this.$i18n.t('TeacherProfile.warning.invalidPasswordLength'));
        }
        else if (this.repeatedPassword !== this.newPassword) {
          Toast.showWarning(this.$i18n.t('TeacherProfile.warning.invalidRepeatedPassword'));
        }
        else {
          Indicator.open();
          this.__editPassword({ newPassword: this.newPassword })
              .then(
                rep => {
                  Indicator.close();
                  Toast.showTip('修改成功');

                  this.$emit('click-confirm-button')
                },
                err => {
                  Indicator.close();
                }
              );
        }
      }
    },

    mounted () {
      window.setTimeout(() => { this.$el.querySelector('input').focus(); }, 300);
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('profile.editAccount.editPassword', () => this.$emit('click-back-button'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('profile.editAccount.editPassword');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .edit-password {
    &__header {
      height: $header-height;
    }

    &__main {
      position: relative;
      margin-top: .5rem;
      text-align: center;
    }

    &__new-password {
      &__eye-icon {
        position: absolute;
        top: .3rem;
        right: .3rem;
        color: $blue;
        font-size: $h3;

        &:active {
          opacity: .5;
        }

        &.is-dark {
          color: $dark-gray;
        }
      }
    }

    &__repeated-password {
      &__eye-icon {
        position: absolute;
        top: 1.5rem;
        right: .3rem;
        color: $blue;
        font-size: $h3;

        &:active {
          opacity: .5;
        }

        &.is-dark {
          color: $dark-gray;
        }
      }
    }

    &__confirm-button {
      margin-top: .5rem;
      width: 90%;
    }
  }
</style>
