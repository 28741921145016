<template>
  <div class="classroom-list">
    <div v-if="classroomListVisible">
      <div v-if="classrooms === null">
        <div class="classroom-list__header">
          <mt-header fixed title="班级"></mt-header>
        </div>
        <div class="classroom-list__main">
          <div class="classroom-list__retry" v-show="retryVisible" @click="retry__fetchClassrooms()">
            班级列表获取失败，点击重试
          </div>
        </div>
        <div class="classroom-list__footer">
          <footer-tab-bar current-tab="classroom"></footer-tab-bar>
        </div>
      </div>

      <div v-else>
        <div class="classroom-list__header">
          <mt-header fixed :title="$t('TeacherClassroomList.title.class')"></mt-header>
          <span
            class="classroom-list__new-button"
            @click="createClassroomVisible = true">
            {{ $t('TeacherClassroomList.label.new') }}
          </span>
        </div>
        <div class="classroom-list__main">
          <div v-if="classrooms.length > 0">
            <list-transition>
              <classroom-card
                v-for="(classroom, index) in classrooms"
                :key="index"
                :classroom="classroom"
                @remove-classroom-done="retry__fetchClassrooms()">
              </classroom-card>
            </list-transition>
            <div class="classroom-list__count">
              {{ classroomsTotal }}个班级
            </div>
          </div>
          <div class="classroom-list__create-classroom" v-else>
            <div class="classroom-list__create-classroom-prompt">
              您尚未创建班级，请添加
            </div>
            <mt-button
              class="classroom-list__create-classroom-button"
              type="primary"
              @click="createClassroomVisible = true">
              创建班级
            </mt-button>
          </div>
        </div>
        <div class="classroom-list__footer">
          <back-top></back-top>
          <footer-tab-bar current-tab="classroom"></footer-tab-bar>
        </div>
        <top-refresh @refresh="retry__fetchClassrooms()"></top-refresh>
      </div>
    </div>

    <slide-transition>
      <create-classroom
        @click-back-button="createClassroomVisible = false"
        v-if="createClassroomVisible">
      </create-classroom>
    </slide-transition>
  </div>
</template>

<script>
  import ListTransition  from '@/components/ListTransition';
  import SlideTransition from '@/components/SlideTransition';
  import TopRefresh      from '@/components/TopRefresh';
  import BackTop         from '@/components/BackTop';
  import FooterTabBar    from '@/components/teacher/FooterTabBar';

  import ClassroomCard   from './_classroom-list/ClassroomCard';
  import CreateClassroom from './_classroom-list/CreateClassroom';

  import Toast      from '@/components/__mint-ui/Toast';
  import Indicator  from '@/components/__mint-ui/Indicator';

  import checkAccess from '@/services/check-access';

  import urls from '@/api/teacher-urls';

  import { without } from 'underscore';

  export default {
    components: {
      ListTransition,
      SlideTransition,
      TopRefresh,
      BackTop,
      FooterTabBar,
      ClassroomCard,
      CreateClassroom
    },

    data () {
      return {
        classrooms: null,
        classroomsTotal: null,

        createClassroomVisible: false,

        retryVisible: false
      };
    },

    computed: {
      classroomListVisible () {
        return !this.createClassroomVisible;
      },

      networkStatus () {
        return this.$store.state.networkStatus;
      }
    },

    watch: {
      networkStatus (val, oldVal) {
        if (val === 'online' && oldVal === 'offline')
          if (this.retryVisible) this.retry__fetchClassrooms();
      }
    },

    methods: {
      __fetchClassrooms () {
        const url = urls['classrooms']();
        return this.$http.get(url);
      },

      fetchClassrooms () {
        Indicator.open(this.$i18n.t('__Common.loading'));

        this.__fetchClassrooms().then(
          rep => {
            this.classrooms      = rep.data.classrooms;
            this.classroomsTotal = rep.data.classroomsTotal;
            Indicator.close();
          },
          err => {
            this.retryVisible = true;
            Indicator.close();
          }
        );
      },

      retry__fetchClassrooms () {
        this.retryVisible = false;
        this.fetchClassrooms();
      }
    },

    created () {
      this.fetchClassrooms();
    },

    beforeCreate () {
      checkAccess('loggedIn');
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';
  @import 'src/assets/style/mixins';

  .classroom-list {
    &__header {
      position: relative;
      height: $header-height;
    }

    &__main {
      background-color: $white;
      border-bottom: 1px solid $light-gray;
    }

    &__retry {
      height: 2rem;
      font-size: $h5;
      line-height: 2rem;
      text-align: center;
    }

    &__count {
      padding: .6rem 0;
      background-color: $light-gray;
      color: $dark-gray;
      font-size: $h4;
      text-align: center;
    }

    &__create-classroom {
      text-align: center;
    }

    &__create-classroom-prompt {
      padding: 1rem 0;
      font-size: $h4;
    }

    &__create-classroom-button {
      margin-bottom: 1rem;
      width: 90%;
    }

    &__footer {
      height: 1.3rem;
    }

    &__new-button {
      @include pressed(0, 0, 0, 0);

      position: fixed;
      z-index: $highest-z-index;
      top: 0;
      right: 0;
      padding-right: .3rem;
      display: inline-block;
      width: 1.5rem;
      height: $header-height;
      color: $white;
      font-size: $h4;
      line-height: $header-height;
      text-align: right;
    }
  }
</style>
