export default {
  __Common: {
    loading: 'Loading...',
    footerTabBar: {
      quiz: 'Quiz',
      class: 'Class',
      discover: 'Discover',
      me: 'Me'
    },
    loadMore: {
      loading: 'Loading...',
      allLoaded: 'All loaded!'
    },
    action: {
      edit: 'Edit',
      remove: 'Delete'
    },
    subject: {
      chinese: 'Chinese',
      math: 'Math',
      english: 'English',
      physics: 'Physics',
      chemistry: 'Chemistry',
      biology: 'Biology',
      history: 'History',
      geography: 'Geography',
      politics: 'Politics',
      other: 'Other'
    },
    grade: {
      1: 'Grade 1',
      2: 'Grade 2',
      3: 'Grade 3',
      4: 'Grade 4',
      5: 'Grade 5',
      6: 'Grade 6',
      7: 'Grade 7',
      8: 'Grade 8',
      9: 'Grade 9',
      10: 'Grade 10',
      11: 'Grade 11',
      12: 'Grade 12',
    }
  },
  Login: {
    title: 'Login',
    placeholder: {
      mobile: 'Mobile',
      password: 'Password'
    },
    label: {
      login: 'Login'
    },
    warning: {
      inputMobile: 'Please input mobile'
    }
  },
  Register: {
    title: {
      newUser: 'Register',
      teacher: 'Teacher Register',
      student: 'Student Register'
    },
    registerByWechat: 'Register by Wechat',
    captchaPrompt: 'Already send captcha to {mobile}, please fill:',
    registering: 'Registering...',
    placeholder: {
      mobile: 'Mobile',
      password: 'Password',
      captcha: 'Captcha',
      fullname: 'Fullname',
      accessCode: 'Access Code',
      school: 'School',
      subject: 'Subject',
      searchSchool: 'Please input school name'
    },
    label: {
      iAmTeacher: 'Teacher',
      iAmStudent: 'Student',
      next: 'Next',
      done: 'Done',
      resendCaptch: 'Resend captcha',
      resendCountdown: 'Resend({countdown}s)',
      subject: 'Subject:',
      cancel: 'Cancel',
      searchResult: 'Search result'
    },
    warning: {
      selectRole: 'Please select role',
      inputMobile: 'Please input mobile',
      inputPassword: 'Please input password',
      inputCaptcha: 'Please input captcha',
      inputFullname: 'Please input fullname',
      inputAccessCode: 'Please input access code',
      inputSchool: 'Please input school',
      invalidMobile: 'Wrong mobile format',
      invalidPasswordContent: 'Wrong passowrd format',
      invalidPasswordLength: 'Wrong password length'
    }
  },
  TeacherProfile: {
    title: {
      account: 'Account',
      editFullname: 'Edit Fullname',
      editPassword: 'Edit Password',
      editSubject: 'Edit Subject'
    },
    placeholder: {
      newPassword: 'New password',
      repeatedPassword: 'Repeated password',
      fullname: 'Fullname'
    },
    label: {
      notification: 'Notification',
      about: 'About',
      avatar: 'Avatar',
      fullname: 'Fullname',
      mobile: 'Mobile',
      modifyPassword: 'Modify password',
      subject: 'Subject',
      school: 'School',
      logout: 'Logout',
      confirm: 'Confirm'
    },
    warning: {
      invliadFullname: 'Fullname can not be empty',
      inputNewPassword: 'Please input new password',
      inputRepeatedPassword: 'Please input repeated password',
      invalidPasswordContent: 'Wrong passowrd format',
      invalidPasswordLength: 'Wrong password length',
      invalidRepeatedPassword: 'New password is not equal to repeated password',
    },
    message: {
      logout: 'Are you sure to logout?',
      modifyPasswordDone: 'Done, please login again!'
    }
  },
  TeacherQuizList: {
    title: {
      quiz: 'Quiz',
      createQuiz: 'Create Quiz',
      editQuiz: 'Edit Quiz'
    },
    label: {
      new: '+ New',
      questionsCount: '{count} quizzes',
      scansCount: '{count} scans',
      averageCorrectRate: 'Average correct rate',
      subject: 'Subject:',
      class: 'Participant classes:',
      next: 'Next',
      creationDone: 'Done',
      nonscan: ' ',
      editionDone: 'Confirm'
    },
    placeholder: {
      quizName: 'Quiz name',
      questionsCount: 'Questions count',
      quizDescription: 'Quiz description'
    },
    warning: {
      inputQuizName: 'Please input quiz name',
      inputQuestionsCount: 'Please input questions count',
      invalidQuestionsCount: 'Valid questions count (1-100)'
    },
    message: {
      removeQuizDone: 'Done',
      createQuizDone: 'Done',
      editQuizDone: 'Done'
    }
  },
  TeacherQuiz: {
    label: {
      bubblesheet: 'Bubblesheet',
      scan: 'Scan',
      report: 'Report',
      selectClasses: 'Please select classes:',
      downloadBubblesheet: 'Download bubblesheet',
      scanBubblesheet: 'Scan bubblesheet',
      classReport: 'Class',
      questionReport: 'Question',
      correctRate: 'Correct rate',
      fullScore: 'Score',
      studentsCount: 'Student',
      scannedCount: 'Scan',
      studentName: 'Student name',
      studentQuizNumber: 'Student number',
      nonscan: 'Non scan',
      questionOrdinal: 'Ordinal',
      switch: 'Switch',
      scanOverview: 'Scan overview',
      sortBystudentQuizNumber: 'Sort by student number',
      sortByCorrectRate: 'Sort by correct rate',
      sortByQuestionOrdinal: 'Sort by question ordinal',
      singleQuestion: 'Single',
      multipleQuestion: 'Multiple',
      booleanQuestion: 'Boolean',
      subjectiveQuestion: 'Subjective',
      point: '{score}pt',
      edit: 'Edit',
      remove: 'Remove',
      question: 'Question',
      fullScore: 'Score',
      noBubblePrompt: 'Subjective question does not have choices',
      modifyCount: 'Modify count',
      selectAll: 'Select all',
      unselectAll: 'Unselect all',
      removeSelected: 'Remove({count})',
      editSelected: 'Edit({count})',
      confirm: 'Confirm'
    },
    placeholder: {
      questionsCount: 'Questions count(1-100)',
      questionScore: 'Question score'
    },
    warning: {
      selectClasses: 'Please select classes',
      setBubblesheet: 'Please set bubblesheet',
      scanBubblesheet: 'Please scan bubblesheet'
    }
  },
  TeacherClassroomList: {
    classroomNamePrompt: 'Suggested format: 2017 class 16',
    title: {
      class: 'Class',
      createClassroom: 'Create Class',
      editClassroom: 'Edit Class'
    },
    label: {
      new: '+ New',
      session: 'Session {session}',
      studentsCount: 'Student {count}',
      creationDone: 'Done',
      editionDone: 'Confirm'
    },
    placeholder: {
      classroomName: 'Class name'
    },
    warning: {
      inputClassroomName: 'Please input class name'
    },
    message: {
      removeClassroomDone: 'Done',
      createClassroomDone: 'Done',
      editClassroomDone: 'Done'
    }
  },
  TeacherClassroom: {
    studentQuizNumberPrompt: 'Tip: student number must be 3bit - 10bit number',
    createStudentPrompt: 'There is no student in this class, please add student',
    title: {
      createStudent: 'Create Student',
      editStudent: 'Edit Student'
    },
    label: {
      studentName: 'Student name',
      studentQuizNumber: 'Student number',
      accessCode: 'Access code',
      confirm: 'Confirm',
      success: 'Success',
      done: 'Done',
      continue: 'Continue',
      sort: 'Sort',
      removeStudents: 'Remove',
      createStudent: 'Create student',
      remove: '- Remove'
    },
    placeholder: {
      studentName: 'Student name',
      studentQuizNumber: 'Student number'
    },
    warning: {
      inputStudentName: 'Please input student name',
      inputstudentQuizNumber: 'Please input student number',
      invalidstudentQuizNumber: 'Student number must be 3bit - 10bit number'
    },
    message: {
      studentInfo: 'Student name: {studentName}, Student number: {studentQuizNumber}',
      removeStudents: 'The selected {count} students will be removed, are you sure?'
    }
  },
  studentQuizList: {
    title: 'Quiz({count})',
    label: {
      point: '',
      nonscore: '-',
      sortByScore: 'Sort by score',
      sortByDate: 'Sort by date'
    }
  },
  StudentProfile: {
    title: {
      me: 'Me',
      accessCode: 'Access Code',
      bind: 'Bind'
    },
    label: {
      accessCode: 'Access code',
      notification: 'Notification',
      about: 'About',
      unbind: 'Unbind',
      boundDate: 'Date: {date}',
      boundClass: 'Class: ',
      created: '',
      boundStudent: 'Student: {name}',
      bind: '+ Bind',
      next: 'Next',
      confirmBind: 'Bind'
    },
    warning: {
      inputAccessCode: 'Please input access code',
      invalidAccessCode: 'Invalid access code'
    },
    placeholder: {
      accessCode: 'Access code'
    },
    message: {
      unbindAccessCode: 'Are you sure to unbind {studentName}?',
      bindAccessCode: 'Are you sure to bind {studentName}?',
      successfulBind: 'Successful bind'
    }
  }
};
