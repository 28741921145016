<template>
  <div class="quiz">
    <div v-if="quizVisible">
      <div v-if="quiz === null">
        <div class="quiz__header">
          <mt-header fixed :title="$route.params.quizName">
            <router-link to="/teacher/quiz-list" slot="left">
              <mt-button icon="back"></mt-button>
            </router-link>
          </mt-header>
        </div>
        <div class="quiz__main">
          <div class="quiz__retry" v-show="retryVisible" @click="retry__fetchQuiz()">
            测验获取失败，点击重试
          </div>
        </div>
      </div>

      <div v-else>
        <div class="quiz__header">
          <mt-header fixed>
            <router-link to="/teacher/quiz-list" slot="left">
              <mt-button icon="back"></mt-button>
            </router-link>
          </mt-header>
          <div class="quiz__name">
            <span>
              <!-- <i class="fa fa-table" v-show="currentTab === 'bubblesheet'"></i>
              <i class="fa fa-camera" v-show="currentTab === 'scan'"></i>
              <i class="fas fa-chart-bar" v-show="currentTab === 'report'"></i> -->
              {{ quiz.quizName }}
            </span>
          </div>

          <span class="quiz__more-button" @click="dropdownMenuVisible = true">
            <i class="fa fa-bars"></i>
          </span>
          <dropdown-menu @click-edit-quiz-button="toEditQuiz" @click-mask="dropdownMenuVisible = false"
            v-if="dropdownMenuVisible">
          </dropdown-menu>
        </div>
        <div class="quiz__nav">
          <nav-tab-bar :quiz="quiz" :current-tab="currentTab" @click-tab-item="changeCurrentTab">
          </nav-tab-bar>
        </div>
        <div class="quiz__main">

          <div class="quiz__content">
            <bubblesheet v-if="currentTab === 'bubblesheet'" :quiz="quiz"
              @update-bubblesheet-structure="updateBubblesheetStructure"></bubblesheet>
            <scan v-if="currentTab === 'scan'" :quiz="quiz" @check-report="goToReport"></scan>
            <report v-if="currentTab === 'report'" :quiz="quiz" @empty-report="goToScan"></report>
          </div>
        </div>
        <div class="quiz__footer">
          <back-top></back-top>
        </div>
      </div>
    </div>

    <!-- <slide-transition>
      <edit-quiz @click-back-button="editQuizVisible = false" @click-done-button="editQuizDone" :quiz="quiz"
        v-if="editQuizVisible">
      </edit-quiz>
    </slide-transition> -->
  </div>
</template>

<script>
  // import SlideTransition from '@/components/SlideTransition';
  import BackTop from '@/components/BackTop';

  import NavTabBar from './_quiz/NavTabBar';
  import DropdownMenu from './_quiz/DropdownMenu';
  // import EditQuiz from './_quiz/EditQuiz';
  import Bubblesheet from './_quiz/Bubblesheet';
  import Scan from './_quiz/Scan';
  import Report from './_quiz/Report';

  import Toast from '@/components/__mint-ui/Toast';
  import Indicator from '@/components/__mint-ui/Indicator';

  import urls from '@/api/teacher-urls';

  import checkAccess from '@/services/check-access';
  import webviewService from '@/services/webview';

  import {
    findWhere
  } from 'underscore';

  export default {
    components: {
      // SlideTransition,
      BackTop,
      NavTabBar,
      DropdownMenu,
      // EditQuiz,
      Bubblesheet,
      Scan,
      Report
    },
    data() {
      return {
        quiz: null,
        currentTab: 'bubblesheet',

        dropdownMenuVisible: false,
        editQuizVisible: false,

        retryVisible: false
      }
    },

    computed: {
      quizVisible() {
        return !this.editQuizVisible;
      },

      networkStatus() {
        return this.$store.state.networkStatus;
      }
    },

    watch: {
      networkStatus(val, oldVal) {
        if (val === 'online' && oldVal === 'offline')
          if (this.retryVisible) this.retry__fetchQuiz();
      }
    },

    methods: {
      __updateBubblesheetStructure({
        quizId,
        items
      }) {
        const url = urls['bubbleSheetStructure']({
          quizId
        });
        return this.$http.put(url, {
          bubbleSheetStructure: {
            items
          }
        });
      },

      __fetchQuiz({
        quizId
      }) {
        const url = urls['quiz']({
          quizId
        });
        return this.$http.get(url);
      },

      updateBubblesheetStructure(items) {
        const quizId = this.$route.params.quizId;

        this.__updateBubblesheetStructure({
            quizId,
            items
          })
          .then(
            rep => {
              this.__fetchQuiz({
                  quizId
                })
                .then(rep => this.quiz = rep.data);
            }
          );
      },

      fetchQuiz() {
        Indicator.open(this.$i18n.t('__Common.loading'));

        this.__fetchQuiz({
            quizId: this.$route.params.quizId
          })
          .then(
            rep => {
              this.quiz = rep.data;
              Indicator.close();
            },
            err => {
              this.retryVisible = true;
              Indicator.close();
            }
          );
      },

      retry__fetchQuiz() {
        this.retryVisible = false;
        this.fetchQuiz();
      },

      toEditQuiz() {
        // this.editQuizVisible = true;
        // this.dropdownMenuVisible = false;
        this.$router.push({
          name: 'teacherEditQuiz',
          params: { quizId: this.quiz.quizId, quizName: this.quiz.quizName }
        });
      },
      editQuizDone() {
        this.__fetchQuiz({
            quizId: this.$route.params.quizId
          })
          .then(rep => {
            this.quiz = rep.data;
            this.editQuizVisible = false;
            Toast.showTip('编辑完成');
          });
      },

      changeCurrentTab(tab) {
        this.currentTab = tab;
      },

      goToScan() {
        this.changeCurrentTab('scan');
        this.fetchQuiz();
      },

      goToReport() {
        this.changeCurrentTab('report');
        this.fetchQuiz();
      }
    },

    created() {
      this.fetchQuiz();
    },

    beforeCreate() {
      checkAccess('loggedIn');
      webviewService.pushBackButtonCallback('quiz', () => this.$router.push({
        name: 'teacherQuizList'
      }));
    },
    beforeDestroy() {
      webviewService.popBackButtonCallback('quiz');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';
  @import 'src/assets/style/mixins';

  .quiz {
    &__header {
      position: relative;
      height: $header-height;
    }

    &__nav {
      position: fixed;
      top: $header-height;
      height: $header-height;
      width: 100%;
      background-color: $blue;
      z-index: 100;
    }

    &__retry {
      height: 2rem;
      background-color: $white;
      font-size: $h5;
      line-height: 2rem;
      text-align: center;
    }

    &__more-button {
      @include pressed(0, 0, 0, -.4rem);

      position: fixed;
      z-index: $highest-z-index;
      top: 0;
      right: 0;
      display: inline-block;
      width: .8rem;
      height: $header-height;
      color: $white;
      font-size: $h3;
      line-height: $header-height;
    }

    &__name {
      position: fixed;
      z-index: $highest-z-index;
      top: 0.3rem;
      width: 80%;
      left: 10%;
      height: .8rem;
      // background-color: $blue;
      color: $white;
      font-size: $h5 + .05;
      line-height: .8rem;
      text-align: center;

      span {
        @include truncate(90%);
        display: inline-block;
        vertical-align: bottom;
      }

      i {
        width: .4rem;
      }
    }

    &__content {
      padding-top: .8rem;
    }
  }
</style>