<template>
  <div class="dropdown-menu">
    <div class="dropdown-menu__main">
      <span class="dropdown-menu__arrow"></span>
      <div class="dropdown-menu__list">
        <div class="dropdown-menu__edit-quiz" @click="$emit('click-edit-quiz-button')">
          <i class="fas fa-pen"></i>
          <span>编辑测验</span>
        </div>
      </div>
    </div>
    <div class="dropdown-menu__mask" @click="$emit('click-mask')">
      <mask-layer></mask-layer>
    </div>
  </div>
</template>

<script>
  import MaskLayer from '@/components/MaskLayer';
  import webviewService from '@/services/webview';

  export default {
    components: {
      MaskLayer
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('quiz.dropdownMenu', () => this.$emit('click-mask'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('quiz.dropdownMenu');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';
  @import 'src/assets/style/mixins';

  .dropdown-menu {
    &__main {
      position: fixed;
      z-index: $highest-z-index + 2;
      top: 1.2rem;
      right: .05rem;
      width: 3.2rem;
      height: 1.2rem;
      background: $white;
      text-align: center;
      border-radius:2px;
    }

    &__arrow {
      position: absolute;
      top: 0;
      right: 0;

      &:before {
        position: absolute;
        top: -.3rem;
        right: .4rem;
        width: 0;
        height: 0;
        border-top: .15rem solid transparent;
        border-left: .15rem solid transparent;
        border-bottom: .15rem solid $white;
        border-right: .15rem solid transparent;
        content: '';
      }
    }

    &__edit-quiz {
      @include pressed(0, 0, 0, 0);

      position: relative;
      // margin-left: .1rem;
      width: 3rem;
      height: 1.2rem;
      font-size: $h4;
      line-height: 1.2rem + 0.1rem;

      i {
        font-size: $h4;
      }

      span {
        margin-left: .1rem;
      }
    }
  }
</style>
