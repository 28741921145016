<template>
  <div class="item-card" :class="{ 'is-first': isFirst }" @click="$emit('click-item', item)">
    <span class="item-card__item-ordinal">
      {{ item.itemOrdinal }}
    </span>
    <span class="item-card__correct-rate">
      <span class="item-card__correct-rate__bar" v-if="item.type !== 'subjectiveSkip'">
        <i>{{ formatRatio(item.averageScoreRatio) }}%</i>
        <mt-progress :value="formatRatio(item.averageScoreRatio)" :bar-height="40"></mt-progress>
      </span>
      <span v-else>
        主观题
      </span>
    </span>
  </div>
</template>

<script>
  export default {
    props: ['item', 'is-first'],

    methods: {
      formatRatio (ratio) {
        return Number((ratio * 100).toFixed(0));
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';
  @import 'src/assets/style/mixins';

  .item-card {
    @include pressed(0, 0, 0, 0);

    position: relative;
    padding: 0 .3rem;
    height: 1.2rem;
    border-top: 1px solid $light-gray;
    font-size: $h4;
    line-height: 1.2rem;

    &.is-first {
      border-top: none;
    }

    &__item-ordinal,
    &__correct-rate {
      display: inline-block;
      // width: 4.6rem;
      @include truncate(50%);
    }

    &__correct-rate {
      position: relative;

      &__bar {
        display: inline-block;
        width: 4.6rem;
        vertical-align: middle;
      }

      i {
        position: absolute;
        z-index: $high-z-index;
        top: .05rem;
        left: .1rem;
        color: $black;
        font-size: $h5;
      }
    }
  }
</style>
