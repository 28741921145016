<template>
  <div class="student-list-modal">
    <div class="student-list-modal__content">
      <div class="student-list-modal__title">
        <span class="student-list-modal__back-button" @click="$emit('close-modal')">
          <back-icon></back-icon>
        </span>
        学生列表 ({{ scansCount }} / {{ studentsCount }})
      </div>
      <div class="student-list-modal__table">
        <div class="student-list-modal__table-header">
          <span class="student-list-modal__table-header__student-name">姓名</span>
          <span class="student-list-modal__table-header__student-quiz-number">考号</span>
          <span class="student-list-modal__table-header__total-score">得分</span>
        </div>
        <div class="student-list-modal__table-content">
          <div class="student-list-modal__student-card" v-for="student in students" :key="student.studentQuizNumber">
            <span class="student-list-modal__student-card__student-name">
              {{ student.studentName }}
            </span>
            <span class="student-list-modal__student-card__student-quiz-number">
              {{ student.studentQuizNumber }}
            </span>
            <span class="student-list-modal__student-card__total-score">
              <span v-if="student.quizAnswerResult === null">未扫描</span>
              <i v-else>{{ student.quizAnswerResult.summary.totalScore }}分</i>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="student-list-modal__mask" @click="$emit('close-modal')">
      <mask-layer></mask-layer>
    </div>
  </div>
</template>

<script>
  import BackIcon  from '@/components/BackIcon';
  import MaskLayer from '@/components/MaskLayer';

  import Toast from '@/components/__mint-ui/Toast';
  import webviewService  from '@/services/webview';

  export default {
    components: {
      BackIcon,
      MaskLayer
    },

    props: ['students'],

    computed: {
      studentsCount () {
        return this.students.length;
      },
      scansCount () {
        return this.students.filter(s => s.quizAnswerResult !== null).length;
      }
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('quiz.bubblesheet.createStudent.studentListModal', () => this.$emit('close-modal'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('quiz.bubblesheet.createStudent.studentListModal');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .student-list-modal {
    color: $darkest-gray;

    &__content {
      position: fixed;
      z-index: $highest-z-index + 2;
      top: 3rem;
      bottom: 15%;
      left: 2%;
      width: 96%;
      background-color: $white;
      border-radius: .1rem;
    }

    &__title {
      height: 1.2rem;
      background-color: $blue;
      border-radius: .1rem .1rem 0 0;
      color: $white;
      font-size: $h4;
      line-height: 1.2rem;
      text-align: center;
    }

    &__back-button {
      position: absolute;
      top: 0;
      left: .15rem;
    }

    &__table {
      box-sizing: border-box;
      padding: .2rem .5rem;
      height: calc(100% - 1.2rem);
      display: flex;
      flex-direction: column;
    }

    &__table-header {
      display: flex;
      font-size: $h5;
      line-height: $h5;

      &__student-name {
        flex: 2;
      }

      &__student-quiz-number {
        flex: 1;
      }

      &__total-score {
        flex: 1;
      }
    }

    &__table-content {
      margin-top: .2rem;
      // max-height: 8rem;
      overflow-y: auto;
    }

    &__student-card {
      padding: 0px 0;
      display: flex;
      font-size: $h4;
      // line-height: $h4;


      &__student-name {
        flex: 2;
        overflow-x: hidden;
      }

      &__student-quiz-number {
        flex: 1;
      }

      &__total-score {
        flex: 1;

        span {
          color: $gray;
        }

        i {
          color: $blue;
        }
      }
    }

    &__confirm-button {
      margin: .5rem 5%;
      width: 90%;
    }
  }
</style>

