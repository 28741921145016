<template>
    <div class="discover">
        <div class="discover__header">
            <mt-header fixed title="班级"></mt-header>
        </div>
        <div class="discover__main">
            <iframe src="https://ch3.menco.cn/" class="discover__main__iframe" frameBorder="0">

            </iframe>
        </div>
        <div class="discover__footer">
            <back-top></back-top>
            <footer-tab-bar current-tab="discover"></footer-tab-bar>
        </div>
        <top-refresh @refresh="retry__fetchClassrooms()"></top-refresh>
    </div>
</template>

<script>
    import ListTransition from '@/components/ListTransition';
    import SlideTransition from '@/components/SlideTransition';
    import TopRefresh from '@/components/TopRefresh';
    import BackTop from '@/components/BackTop';
    import FooterTabBar from '@/components/teacher/FooterTabBar';

    import Toast from '@/components/__mint-ui/Toast';
    import Indicator from '@/components/__mint-ui/Indicator';

    import checkAccess from '@/services/check-access';

    import urls from '@/api/teacher-urls';

    import {
        without
    } from 'underscore';

    export default {
        components: {
            ListTransition,
            SlideTransition,
            TopRefresh,
            BackTop,
            FooterTabBar
        },

        data() {
            return {
                discovers: null,
                discoversTotal: null,

                createClassroomVisible: false,

                retryVisible: false
            };
        },

        computed: {
            discoverListVisible() {
                return !this.createClassroomVisible;
            },

            networkStatus() {
                return this.$store.state.networkStatus;
            }
        },

        watch: {
            networkStatus(val, oldVal) {
                if (val === 'online' && oldVal === 'offline')
                    if (this.retryVisible) this.retry__fetchClassrooms();
            }
        },

        methods: {
            __fetchClassrooms() {
                const url = urls['discovers']();
                return this.$http.get(url);
            },

            fetchClassrooms() {
                Indicator.open(this.$i18n.t('__Common.loading'));

                this.__fetchClassrooms().then(
                    rep => {
                        this.discovers = rep.data.discovers;
                        this.discoversTotal = rep.data.discoversTotal;
                        Indicator.close();
                    },
                    err => {
                        this.retryVisible = true;
                        Indicator.close();
                    }
                );
            },

            retry__fetchClassrooms() {
                this.retryVisible = false;
                this.fetchClassrooms();
            }
        },

        created() {
            //   this.fetchClassrooms();
        },

        beforeCreate() {
            checkAccess('loggedIn');
        }
    }
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables';
    @import 'src/assets/style/mixins';

    .discover {
        &__header {
            position: relative;
            height: 1.2rem;
        }

        &__main {
            background-color: $white;
            border-bottom: 1px solid $light-gray;
            position: fixed;
            top: 1.2rem;
            bottom: 1.3rem;
            left:0;
            right:0;
        }

        &__main__iframe {
            height: 100%;
            width: 100%;
        }

        &__retry {
            height: 2rem;
            font-size: $h5;
            line-height: 2rem;
            text-align: center;
        }

        &__count {
            padding: .6rem 0;
            background-color: $light-gray;
            color: $dark-gray;
            font-size: $h4;
            text-align: center;
        }

        &__create-discover {
            text-align: center;
        }

        &__create-discover-prompt {
            padding: 1rem 0;
            font-size: $h4;
        }

        &__create-discover-button {
            margin-bottom: 1rem;
            width: 90%;
        }

        &__footer {
            height: 1.3rem;
        }

        &__new-button {
            @include pressed(0, 0, 0, 0);

            position: fixed;
            z-index: $highest-z-index;
            top: 0;
            right: 0;
            padding-right: .3rem;
            display: inline-block;
            width: 1.5rem;
            height: 1.2rem;
            color: $white;
            font-size: $h4;
            line-height: 1.2rem;
            text-align: right;
        }
    }
</style>