<template>
  <div class="scan">
    <div v-if="classrooms === null">
      <div class="scan__retry" v-show="retryVisible" @click="retry__fetchClassrooms()">班级列表获取失败，点击重试</div>
    </div>

    <div v-else>
      <div v-if="classrooms.length > 0">
        <div class="scan__top-bar">
          <div class="scan__top-bar__title">
            <span>{{ $t('TeacherQuiz.label.selectClasses') }}</span>
            <span v-show="selectedClassrooms.length > 0">({{ selectedClassrooms.length }})</span>
          </div>
          <div class="scan__top-bar__button">
            <span
              v-show="selectAllButtonVisible"
              class="scan__select-all-button"
              @click="selectAll()"
            >全选</span>
            <span
              v-show="unselectAllButtonVisible"
              class="scan__unselect-all-button"
              @click="unselectAll()"
            >取消全选</span>
          </div>
        </div>

        <div class="scan__classroom-list">
          <classroom-card
            @click-selector="classroom.selected = !classroom.selected"
            v-for="(classroom, index) in classrooms"
            :classroom="classroom"
            :key="classroom.classroomId"
            :is-first="index === 0"
          ></classroom-card>
          <div class="scan__classroom-list__count">{{ classrooms.length }}个班级</div>
        </div>

        <div class="scan__operation">
          <mt-button
            class="scan__scan-button"
            type="primary"
            :disabled="selectedClassrooms.length === 0"
            @click="startScan()"
          >
            <i class="fa fa-camera"></i>
            {{ $t('TeacherQuiz.label.scanBubblesheet') }}
          </mt-button>
        </div>
      </div>

      <div v-else>
        <div class="scan__create-classroom-prompt">
          <div>您暂未创建班级，扫描前请先创建班级</div>
          <div>
            <mt-button
              class="scan__to-classroom-list-button"
              type="primary"
              @click="$router.push({ name: 'teacherClassroomList' })"
            >去创建</mt-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ClassroomCard from "./_scan/ClassroomCard";

import Toast from "@/components/__mint-ui/Toast";
import Indicator from "@/components/__mint-ui/Indicator";
import MessageBox from "@/components/__mint-ui/MessageBox";

import urls from "@/api/teacher-urls";

import webviewService from "@/services/webview";

import { without, where, pluck, union, uniq } from "underscore";

export default {
  components: {
    ClassroomCard
  },

  props: ["quiz"],

  data() {
    return {
      classrooms: null,
      retryVisible: false
    };
  },

  computed: {
    selectAllButtonVisible() {
      if (this.classrooms !== null)
        return this.classrooms.find(
          c => c.students.length > 0 && c.selected === false
        );
    },
    unselectAllButtonVisible() {
      if (this.classrooms !== null)
        return (
          this.classrooms.find(c => c.students.length > 0) &&
          !this.classrooms.find(
            c => c.students.length > 0 && c.selected === false
          )
        );
    },

    selectedClassrooms() {
      if (this.classrooms !== null)
        return where(this.classrooms, { selected: true });
    },
    selectedStudents() {
      if (this.classrooms !== null)
        return union(...pluck(this.selectedClassrooms, "students"));
    },

    networkStatus() {
      return this.$store.state.networkStatus;
    }
  },

  watch: {
    networkStatus(val, oldVal) {
      if (val === "online" && oldVal === "offline")
        if (this.retryVisible) this.retry__fetchClassrooms();
    }
  },

  methods: {
    __fetchClassrooms({ quizId }) {
      const url = urls["quizDataClassrooms"]({ quizId });
      return this.$http.get(url);
    },

    __checkScannable() {
      if (this.selectedClassrooms.length === 0) {
        Toast.showWarning(this.$i18n.t("TeacherQuiz.warning.selectClasses"));
      } else {
        let studentQuizNumbers = pluck(
          this.selectedStudents,
          "studentQuizNumber"
        );
        let hasConflict =
          uniq(studentQuizNumbers).length < studentQuizNumbers.length;

        if (hasConflict) Toast.showWarning("所选班级存在考号冲突");
        else return true;
      }
    },

    __generateScanUrl({ quizId, classroomIds }) {
      const rootUrl =
        location.origin +
        location.pathname.substr(0, location.pathname.lastIndexOf("/"));
      const mId = webviewService.get_mId();
      const uav = webviewService.get_uav();

      return `${rootUrl}/scan.html#/teacher/scan?quizId=${quizId}&classroomIds=${classroomIds.join(
        ","
      )}&mId=${mId}&uav=${uav}`;
    },

    fetchClassrooms() {
      Indicator.open(this.$i18n.t("__Common.loading"));

      this.__fetchClassrooms({ quizId: this.quiz.quizId }).then(
        rep => {
          this.classrooms = rep.data.classrooms.map(c =>
            Object.assign({}, c, { selected: false })
          );
          this.classrooms.sort(
            (c1, c2) => c2.students.length - c1.students.length
          );
          Indicator.close();
        },
        err => {
          this.retryVisible = true;
          Indicator.close();
        }
      );
    },

    retry__fetchClassrooms() {
      this.retryVisible = false;
      this.fetchClassrooms();
    },

    selectAll() {
      this.classrooms.forEach(c => {
        if (c.students.length > 0) c.selected = true;
      });
    },
    unselectAll() {
      this.classrooms.forEach(c => (c.selected = false));
    },

    startScan() {
      if (this.__checkScannable()) {
        MessageBox.open(
          {
            message: `您选择的${this.selectedClassrooms.length}个班级内共有${
              this.selectedStudents.length
            }个学生，请准备好对应的答题卡进行扫描`,
            showConfirmButton: true,
            showCancelButton: true
          },
          () => {
            const quizId = this.quiz.quizId;
            const classroomIds = pluck(this.selectedClassrooms, "classroomId");
            this.gotoScan(quizId, classroomIds);
          }
        );
      }
    },

    gotoScan(quizId, classroomIds) {
      const url = this.__generateScanUrl({ quizId, classroomIds });

      if (window.___env === 'webview') {
        webviewService.startScan(url, ({ scannedStudentsCount }) => {
          if (scannedStudentsCount > 0) this.$emit("check-report");
        });
      } else {
        // console.log('go to scan web')
        this.gotoScanWeb(quizId, classroomIds);
      }

      try {
        
      } catch (error) {
        
      }
    },
    gotoScanWeb(quizId, classroomIds) {
      // window.location.href = "/#/webscan";

      this.$router.push({
        name: 'teacherScan',
        query: {
          classroomIds: classroomIds.join(','),
          quizId
        }
      })
    }
  },

  created() {
    this.fetchClassrooms();
  },

  beforeCreate() {
    webviewService.pushBackButtonCallback("quiz.scan", () =>
      this.$router.push({ name: "teacherQuizList" })
    );
  },
  beforeDestroy() {
    webviewService.popBackButtonCallback("quiz.scan");
  }
};
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';
  @import 'src/assets/style/mixins';

  .scan {
    &__retry {
      margin-top: -.1rem;
      height: 2rem;
      background-color: $white;
      font-size: $h5;
      line-height: 2rem;
      text-align: center;
    }

    &__top-bar {
      position: fixed;
      z-index: $highest-z-index;
      top: 2.7rem;
      padding-left: .3rem;
      width: 100%;
      height: .8rem;
      background-color: $light-gray;
      font-size: $h5;
      line-height: 1rem;

      &__title,
      &__button {
        display: inline-block;
      }

      &__button {
        float: right;
        margin-right: .6rem;
        color: $blue;

        &:active {
          opacity: .5;
        }
      }
    }

    &__classroom-list {
      margin: 1.55rem 0 1.2rem 0;
      background-color: $white;
      border-bottom: 1px solid $light-gray;

      &__count {
        padding: .6rem 0;
        background-color: $light-gray;
        color: $dark-gray;
        font-size: $h4;
        text-align: center;
      }
    }

    &__create-classroom-prompt {
      margin-top: -.1rem;
      padding: 1rem 0;
      background-color: $white;
      font-size: $h4;
      text-align: center;
    }

    &__to-classroom-list-button {
      margin-top: .5rem;
      width: 3rem;
      color: $white;
    }

    &__operation {
      position: fixed;
      z-index: $high-z-index;
      bottom: 0;
      width: 100%;
    }

    &__scan-button {
      position: absolute;
      bottom: 0;
      display: inline-block;
      width: 100%;
      height: 1.2rem;
      color: $white;
      font-size: $h4;
      line-height: 1.2rem;
      text-align: center;
    }
  }
</style>
