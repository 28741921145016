import { MessageBox } from 'mint-ui';
import webviewService from '@/services/webview';

const lightBlueStr  = '#26a2ff';
const darkBlueStr   = '#082033';
const darkerBlueStr = '#02060a';

export default {

  confirm(options, onSuccess = () => {}, onFail = () => {}) {
    MessageBox(options).then(action => {
      if (action === 'confirm')
        onSuccess();
      else if (action === 'cancel')
        onFail();
    });
  },

  // 点击 外部区域 会隐藏
  open (options, onSuccess = () => {}, onFail = () => {}) {
    // Trigger click modal event to close message box.
    webviewService.pushBackButtonCallback('messageBox', () => window.document.querySelector('.v-modal').click());
    webviewService.changeStatusBarColor(darkBlueStr);

    // When user click modal.
    window.setTimeout(() => {
      window.document.querySelector('.v-modal').onclick = () => {
        webviewService.popBackButtonCallback('messageBox');
        window.setTimeout(() => webviewService.changeStatusBarColor(lightBlueStr), 200);
      };
    });

    MessageBox(options).then(action => {
      if (action === 'confirm')
        onSuccess();
      else if (action === 'cancel')
        onFail();

      // When user click confirm button or cancel button.
      webviewService.popBackButtonCallback('messageBox');
      window.setTimeout(() => webviewService.changeStatusBarColor(lightBlueStr), 200);
    });
  },


  // Status bar color logic is different in the modal.
  // In current app, only TakerModal component call MessageBox.
  openInModal (options, onSuccess = () => {}, onFail = () => {}) {
    // Trigger click modal event to close message box.
    webviewService.pushBackButtonCallback('messageBox', () => window.document.querySelector('.v-modal').click());
    webviewService.changeStatusBarColor(darkerBlueStr);

    // When user click modal.
    window.setTimeout(() => {
      window.document.querySelector('.v-modal').onclick = () => {
        webviewService.popBackButtonCallback('messageBox');
        window.setTimeout(() => webviewService.changeStatusBarColor(darkBlueStr), 200);
      };
    });

    MessageBox(options).then(action => {
      if (action === 'confirm')
        onSuccess();
      else if (action === 'cancel')
        onFail();

      // When user click confirm button or cancel button.
      webviewService.popBackButtonCallback('messageBox');
      window.setTimeout(() => webviewService.changeStatusBarColor(darkBlueStr), 200);
    });
  }
};
