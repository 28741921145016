import Toast from '@/components/__mint-ui/Toast';
import { MessageBox } from 'mint-ui';

// TODO: translations.
const messages = {
  'ENTER___MOBILE_INVALID': '手机号格式错误',
  'ENTER___VERIFICATIONCODE_NOTCORRECT': '验证码错误',

  'SESSION___LOGIN___MOBILE_INVALID' : '手机号格式错误',
  'SESSION___LOGIN___MOBILE_NOTFOUND': '手机号未注册',
  'SESSION___LOGIN___PASSWORD_NOTCORRECT': '密码错误',

  'REGISTRATION___REGISTRATIONID_NOTFOUND': '',
  'REGISTRATION___MOBILE_ALREADYINUSE': '手机号正在注册中',
  'REGISTRATION___REGISTRATIONSTATUS_NOTCORRECT': '',
  'REGISTRATION___PASSWORD_INVALID': '密码格式错误',
  'REGISTRATION___FULLNAME_INVALID': '姓名格式错误',
  'REGISTRATION___SUBJECT_INVALID': '科目格式错误',

  'ACCOUNT___USERID_NOTFOUND': '',
  'ACCOUNT___USER___FULLNAME_INVALID': '姓名格式错误',
  'ACCOUNT___MOBILE___MOBILE_ALREADYINUSE': '手机号已被占用',
  'ACCOUNT___MOBILE___VERIFICATIONCODE_NOTCORRECT': '验证码错误',
  'ACCOUNT___PASSWORD___CURRENTPASSWORD_NOTCORRECT': '',
  'ACCOUNT___PASSWORD___CURRENTPASSWORD_NOTCORRECT': '',
  'ACCOUNT___PASSWORD___NEWPASSWORD_INVALID': '新密码格式错误',

  'CLASSROOM___CLASSROOMID_NOTFOUND': '',
  'CLASSROOM___STUDENTID_NOTFOUND': '',
  'CLASSROOM___CLASSROOMNAME_INVALID': '名称格式错误',
  'CLASSROOM___STUDENTNAME_INVALID': '姓名格式错误',
  'CLASSROOM___STUDENTQUIZNUMBER_INVALID': '考号格式错误',
  'CLASSROOM___STUDENTQUIZNUMBER_INPUTCONFLICT': '',
  'CLASSROOM___STUDENTQUIZNUMBER_CONFLICT': '考号存在冲突',

  'QUIZDATA___QUIZTAKERSOURCE_ALREADYEXIST': ''
};

let callback_422 = (err, alert=false) => {
  const type = err.data.ERRORS[0].ERROR_TYPE;
  const msg  = messages[type];

  if (alert) { // 强提示 Alert
    MessageBox('提示', msg);
  } else { // 弱提示： Toast
    Toast.showWarning(msg);
  }

};

let callback_500 = () => {
  Toast.showWarning('服务器出了点状况呀');
};

export default {
  handle (err, handle_422 = callback_422, handle_500 = callback_500, alert=false) {
    const status = err.status;

    switch (status) {
      case 422: handle_422(err, alert=false); break;
      case 500: handle_500(); break;
      default: handle_500();
    }
  },
  handleError (err, { handle_422, handle_500, alert} = {}) {
    const status = err.status;

    handle_422 = handle_422 || callback_422;
    handle_500 = handle_500 || callback_500;
    alert = alert || false;

    switch (status) {
      case 422: handle_422(err, alert); break;
      case 500: handle_500(); break;
      default: handle_500();
    }
  }
};
