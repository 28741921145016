<template>
  <div class="summary-bar">
    <div class="summary-bar__items-category">
      <span>
        {{ $t('TeacherQuiz.label.singleQuestion') }}
        <i>{{ singleItemsCount }}</i>
      </span>
      <span>
        {{ $t('TeacherQuiz.label.multipleQuestion') }}
        <i>{{ multipleItemsCount }}</i>
      </span>
      <span>
        {{ $t('TeacherQuiz.label.booleanQuestion') }}
        <i>{{ booleanItemsCount }}</i>
      </span>
      <span>
        {{ $t('TeacherQuiz.label.subjectiveQuestion') }}
        <i>{{ subjectiveItemsCount }}</i>
      </span>
    </div>
    <div class="summary-bar__items-count">
      <span class="summary-bar__items-count__title">
        <span>{{ $t('TeacherQuiz.label.question') }}</span>
      </span>
      <span class="summary-bar__items-count__value">
        {{ itemsCount }}
      </span>
    </div>
    <div class="summary-bar__full-score">
      <span class="summary-bar__full-score__title">
        <span>{{ $t('TeacherQuiz.label.fullScore') }}</span>
      </span>
      <span class="summary-bar__full-score__value">
        {{ fullScore }}
      </span>
    </div>
  </div>
</template>

<script>
  import { where } from 'underscore';

  export default {
    props: ['items'],

    computed: {
      singleItemsCount () {
        return where(this.items, { type: 'single' })['length'];
      },
      multipleItemsCount () {
        return where(this.items, { type: 'multiple' })['length'];
      },
      booleanItemsCount () {
        return where(this.items, { type: 'boolean' })['length'];
      },
      subjectiveItemsCount () {
        return where(this.items, { type: 'subjectiveSkip' })['length'];
      },
      itemsCount () {
        return this.items.length;
      },
      fullScore () {
        return this.items.reduce((acc, i) => acc + i.fullScore, 0);
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .summary-bar {
    &__items-category,
    &__items-count,
    &__full-score {
      position: absolute;
    }

    &__items-category {
      top: 0;
      left: 0;
      width: 60%;
      height: 2rem;
      border-right: 1px solid $light-gray;
      font-size: $h5;

      span {
        display: inline-block;
        padding-left: .3rem;
        width: 2.5rem;
        height: 1rem;
        color: $dark-gray;
        line-height: 1rem;
      }

      i {
        margin-left: .3rem;
        font-size: $h3;
        color: $blue;
      }
    }

    &__items-count,
    &__full-score {
      left: 60%;
      display: inline-block;
      width: 20%;
      height: 2rem;
      border-right: 1px solid $light-gray;

      &__title {
        position: absolute;
        z-index: $high-z-index;
        top: 0;
        left: 0;
        width: .9rem;
        height: .9rem;
        background-image: linear-gradient(135deg, $dark-gray, $dark-gray 50%, transparent 0);
        font-size: $h5 - .1;
        opacity: .5;

        span {
          position: absolute;
          right: .4rem;
          bottom: .5rem;
          display: block;
          color: $white;
          transform: rotate(-45deg);
        }
      }

      &__value {
        display: inline-block;
        width: 2rem;
        line-height: 2rem;
        color: $blue;
        font-size: $h1;
        text-align: center;
      }
    }

    &__full-score {
      left: 80%;
    }
  }
</style>
