export default {
  SET_SESSION (state, { session }) {
    state.session = session;
  },
  UPDATE_SESSION_ACCOUNT (state, { key, newVal }) {
    state.session.account[key] = newVal;
  },
  SET_NETWORK_STATUS (state, { networkStatus }) {
    state.networkStatus = networkStatus;
  },
  SET_DEVICE_INFO (state, deviceInfo) {
    state.deviceInfo = deviceInfo;
  }
};
