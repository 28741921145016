<template>
    <div class="classroom-card" @click="$emit('view-classroom-report')">
        <div class="card-content">
            <div class="card-header">
                {{classroom.classroomName}}
            </div>
            <div class="card-info">
                <div>
                    <label>平均分:</label><span>{{Math.round(classroom.summary.averageScore * 100) / 100}}</span>
                </div>
                <div>
                    <label>得分率:</label><span>{{Math.round(classroom.summary.averageScoreRatio * 100)}}%</span>
                </div>
                <div>
                    <label>最高分:</label><span>{{topScore}}</span>
                </div>
            </div>
        </div>
        <div class="card-controls">
            <i class="fas fa-chevron-right"></i>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['classroom'],
        computed: {
            quizEntries() {
                return this.classroom.quizTakeableEntries.filter(qe => !qe
                    .quizAnswerDeletedAt && !!qe.quizAnswerResult);
            },
            scores() {
                return this.quizEntries.map(qe => qe.quizAnswerResult.summary.totalScore).sort();
            },
            topScore() {
                return Math.max(...this.scores)
            },
        }
    }
</script>

<style scoped lang="scss">
    @import 'src/assets/style/variables';
    @import 'src/assets/style/mixins';

    .classroom-card {
        background-color: $white;
        padding: .6rem .5rem;
        margin: .2rem 0;
        border-bottom: 1px solid $gray;
        display: flex;

        .card-content {

            flex: 1;

            .card-header {
                margin-bottom: .4rem;
                font-size: $h4;
            }


            .card-info {
                display: flex;
                flex-wrap: wrap;


                &>div {
                    width: 33.33%;
                    overflow: hidden;
                }

                label {
                    color: $gray;
                    font-size: $h5;
                }

                span {
                    margin-left: .1rem;
                    font-size: $h5;
                }

            }
        }

        .card-controls {
            width: .5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            color: $blue;
        }


    }
</style>