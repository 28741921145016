<template>
  <div class="student-card" :class="{ 'is-first': isFirst, 'is-selected': student.selected }">
    <label>
      <span class="student-card__student-name">
        {{ student.studentName }}
      </span>
      <span class="student-card__student-quiz-number">
        {{ student.studentQuizNumber }}
      </span>
      <div class="student-card__selector">
        <input
          type="checkbox"
          :checked="student.selected"
          @change="$emit('click-selector')">
        <span></span>
      </div>
    </label>
  </div>
</template>

<script>
  export default {
    props: ['student', 'is-first'],
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';
  @import 'src/assets/style/mixins';

  .student-card {
    border-top: 1px solid $light-gray;

    &.is-first {
      border-top: none;
    }

    &.is-selected {
      color: $blue;
    }

    label {
      @include pressed(0, 0, 0, 0);

      position: relative;
      padding-left: .3rem;
      display: block;
      height: 1.2rem;
      font-size: $h4;
      line-height: 1.2rem;
    }

    &__student-name {
      @include truncate(6rem);
      display: inline-block;
      width: 6rem;
    }

    &__student-quiz-number {
      @include truncate(3rem);
      display: inline-block;
      width: 3rem;
    }

    &__selector {
      position: absolute;
      top: 0;
      right: .1rem;
      height: 1.2rem;
      width: 1rem;
      text-align: center;

      input {
        display: none;
      }

      span {
        position: relative;
        display: inline-block;
        width: .5rem;
        height: .5rem;
        background-color: $white;
        border-radius: 0;
        border: 1px solid $gray;
        vertical-align: middle;

        &:after {
          position: absolute;
          top: .1rem;
          left: .2rem;
          width: .1rem;
          height: .2rem;
          border: 2px solid transparent;
          border-left: 0;
          border-top: 0;
          content: '';
          transform: rotate(45deg) scale(0);
          transition: transform .2s, -webkit-transform .2s;
          -webkit-transform: rotate(45deg); // For android 4.4
        }
      }

      input:checked + span {
        background-color: $blue;
        border-color: $blue;
      }

      input:checked + span:after {
        border-color: $white;
        transform: rotate(45deg) scale(1);
      }
    }
  }
</style>
