<template>
    <div class="unsupported__ios">
        <div class="message-container">
            <p>苹果手机请使用<b>Safari</b>浏览器</p>
            <small class="copy-link"><a @click="copyUrl"><i class="far fa-copy" style="margin-right: 0.1rem"></i>复制链接</a></small>
        </div>
    </div>
</template>

<script>
    import webviewService from '@/services/webview.js'
    export default {
        methods: {
            copyUrl() {
                webviewService.copyToClipboard('https://app.mkyice.cn');
                alert('链接复制成功，请用Safari浏览器访问')
            }
        }

    };
</script>
<style lang="scss" scoped>
    @import 'src/assets/style/variables';

    .unsupported__ios {
        position: relative;
        height: 60vh;
        width: 100%;
        color: $white;
        background-color: $blue;

        .message-container {
            font-size: $h4;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            padding: 1rem;
            justify-content: center;
            align-items: center;

            p {
                margin-bottom: 0.2rem;
            }

        }

        .copy-link {
            font-size: $h5;
            font-weight: lighter;
        }

    }
</style>