<template>
  <div class="edit-answer-modal">
    <div class="edit-answer-modal__content">
      <div class="edit-answer-modal__title">
        <span class="edit-answer-modal__back-button" @click="$emit('close-modal')">
          <back-icon></back-icon>
        </span>
        编辑答案 - {{ quizTaker.quizTakerName }}
      </div>

      <div class="edit-answer-modal__answer-sheet">
        <div class="edit-answer-modal__item-card" v-for="(item,index) in quizAnswerSheet.items" :key="index">
          <div v-if="item.type === 'subjectiveSkip'">
            <div>
              <span>第{{ item.itemOrdinal }}题</span>
              <span>主观题</span>
            </div>
            <div class="edit-answer-modal__no-choice-prompt">
              主观题无选项
            </div>
          </div>

          <div v-else>
            <div>
              <span>第{{ item.itemOrdinal }}题</span>
            </div>
            <div class="edit-answer-modal__choice-list">
              <span
                v-for="(choice, index) in item.answerChoices"
                class="edit-answer-modal__choice-item"
                :class="{ 'is-selected': choice === '1', 'is-correct': item.result === 'correct' }"
                :key="index"
                @click="toggleChoiceSelected(item, index)">
                {{ getBubbleLabel(item.type, index) }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div>
        <mt-button
          class="edit-answer-modal__confirm-button"
          type="primary"
          @click="confirm()">
          {{ $t('TeacherQuiz.label.confirm') }}
        </mt-button>
      </div>
    </div>
    <div class="edit-answer-modal__mask" @click="$emit('close-modal')">
      <nested-mask-layer></nested-mask-layer>
    </div>
  </div>
</template>

<script>
  import BackIcon        from '@/components/BackIcon';
  import NestedMaskLayer from '@/components/NestedMaskLayer';

  import webviewService  from '@/services/webview';
  import { findWhere } from 'underscore';

  export default {
    components: {
      BackIcon,
      NestedMaskLayer
    },

    props: ['entry'],

    data () {
      let items =
        this.entry.quizAnswerResult.items.map(item => {
          if (item.type === 'subjectiveSkip')
            return Object.assign({}, {
              itemOrdinal: item.itemOrdinal,
              type       : item.type
            });
          else
            return Object.assign({}, {
              itemOrdinal   : item.itemOrdinal,
              type          : item.type,
              answerChoices : item.answerChoices
            });
        });

      return {
        quizAnswerSheet: { items }
      };
    },

    computed: {
      quizTaker () {
        return this.entry.quizTaker;
      },

      answerHasChanged () {
        let hasChanged = false;

        this.quizAnswerSheet.items.forEach(
          item => {
            let oldAnswer = findWhere(this.entry.quizAnswerResult.items, { itemOrdinal: item.itemOrdinal }).answerChoices;
            let newAnswer = item.answerChoices;

            if (newAnswer !== oldAnswer) hasChanged = true;
          }
        );

        return hasChanged;
      }
    },

    methods: {
      getBubbleLabel (type, index) {
        if (type === 'single' || type === 'multiple') {
          return ('ABCDEFGHIJK')[index];
        }
        else if (type === 'boolean') {
          return ('TF')[index];
        }
      },

      toggleChoiceSelected (item, choiceIndex) {
        item.answerChoices =
          item.answerChoices
            .split('')
            .map((c, i) => {
              if (i === choiceIndex)
                return (c === '0') ? '1' : '0';
              else
                return c;
            })
            .join('');
      },

      confirm () {
        if (!this.answerHasChanged)
          this.$emit('close-modal');
        else
          this.$emit('click-confirm-button', {
            quizAnswerEntryId: this.entry.quizAnswerEntryId,
            quizAnswerSheet  : this.quizAnswerSheet
          });
      }
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('quiz.report.classroomReport.takerModal.editAnswerModal', () => this.$emit('close-modal'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('quiz.report.classroomReport.takerModal.editAnswerModal');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';
  @import 'src/assets/style/mixins';

  .edit-answer-modal {
    &__content {
      position: fixed;
      z-index: $highest-z-index + 2;
      top: 10%;
      left: 2%;
      width: 96%;
      bottom: 10%;
      background-color: $white;
      border-radius: .1rem;
    }

    &__title {
      height: 1.2rem;
      background-color: $blue;
      border-radius: .1rem .1rem 0 0;
      color: $white;
      font-size: $h4;
      line-height: 1.2rem;
      text-align: center;
    }

    &__back-button {
      position: absolute;
      top: 0;
      left: .15rem;
    }

    &__answer-sheet {
      box-sizing: border-box;
      margin: .3rem 0;
      // height: 7rem;
      // max-height: 7rem;
      height: calc(100% - 2.4rem);
      max-height: calc(100% - 2.4rem);
      overflow-y: auto;
    }

    &__item-card {
      padding: .3rem;
      border-bottom: 1px solid $light-gray;
      font-size: $h5;
    }

    &__no-choice-prompt {
      margin-top: .3rem;
      color: $gray;
    }

    &__choice-list {
      margin-top: .3rem;
    }

    &__choice-item {
      margin-left: .15rem;
      display: inline-block;
      width: .5rem;
      height: .5rem;
      border: 1px solid $black;
      border-radius: 50%;
      box-sizing: border-box;
      line-height: .5rem;
      text-align: center;

      &:first-child {
        margin-left: 0;
      }

      &.is-selected {
        background-color: $blue;
        border-color: $blue;
        color: $white;
      }
    }

    &__confirm-button {
      // margin: .5rem 5%;
      height: 1.2rem;
      width: 100%;
      border-radius: 0;
    }
  }
</style>
