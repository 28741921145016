<template>
  <div class="item-card" :class="{ 'is-selected': item.selected }">
    <div class="item-card__summary">
      <span class="item-card__item-ordinal">
        {{ item.itemOrdinal }}.
      </span>
      <span class="item-card__type">
        <span v-if="item.type === 'single'">({{ $t('TeacherQuiz.label.singleQuestion') }})</span>
        <span v-if="item.type === 'multiple'">({{ $t('TeacherQuiz.label.multipleQuestion') }})</span>
        <span v-if="item.type === 'boolean'">({{ $t('TeacherQuiz.label.booleanQuestion') }})</span>
        <span v-if="item.type === 'subjectiveSkip'">({{ $t('TeacherQuiz.label.subjectiveQuestion') }})</span>
      </span>
      <span class="item-card__full-score" v-if="item.type !== 'subjectiveSkip'">
        {{ item.fullScore }}分
      </span>
      <label class="item-card__selector">
        <input
          type="checkbox"
          :checked="item.selected"
          @change="$emit('click-selector')">
        <span></span>
      </label>
      <span
        v-if="item.type !== 'subjectiveSkip'"
        class="item-card__set-score-button"
        @click="$emit('click-set-score-button', item)">
        <i class="fa fa-pencil"></i>
        <span>设置分数</span>
      </span>
      <span
        class="item-card__edit-button"
        :class="{ 'is-disabled': hasAnswerResult }"
        @click="$emit('click-edit-button', item)">
        <i class="fa fa-pencil-square-o"></i>
        <span>{{ $t('TeacherQuiz.label.edit') }}</span>
      </span>
      <span
        class="item-card__remove-button"
        :class="{ 'is-disabled': hasAnswerResult }"
        @click="$emit('click-remove-button', item)">
        <i class="fa fa-trash-o"></i>
        <span>{{ $t('TeacherQuiz.label.remove') }}</span>
      </span>
    </div>
    <div class="item-card__choice-list">
      <div class="item-card__no-choice" v-if="item.type === 'subjectiveSkip'">
        主观题无选项
      </div>
      <div v-else>
        <span
          v-for="(choice, index) in item.choices"
          class="item-card__choice-item"
          :class="{ 'is-correct': choice === '1' }"
          :key="index"
          @click="toggleChoiceCorrect(index)">
          {{ getBubbleLabel(item.type, index) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['item', 'quiz'],

    computed: {
      hasAnswerResult () {
        return (this.quiz.quizSummary.answerResultsTotal > 0);
      }
    },

    methods: {
      getBubbleLabel (itemType, index) {
        if (itemType === 'single' || itemType === 'multiple') {
          return ('ABCDEFGHIJK')[index];
        }
        else if (itemType === 'boolean') {
          return ('TF')[index];
        }
      },

      toggleChoiceCorrect (index) {
        let choices = '';

        if (this.item.type === 'single' || this.item.type === 'boolean') {
          choices =
            this.item.choices
              .split('')
              .map((c, i) => {
                if (i === index)
                  return (c === '0') ? '1' : '0';
                else
                  return 0;
              })
              .join('');
        }
        else if (this.item.type === 'multiple') {
          choices =
            this.item.choices
              .split('')
              .map((c, i) => {
                if (i === index)
                  return (c === '0') ? '1' : '0';
                else
                  return c;
              })
              .join('');
        }

        this.$emit('click-choice-item', choices, this.item.itemOrdinal);
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .item-card {
    margin-top: .3rem;
    height: 2.4rem;
    background: $white;
    box-sizing: border-box;
    border-top: 1px solid $gray;
    border-bottom: 1px solid $gray;

    &.is-selected {
      border-color: $blue;
    }

    &__summary {
      padding-left: .3rem;
      height: 1rem;
      border-bottom: 1px solid $light-gray;
      font-size: $h5;
      line-height: 1rem;
    }

    &__type {
      margin-left: .3rem;
      color: $blue;
    }

    &__full-score {
      margin-left: .3rem;
      color: $dark-gray;
    }

    &__selector {
      float: right;
      display: inline-block;
      height: 1.2rem;
      width: 1rem;
      text-align: center;

      input {
        display: none;
      }

      span {
        position: relative;
        display: inline-block;
        width: .5rem;
        height: .5rem;
        background-color: $white;
        border-radius: 0;
        border: 1px solid $gray;
        vertical-align: middle;

        &:after {
          position: absolute;
          top: .1rem;
          left: .2rem;
          width: .1rem;
          height: .2rem;
          border: 2px solid transparent;
          border-left: 0;
          border-top: 0;
          content: '';
          transform: rotate(45deg) scale(0);
          transition: transform .2s, -webkit-transform .2s;
          -webkit-transform: rotate(45deg); // For android 4.4
        }
      }

      input:checked + span {
        background-color: $blue;
        border-color: $blue;
      }

      input:checked + span:after {
        border-color: $white;
        transform: rotate(45deg) scale(1);
      }
    }

    &__set-score-button,
    &__remove-button,
    &__edit-button {
      float: right;
      margin-right: .4rem;
      font-size: $h5;

      &:not(.is-disabled):active {
        opacity: .5;
      }
    }

    &__remove-button,
    &__edit-button {
      &.is-disabled {
        color: $gray;
      }
    }

    &__choice-list {
      padding-left: .2rem;
    }

    &__choice-item {
      margin: .35rem 0 0 .15rem;
      display: inline-block;
      width: .65rem;
      height: .65rem;
      border: 1px solid $black;
      border-radius: 50%;
      box-sizing: border-box;
      font-size: $h4;
      line-height: .65rem;
      text-align: center;

      &:active {
        opacity: .5;
      }

      &.is-correct {
        background-color: $blue;
        border-color: $blue;
        color: $white;
      }
    }

    &__no-choice {
      padding-left: .3rem;
      color: $gray;
      font-size: $h4;
      line-height: 1.4rem;
    }
  }
</style>
