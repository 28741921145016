<template>
  <div class="edit-school">
    <search-school
      @click-back-button="$emit('click-back-button')"
      @click-school-item="selectSchool">
    </search-school>
  </div>
</template>

<script>
  import SearchSchool from '@/components/teacher/SearchSchool';

  import Toast     from '@/components/__mint-ui/Toast';
  import Indicator from '@/components/__mint-ui/Indicator';

  import webviewService from '@/services/webview';
  import urls from '@/api/teacher-urls';

  export default {
    components: {
      SearchSchool
    },

    computed: {
      account () {
        return this.$store.state.session.account;
      }
    },

    methods: {
      __editSchool ({ schoolInfo }) {
        const url = urls['editSchool']();
        return this.$http.put(url, { schoolInfo });
      },

      selectSchool (school) {
        const hasChanged = (school.directorySchoolCode !== this.account.schoolInfo.directorySchoolCode);
        if (!hasChanged) return this.$emit('click-back-button');

        Indicator.open();
        this.__editSchool({ schoolInfo: { infoEnterType: 'directorySchool', directorySchoolCode: school.directorySchoolCode } })
            .then(
              rep => {
                Indicator.close();
                Toast.showTip('编辑成功');

                this.$store.commit('UPDATE_SESSION_ACCOUNT', {
                  key: 'schoolInfo',
                  newVal: {
                    infoEnterType      : 'directorySchool',
                    userInputSchoolName: null,
                    directorySchoolCode: school.directorySchoolCode,
                    directorySchoolName: school.name
                  }
                });

                this.$emit('click-school-item');
              },
              err => {
                Indicator.close();
              }
            );
      }
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('profile.editAccount.editSchool', () => this.$emit('click-back-button'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('profile.editAccount.editSchool');
    }
  };
</script>

<style lang="scss" scoped>

</style>
