<template>
  <div class="edit-item-modal">
    <div class="edit-item-modal__content">
      <div class="edit-item-modal__title">
        <span class="edit-item-modal__back-button" @click="$emit('close-modal')">
          <back-icon></back-icon>
        </span>
        编辑第{{ newItem.itemOrdinal }}题
        <span class="edit-item-modal__reset-button" @click="reset()">
          <i class="fa fa-undo"></i>
          重置
        </span>
      </div>
      <div class="edit-item-modal__select-type-prompt">
        <span class="edit-item-modal__select-type-prompt__title">设置题型</span>
      </div>
      <div class="edit-item-modal__select-type">
        <select @change="selectType(newItemType)" v-model="newItemType">
          <option value="single">{{ $t('TeacherQuiz.label.singleQuestion') }}</option>
          <option value="multiple">{{ $t('TeacherQuiz.label.multipleQuestion') }}</option>
          <option value="boolean">{{ $t('TeacherQuiz.label.booleanQuestion') }}</option>
          <option value="subjectiveSkip">{{ $t('TeacherQuiz.label.subjectiveQuestion') }}</option>
        </select>
      </div>
      <div class="edit-item-modal__choice-list-prompt">
        <span class="edit-item-modal__choice-list-prompt__title">设置选项</span>
        <span
          v-if="newItem.type === 'single' || newItem.type === 'multiple'"
          class="edit-item-modal__add-choice-button"
          @click="addChoice()">
          增加选项
          <i class="fa fa-plus-circle"></i>
        </span>
        <span
          v-if="newItem.type === 'single' || newItem.type === 'multiple'"
          class="edit-item-modal__remove-choice-button"
          @click="removeChoice()">
          减少选项
          <i class="fa fa-minus-circle"></i>
        </span>
      </div>
      <div class="edit-item-modal__choice-list">
        <div class="edit-item-modal__no-choice" v-if="newItem.type === 'subjectiveSkip'">
          主观题无选项
        </div>
        <div v-else>
          <span
            v-for="(choice, index) in newItem.choices"
            class="edit-item-modal__choice-item"
            :class="{ 'is-correct': choice === '1' }"
            :key="index"
            @click="toggleChoiceCorrect(index)">
            {{ getBubbleLabel(newItem.type, index) }}
          </span>
        </div>
      </div>
      <div class="edit-item-modal__set-full-score-prompt">
        设置分数
      </div>
      <div class="edit-item-modal__set-full-score">
        <div class="edit-item-modal__set-full-score-unsupported" v-if="newItem.type === 'subjectiveSkip'">
          主观题暂不支持设置分数
        </div>
        <div v-else>
          <mt-field
            v-model.number="newItem.fullScore"
            :placeholder="$t('TeacherQuiz.placeholder.questionScore')"
            type="number"
            disableClear>
          </mt-field>
        </div>
      </div>
      <mt-button
        class="edit-item-modal__confirm-button"
        type="primary"
        @click="confirm()">
        {{ $t('TeacherQuiz.label.confirm') }}
      </mt-button>
    </div>
    <div class="edit-item-modal__mask" @click="$emit('close-modal')">
      <mask-layer></mask-layer>
    </div>
  </div>
</template>

<script>
  import BackIcon  from '@/components/BackIcon';
  import MaskLayer from '@/components/MaskLayer';

  import Toast from '@/components/__mint-ui/Toast';
  import webviewService  from '@/services/webview';

  export default {
    components: {
      BackIcon,
      MaskLayer
    },

    props: ['item'],

    data () {
      return {
        newItem: Object.assign({}, this.item),
        newItemType: this.item.type
      };
    },

    methods: {
      reset () {
        this.newItem = Object.assign({}, this.item);
      },

      selectType (type) {
        this.newItem.type = type;

        if (this.newItem.type === 'single' || this.newItem.type === 'multiple') {
          this.$set(this.newItem, 'choices', '0000');
        }
        else if (this.newItem.type === 'boolean') {
          this.$set(this.newItem, 'choices', '00');
        }
        else if (this.newItem.type === 'subjectiveSkip') {
          this.newItem.fullScore = null;
          this.$delete(this.newItem, 'choices');
        }

        Toast.showTip('设置题型生效');
      },

      addChoice () {
        if (this.newItem.choices.length < 11)
          this.newItem.choices = this.newItem.choices + '0';
        else
          Toast.showWarning('选项数量不能超过11');
      },

      removeChoice () {
        if (this.newItem.choices.length > 2)
          this.newItem.choices = this.newItem.choices.slice(0, this.newItem.choices.length - 1);
        else
          Toast.showWarning('选项数量不能小于2');
      },

      toggleChoiceCorrect (index) {
        if (this.newItem.type === 'single' || this.newItem.type === 'boolean') {
          this.newItem.choices =
            this.newItem.choices
              .split('')
              .map((c, i) => {
                if (i === index)
                  return (c === '0') ? '1' : '0';
                else
                  return 0;
              })
              .join('');
        }
        else if (this.newItem.type === 'multiple') {
          this.newItem.choices =
            this.newItem.choices
              .split('')
              .map((c, i) => {
                if (i === index)
                  return (c === '0') ? '1' : '0';
                else
                  return c;
              })
              .join('');
        }
      },

      getBubbleLabel (itemType, index) {
        if (itemType === 'single' || itemType === 'multiple') {
          return ('ABCDEFGHIJK')[index];
        }
        else if (itemType === 'boolean') {
          return ('TF')[index];
        }
      },

      confirm () {
        if (this.newItem.type !== 'subjectiveSkip' && this.newItem.fullScore <= 0)
          Toast.showWarning('试题分数需大于0');
        else
          this.$emit('click-confirm-button', this.newItem);
      }
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('quiz.bubblesheet.editItemModal', () => this.$emit('close-modal'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('quiz.bubblesheet.editItemModal');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .edit-item-modal {
    &__content {
      position: fixed;
      z-index: $highest-z-index + 2;
      top: 1.9rem;
      left: 2%;
      width: 96%;
      background-color: $white;
      border-radius: .1rem;
    }

    &__title {
      height: 1.2rem;
      background-color: $blue;
      border-radius: .1rem .1rem 0 0;
      color: $white;
      font-size: $h4;
      line-height: 1.2rem;
      text-align: center;
    }

    &__back-button {
      position: absolute;
      top: 0;
      left: .15rem;
    }

    &__reset-button {
      position: absolute;
      top: .05rem;
      right: -.2rem;
      display: inline-block;
      width: 2rem;
      height: 1.2rem;
      font-size: $h5;

      &:active {
        opacity: .5;
      }
    }

    &__select-type-prompt {
      margin: .5rem 0 .1rem 0;
      padding-left: .2rem;
      font-size: $h5;

      &__title {
        font-weight: $bold;
      }
    }

    &__select-type {
      padding: .2rem;
      width: 100%;
      border-bottom: 1px solid $light-gray;
      font-size: $h4;

      select {
        padding: .2rem 1.2rem .2rem .3rem;
        width: 3.5rem;
        background-image: url('~images/arrow.png');
        background-position: 90% center;
        background-repeat: no-repeat;
        background-size: .3rem;
        border: 1px solid $gray;

        &:active {
          opacity: .5;
        }
      }
    }

    &__choice-list-prompt {
      margin: .5rem 0 .1rem 0;
      padding-left: .2rem;
      font-size: $h5;

      &__title {
        font-weight: $bold;
      }
    }

    &__add-choice-button,
    &__remove-choice-button {
      float: right;
      margin-right: .5rem;
      color: $blue;

      &:active {
        opacity: .5;
      }
    }

    &__choice-list {
      padding: .2rem;
      border-bottom: 1px solid $light-gray;
    }

    &__choice-item {
      margin-left: .15rem;
      display: inline-block;
      width: .65rem;
      height: .65rem;
      border: 1px solid $black;
      border-radius: 50%;
      box-sizing: border-box;
      font-size: $h4;
      line-height: .65rem;
      text-align: center;

      &:active {
        opacity: .5;
      }

      &:first-child {
        margin-left: 0;
      }

      &.is-correct {
        background-color: $blue;
        border-color: $blue;
        color: $white;
      }
    }

    &__no-choice {
      display: inline-block;
      height: .6rem;
      color: $gray;
      font-size: $h4;
      line-height: .6rem;
      text-align: center;
    }

    &__set-full-score-prompt {
      margin: .5rem 0 .1rem 0;
      padding-left: .2rem;
      font-size: $h5;
      font-weight: $bold;
    }

    &__set-full-score {
      margin-top: .2rem;
      width: 100%;
    }

    &__set-full-score-unsupported {
      padding: .2rem;
      display: inline-block;
      height: .6rem;
      border-bottom: 1px solid $light-gray;
      color: $gray;
      font-size: $h4;
      line-height: .6rem;
      text-align: center;
    }

    &__confirm-button {
      margin: .5rem 5%;
      width: 90%;
    }
  }
</style>

