<template>
  <div class="edit-quiz">
    <div class="edit-quiz__header">
      <mt-header fixed :title="$t('TeacherQuizList.title.editQuiz')">
        <router-link to="" slot="left">
          <mt-button icon="back" @click="$router.go(-1)"></mt-button>
        </router-link>
      </mt-header>
    </div>
    <div class="edit-quiz__main">
      <mt-field
        v-model.trim="quizName"
        class="edit-quiz__quiz-name"
        :placeholder="$t('TeacherQuizList.placeholder.quizName')"
        type="text"
        disableClear
      >
      </mt-field>
      <!--
      <mt-field
        v-model.trim="quizDescription"
        class="edit-quiz__quiz-description"
        :placeholder="$t('TeacherQuizList.placeholder.quizDescription')"
        type="text"
        disableClear>
      </mt-field>
-->
    </div>
    <div class="edit-quiz__footer">
      <mt-button class="edit-quiz__done-button" type="primary" @click="done()">
        确认
      </mt-button>
      <!-- <div class="edit-quiz__footer-divider" /> -->
      <!-- <mt-button class="edit-quiz__delete-button" type="danger" @click="removeQuiz()">
        删除
      </mt-button> -->
    </div>

    <div class="edit-quiz__remove-quiz-prompt">
      <span @click="removeQuiz()">删除测验</span>
    </div>
  </div>
</template>

<script>
// import SlideTransition from '@/components/SlideTransition';

import Toast from "@/components/__mint-ui/Toast";
import Indicator from "@/components/__mint-ui/Indicator";
import MessageBox from "@/components/__mint-ui/MessageBox";

import urls from "@/api/teacher-urls";
import checkAccess from "@/services/check-access";
import webviewService from "@/services/webview";

export default {
  components: {
    // SlideTransition
  },
  data() {
    return {
      quiz: null,
      quizId: null,
      quizName: null,
      quizDescription: null,
    };
  },

  computed: {
    quizNameHasChanged() {
      return this.quizName !== this.quiz.quizName;
    },
    quizDescriptionHasChanged() {
      return this.quizDescription !== this.quiz.quizDescription;
    },
  },

  methods: {
    __fetchQuiz({ quizId }) {
      const url = urls["quiz"]({
        quizId,
      });
      return this.$http.get(url);
    },

    fetchQuiz() {
      Indicator.open(this.$i18n.t("__Common.loading"));

      this.__fetchQuiz({
        quizId: this.$route.params.quizId,
      }).then(
        (rep) => {
          this.quiz = rep.data;
          (this.quizId = this.quiz.quizId),
            (this.quizName = this.quiz.quizName),
            (this.quizDescription = this.quiz.quizDescription),
            Indicator.close();
        },
        (err) => {
          this.retryVisible = true;
          Indicator.close();
        }
      );
    },

    __editQuiz({ quizId, quizName, quizDescription }) {
      const url = urls["quiz"]({ quizId });
      return this.$http.put(url, { quizId, quizName, quizDescription });
    },

    __removeQuiz({ quizId }) {
      const url = urls["quiz"]({ quizId });
      return this.$http.delete(url);
    },

    done() {
      if (!this.quizName) {
        Toast.showWarning("请填写测验名称");
      } else if (!this.quizNameHasChanged && !this.quizDescriptionHasChanged) {
        this.$router.go(-1);
      } else {
        Indicator.open();
        this.__editQuiz({
          quizId: this.quizId,
          quizName: this.quizName,
          quizDescription: this.quizDescription,
        }).then(
          (rep) => {
            Indicator.close();
            this.$router.go(-1);
            Toast.showTip("编辑成功");
          },
          (err) => {
            Indicator.close();
          }
        );
      }
    },

    removeQuiz() {
      MessageBox.open(
        {
          message: `确认删除${this.quiz.quizName}吗?`,
          showConfirmButton: true,
          showCancelButton: true,
        },
        () => {
          this.__removeQuiz({ quizId: this.quiz.quizId }).then((rep) => {
            this.$router.push({ name: "teacherQuizList" });
            Toast.showTip("删除成功");
          });
        }
      );
    },
  },

  created() {
    this.fetchQuiz();
  },

  mounted() {
    window.setTimeout(() => {
      this.$el.querySelector("input").focus();
    }, 300);
  },

  beforeCreate() {
    checkAccess("loggedIn");
    webviewService.pushBackButtonCallback("quiz.editQuiz", () =>
      this.$router.go(-1)
    );
  },
  beforeDestroy() {
    webviewService.popBackButtonCallback("quiz.editQuiz");
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/variables";

.edit-quiz {
  &__header {
    position: relative;
    height: $header-height;
  }

  &__main {
    margin-top: 0.5rem;
  }

  &__footer {
    text-align: center;
  }

  &__done-button {
    margin-top: 0.7rem;
    margin-bottom: 0.3rem;
    width: 9rem;
  }

&__delete-button {
    margin-top: 0.3rem;
    width: 9rem;
  }

  &__footer-divider {
      border-bottom: .5px solid #d4d4d4;
      width: 96%;
    //   padding-top: .2rem;
      margin: auto;
  }

  &__remove-quiz-prompt {
    margin-top: 0.7rem;
    width: 100%;
    color: $red;
    font-size: $h5;
    text-align: center;
    text-decoration: underline;

    span {
      &:active {
        opacity: 0.5;
      }
    }
  }
}
</style>
