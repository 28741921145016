<template>
  <div class="select-subject">
    <div class="select-subject__header">
      <mt-header fixed title="选择任教科目">
        <router-link to="" slot="left">
          <mt-button icon="back" @click="$emit('click-back-button')"></mt-button>
        </router-link>
      </mt-header>
    </div>
    <div class="select-subject__main">
      <div
        v-for="(op, index) in subjectOptions"
        class="select-subject__subject-item"
        :class="{ 'is-first': index === 0 }"
        :key="index"
        @click="$emit('click-subject-item', op)">
        {{ op.label }}
      </div>
    </div>
  </div>
</template>

<script>
  import subjectOptions from '@/services/subject-options';
  import webviewService from '@/services/webview';

  export default {
    data () {
      return {
        subjectOptions
      };
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('enter.register.selectSubject', () => this.$emit('click-back-button'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('enter.register.selectSubject');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .select-subject {
    &__header {
      position: relative;
      height: 1.2rem;
      background-color: $blue;
    }

    &__main {
      margin-top: .5rem;
      padding-left: .3rem;
      background-color: $white;
      border-top: 1px solid $light-gray;
      border-bottom: 1px solid $light-gray;
    }

    &__subject-item {
      padding: .3rem 0;
      border-top: 1px solid $light-gray;
      font-size: $h4;

      &.is-first {
        border-top: none;
      }
    }
  }
</style>
