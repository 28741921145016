<template>
  <div class="quiz-card">

    <div
      class="quiz-card__main"
      @contextmenu="handleContext($event)"
      @click="toQuizPage"
      v-touch:touchhold="handlePress">
      <div class="quiz-card__name">{{ quiz.quizName }}</div>
      <div class="quiz-card__summary">
        <div class="quiz-card__questions-count">
          试题数量: {{ itemsCount }}
        </div>
        <div class="quiz-card__scanned-count">
          扫描数量: {{ scansCount }}
        </div>
        <div class="quiz-card__created-date">
          创建时间: {{ createdDate }}
        </div>
        <div class="quiz-card__average-correct-rate">
          <span class="quiz-card__average-correct-rate__title">
            {{ $t('TeacherQuizList.label.averageCorrectRate') }}
          </span>
          <span class="quiz-card__average-correct-rate__value">
            <i>{{ averageCorrectRate }}</i>%
          </span>
          <span class="quiz-card__average-correct-rate__bar">
            <mt-progress :value="averageCorrectRate" :bar-height="10"></mt-progress>
          </span>
        </div>
      </div>

      <div class="quiz-card__nonscan-mark" v-if="scansCount === 0">
        <span>{{ $t('TeacherQuizList.label.nonscan') }}</span>
      </div>
      <div class="quiz-card__scanned-mark" v-else>
        <span>已扫</span>
      </div>
    </div>

    <operations-popup
      v-if="operationsPopupVisible"
      @click-view-button="toQuizPage()"
      @click-duplicate-button="duplicateQuiz()"
      @click-edit-button="toEditQuiz()"
      @click-remove-button="removeQuiz()"
      @touch-mask="operationsPopupVisible = false">
    </operations-popup>
  </div>
</template>

<script>
  import OperationsPopup from './_quiz-card/OperationsPopup';

  import Toast      from '@/components/__mint-ui/Toast';
  import MessageBox from '@/components/__mint-ui/MessageBox';

  import urls from '@/api/teacher-urls';

  import formatDate     from '@/services/format-date';
  import webviewService from '@/services/webview';

  export default {
    components: {
      OperationsPopup
    },

    props: ['quiz'],

    data () {
      return {
        operationsPopupVisible: false, 
        editQuizVisible: false
      };
    },

    computed: {
      itemsCount () {
        return this.quiz.quizPaper.bubbleSheetStructure.items.length;
      },
      scansCount () {
        return this.quiz.quizSummary.answerResultsTotal;
      },
      averageCorrectRate () {
        const ratio = this.quiz.quizSummary.averageScoreRatio;
        return Number((ratio * 100).toFixed(0));
      },
      createdDate () {
        return formatDate(this.quiz.quizCreatedAt * 1000);
      }
    },

    methods: {
      __duplicateQuiz ({ quizId, quizName }) {
        const url = urls['duplicateQuiz']({ quizId });
        return this.$http.post(url, { quizId, quizName });
      },

      __removeQuiz ({ quizId }) {
        const url = urls['quiz']({ quizId });
        return this.$http.delete(url);
      },

      __backTop () {
        window.scrollTo(0, 0);
      },

      toEditQuiz () {
        this.$router.push({
          name: 'teacherEditQuiz',
          params: { quizId: this.quiz.quizId, quizName: this.quiz.quizName }
        });
      },

      duplicateQuiz () {
        this.operationsPopupVisible = false;
        this.__backTop();
        this.__duplicateQuiz({ quizId: this.quiz.quizId, quizName: `${this.quiz.quizName} (复制)` })
            .then(
              rep => {
                this.$emit('duplicate-quiz-done');
                Toast.showTip('复制成功');
              },
              err => {}
            );
      },

      removeQuiz () {
        MessageBox.open(
          {
            message: `确认删除${this.quiz.quizName}吗?`,
            showConfirmButton: true,
            showCancelButton: true
          },
          () => {
            this.operationsPopupVisible = false;
            this.__removeQuiz({ quizId: this.quiz.quizId })
                .then(rep => {
                  this.$emit('remove-quiz-done');
                  Toast.showTip('删除成功');
                });
          },
          () => {
            this.operationsPopupVisible = false;
          }
        );
      },

      toQuizPage () {
        this.$router.push({
          name: 'teacherQuiz',
          params: { quizId: this.quiz.quizId, quizName: this.quiz.quizName }
        });
      },

      handlePress () {
        webviewService.haptic();
        this.operationsPopupVisible = true;
      },

      handleContext (e) {
        e.preventDefault()
        this.handlePress();
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';
  @import 'src/assets/style/mixins';

  .quiz-card {
    &__main {
      @include pressed(0, 0, 0, 0);
      position: relative;
      margin-top: .25rem;
      // margin-bottom: 32px;
      padding: .5rem .3rem 0 .3rem;
      height: 3rem;
      background-color: $white;
      border-radius: 2%;
    }

    &__name {
      @include truncate(95%);
      font-size: $h4;
    }

    &__summary {
      position: relative;
      margin-top: .35rem;
      color: $dark-gray;
      font-size: $h5;
    }

    &__questions-count,
    &__scanned-count,
    &__created-date {
      margin-bottom: .2rem;
    }

    &__average-correct-rate {
      position: absolute;
      top: 0;
      left: 3.15rem;
      padding-left: .5rem;
      height: 1.4rem;
      border-left: 1px solid $gray;
      font-size: $h4;

      &__title {
        display: inline-block;
        height: .5rem;
      }

      &__value {
        position: absolute;
        top: -.3rem;
        left: 1.85rem;

        i {
          font-size: $h2;
        }
      }

      &__bar {
        margin-top: .3rem;
        display: block;
        width: 4.6rem;
        vertical-align: top;
      }
    }

    &__nonscan-mark,
    &__scanned-mark {
      position: absolute;
      z-index: $high-z-index;
      right: 0;
      bottom: 0;
      width: 1.2rem;
      height: 1.2rem;
      background-image: linear-gradient(-45deg, $dark-gray, $dark-gray 50%, transparent 0);
      font-size: $h5;
      border-radius: 0 0 5% 0;

      span {
        position: absolute;
        top: .65rem;
        left: .5rem;
        display: block;
        color: $white;
        font-weight: $bold;
        transform: rotate(-45deg);
      }
    }

    &__scanned-mark {
      background-image: linear-gradient(-45deg, $blue, $blue 50%, transparent 0);
    }
  }


</style>
