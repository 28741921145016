<template>
<!-- Don't rely on css class in mint-ui directly -->
  <div class="back-icon">
    <i class="mintui mintui-back"></i>
  </div>
</template>

<script>
  export default {};
</script>

<style lang="scss" scoped>

</style>
