<template>
  <div class="footer-tab-bar">
    <mt-tabbar v-model="selectedTab" :fixed="true">
      <mt-tab-item id="classroom">
        <div @click="$router.push({ name: 'teacherClassroomList' })">
          <i class="far fa-address-book light"></i>
          <span>{{ $t('__Common.footerTabBar.class') }}</span>
        </div>
      </mt-tab-item>
      <mt-tab-item id="quiz">
        <div @click="$router.push({ name: 'teacherQuizList' })">
          <i class="far fa-file-alt light"></i>
          <span>{{ $t('__Common.footerTabBar.quiz') }}</span>
        </div>
      </mt-tab-item>
      <!-- <mt-tab-item id="discover">
        <div @click="$router.push({ name: 'teacherDiscover' })">
          <i class="far fa-compass light"></i>
          <span>{{ $t('__Common.footerTabBar.discover') }}</span>
        </div>
      </mt-tab-item> -->
      <mt-tab-item id="me">
        <div @click="$router.push({ name: 'teacherProfile' })">
          <i class="far fa-user light"></i>
          <span>{{ $t('__Common.footerTabBar.me') }}</span>
        </div>
      </mt-tab-item> 
    </mt-tabbar>
  </div>
</template>

<script>
  export default {
    props: ['currentTab'],

    data () {
      return {
        selectedTab: this.currentTab
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .footer-tab-bar {
    
    i {
      -webkit-text-stroke: 0.5px #fafafa;
    }
  }
</style>
