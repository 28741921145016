<template>
  <div class="item-modal">
    <div class="item-modal__content">
      <div class="item-modal__title">
        <span class="item-modal__back-button" @click="$emit('close-modal')">
          <back-icon></back-icon>
        </span>
        第{{ item.itemOrdinal }}题
      </div>
      <div class="item-modal__table">
        <div class="item-modal__table-header">
          <span>选项</span>
          <span>选择率</span>
        </div>
        <div class="item-modal__table-content">
          <div class="item-modal__choice-card" v-for="(stat, index) in item.choicesStats" :key="index"
            @click="selectItem(index)">
            <div class="item-modal__choice-card-header">
              <span class="item-modal__choice-card-label" :class="{ 'is-correct': stat.choice === '1' }">
                {{ getBubbleLabel(item.type, index) }}
              </span>
              <span class="item-modal__selected-rate">
                <span class="item-modal__selected-rate__bar">
                  <i>{{ formatRatio(stat.selectedTakersRatio) }}%</i>
                  <mt-progress :value="formatRatio(stat.selectedTakersRatio)" :bar-height="40"></mt-progress>
                </span>
              </span>
              <span class="item-modal__expand-icon">
                <i v-if="selectedItemIndex === index" class="fas fa-chevron-down"></i>
                <i v-else class="fas fa-chevron-right"></i>
              </span>
            </div>
            <el-collapse-transition>
              <div class="item-modal__choice-card__taker-details" v-show="selectedItemIndex === index">
                <div class="header">
                  <span>选择的学生 ({{stat.selectedTakers.length}})</span>
                </div>
                <div class="item-modal__choice-card__taker-details__taker-card" v-for="taker in stat.selectedTakers"
                  :key="taker.quizTakerId">
                  <div class="item-modal__choice-card__taker-details__taker-number">
                    {{taker.quizTakerNumber}}
                  </div>
                  <div class="item-modal__choice-card__taker-details__taker-name">
                    {{taker.quizTakerName}}
                  </div>
                  <div class="item-modal__choice-card__taker-details__taker-class">
                    {{taker.quizTakerSource.classroomName}}
                  </div>
                </div>
              </div>
            </el-collapse-transition>
          </div>
        </div>
      </div>
    </div>
    <div class="item-modal__mask" @click="$emit('close-modal')">
      <mask-layer></mask-layer>
    </div>
  </div>
</template>

<script>
  import BackIcon from '@/components/BackIcon';
  import MaskLayer from '@/components/MaskLayer';
  import webviewService from '@/services/webview';

  // expand
  import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
  import Vue from 'vue'
  Vue.component(CollapseTransition.name, CollapseTransition)

  export default {
    components: {
      BackIcon,
      MaskLayer
    },
    props: ['item'],
    data() {
      return {
        selectedItemIndex: null
      }
    },
    methods: {
      getBubbleLabel(type, index) {
        if (type === 'single' || type === 'multiple') {
          return ('ABCDEFGHIJK')[index];
        } else if (type === 'boolean') {
          return ('TF')[index];
        }
      },
      formatRatio(ratio) {
        return Number((ratio * 100).toFixed(0));
      },
      selectItem(index) {
        if (this.selectedItemIndex === index) {
          this.selectedItemIndex = null;
        } else {
          this.selectedItemIndex = index
        }
      }
    },
    created() {
      console.log(this.item)
    },
    beforeCreate() {
      webviewService.pushBackButtonCallback('quiz.report.itemReport.itemModal', () => this.$emit('close-modal'));
    },
    beforeDestroy() {
      webviewService.popBackButtonCallback('quiz.report.itemReport.itemModal');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .item-modal {
    &__content {
      position: fixed;
      z-index: $highest-z-index + 2;
      top: 10%;
      bottom: 10%;
      left: 2%;
      width: 96%;
      // height: 10rem;
      background-color: $white;
      border-radius: .1rem;
    }

    &__title {
      height: 1.2rem;
      background-color: $blue;
      border-radius: .1rem .1rem 0 0;
      color: $white;
      font-size: $h4;
      line-height: 1.2rem;
      text-align: center;
    }

    &__back-button {
      position: absolute;
      top: 0;
      left: .15rem;
    }

    &__table {
      margin: .3rem 0;
      // height: 8rem;
      // max-height: 8rem;
      height: calc(100% - 1.8rem);
      // box-sizing: border-box;
      overflow-y: auto;
    }

    &__choice-list {
      position: relative;
      padding: .2rem;
      border-bottom: 1px solid $light-gray;
    }

    &__correct-rate {
      position: absolute;
      top: -.1rem;
      right: .3rem;
      color: $blue;

      span {
        font-size: $h2;
      }

      i {
        font-size: $h5;
      }
    }

    &__choice-item,
    &__choice-card-label {
      margin-left: .15rem;
      display: inline-block;
      width: .5rem;
      height: .5rem;
      border: 1px solid $black;
      border-radius: 50%;
      box-sizing: border-box;
      font-size: $h5;
      line-height: .5rem;
      text-align: center;

      &:first-child {
        margin-left: 0;
      }

      &.is-correct {
        background-color: $blue;
        border-color: $blue;
        color: $white;
      }
    }

    &__table-header {
      margin: .3rem;
      height: .3rem;
      letter-spacing: -1rem;
      line-height: .3rem;

      span {
        float: left;
        display: inline-block;
        width: 3rem;
        font-size: $h5;
        letter-spacing: 0;
      }
    }

    &__table-content {
      padding: 0 .3rem;
    }

    &__choice-card {
      position: relative;
      // height: 1.2rem;
      border-bottom: 1px solid #f5f5f5;
      font-size: 0.4rem;
      line-height: 1.2rem;

      &__taker-details {

        .header {
          font-size: $h5;
          color: $dark-gray;
          line-height: 0.75rem;
        }

        &__taker-card {
          font-size: $h5 + .05rem;
          display: flex;
          line-height: .85rem;
        }

        &__taker-number {
          width: 33%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &__taker-name {
          width: 30%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &__taker-class {
          width: 36%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    &__choice-card-label,
    &__selected-rate,
    &__expand-icon {
      display: inline-block;
    }

    &__selected-rate {
      margin-left: 2.5rem;
      position: relative;
      width: 4rem;

      &__bar {
        display: inline-block;
        width: 4rem;
        vertical-align: middle;
      }

      i {
        position: absolute;
        z-index: $high-z-index;
        top: .05rem;
        left: .1rem;
        color: $black;
        font-size: $h5;
      }
    }

    &__expand-icon {
      margin-left: 1.5rem;
      color: $blue;
    }
  }
</style>