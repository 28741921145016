<template>
  <div class="create-student">
    <div class="create-student__header">
      <mt-header fixed :title="$t('TeacherClassroom.title.createStudent')">
        <router-link to="" slot="left">
          <mt-button icon="back" @click="$emit('click-back-button')"></mt-button>
        </router-link>
      </mt-header>
    </div>
    <div class="create-student__main">
      <div class="create-student__prompt">
        {{ $t('TeacherClassroom.studentQuizNumberPrompt') }}
      </div>
      <mt-field
        v-model.trim="studentName"
        class="create-student__student-name"
        :placeholder="$t('TeacherClassroom.placeholder.studentName')"
        type="text"
        disableClear>
      </mt-field>
      <mt-field
        v-model.trim="studentQuizNumber"
        class="create-student__student-quiz-number"
        :placeholder="$t('TeacherClassroom.placeholder.studentQuizNumber')"
        type="number"
        disableClear>
      </mt-field>
    </div>
    <div class="create-student__footer">
      <mt-button
        class="create-student__confirm-button"
        type="primary"
        @click="confirm()">
        {{ $t('TeacherClassroom.label.confirm') }}
      </mt-button>
    </div>

    <div class="create-student__check-student-list">
      <span @click="studentListModalVisible = true">
        查看已创建学生({{ classroom.students.length }})
      </span>
    </div>

    <div class="create-student__upload-students-tip">
      <div class="create-student__upload-students-tip__title">
        <i class="fa fa-cloud-upload"></i>
        <span>您可以批量上传学生!</span>
      </div>
      <div class="create-student__upload-students-tip__content">
        在电脑浏览器中输入网址 <b>http://mkyice.cn</b>, 并登录账号,即可使用excel批量上传学生名单。
      </div>
    </div>
    <modal-transition>
      <student-list-modal
        v-if="studentListModalVisible"
        :students="classroom.students"
        @close-modal="studentListModalVisible = false">
      </student-list-modal>
    </modal-transition>
  </div>
</template>

<script>
  import ModalTransition from '@/components/ModalTransition';
  import StudentListModal from './_create-student/StudentListModal';

  import Toast      from '@/components/__mint-ui/Toast';
  import Indicator  from '@/components/__mint-ui/Indicator';
  import MessageBox from '@/components/__mint-ui/MessageBox';

  import urls         from '@/api/teacher-urls';
  import errorHandler from '@/api/error-handler';

  import webviewService from '@/services/webview';

  export default {
    components: {
      ModalTransition,
      StudentListModal
    },

    props: ['classroom'],

    data () {
      return {
        studentName: '',
        studentQuizNumber: '',

        studentListModalVisible: false
      }
    },

    computed: {
      studentQuizNumberIsValid () {
        let isNumber = /^[0-9]+$/.test(this.studentQuizNumber);
        let validDigit = (this.studentQuizNumber.length >= 3) && (this.studentQuizNumber.length <= 10);

        return isNumber && validDigit;
      }
    },

    methods: {
      __createStudent ({ classroomId, studentName, studentQuizNumber }) {
        const url = urls['classroomStudents']({ classroomId });
        return this.$http.post(url, { students: [{ studentName, studentQuizNumber }] });
      },

      confirm () {
        if (!this.studentName) {
          Toast.showWarning(this.$i18n.t('TeacherClassroom.warning.inputStudentName'));
        }
        else if (!this.studentQuizNumber) {
          Toast.showWarning(this.$i18n.t('TeacherClassroom.warning.inputstudentQuizNumber'));
        }
        else if (!this.studentQuizNumberIsValid) {
          Toast.showWarning(this.$i18n.t('TeacherClassroom.warning.invalidstudentQuizNumber'));
        }
        else {
          let classroomId       = this.classroom.classroomId;
          let studentName       = this.studentName;
          let studentQuizNumber = this.studentQuizNumber;

          Indicator.open();
          this.__createStudent({ classroomId, studentName, studentQuizNumber })
              .then(
                rep => {
                  this.studentName       = '';
                  this.studentQuizNumber = '';

                  Indicator.close();
                  MessageBox.open(
                    {
                      title: this.$i18n.t('TeacherClassroom.label.success'),
                      message: this.$i18n.t('TeacherClassroom.message.studentInfo', { studentName, studentQuizNumber }),
                      showConfirmButton: true,
                      showCancelButton: true,
                      confirmButtonText: this.$i18n.t('TeacherClassroom.label.continue'),
                      cancelButtonText: this.$i18n.t('TeacherClassroom.label.done')
                    },
                    () => this.$emit('click-continue-button'),
                    () => this.$emit('click-done-button')
                  );
                },
                err => {
                  Indicator.close();
                  errorHandler.handle(err);
                }
              );
        }
      }
    },

    mounted () {
      window.setTimeout(() => { this.$el.querySelector('input').focus(); }, 300);
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('classroom.createStudent', () => this.$emit('click-back-button'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('classroom.createStudent');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .create-student {
    &__header {
      position: relative;
      height: $header-height;
    }

    &__main {
      margin-top: .3rem;
    }

    &__prompt {
      margin: .1rem .3rem;
      color: $dark-gray;
      font-size: $h5;
      text-align: left;
    }

    &__footer {
      text-align: center;
    }

    &__confirm-button {
      margin-top: .5rem;
      width: 90%;
    }

    &__check-student-list {
      margin-top: .8rem;
      width: 100%;
      color: $blue;
      font-size: $h5;
      text-align: center;
      text-decoration: underline;

      span {
        &:active {
          opacity: .5;
        }
      }
    }

    &__upload-students-tip {
      margin: 10% 5%;
      padding: .5rem .3rem;
      width: 90%;
      background-color: $white;
      border: 2px solid $blue;
      border-radius: .1rem;
      box-sizing: border-box;
      text-align: center;

      &__title {
        font-size: $h4;

        i {
          color: $blue;
        }
      }

      &__content {
        margin-top: .3rem;
        font-size: $h5;
        line-height: .5rem;

        b {
          color: $blue;
        }
      }
    }
  }
</style>
