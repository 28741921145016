import { Toast } from 'mint-ui';
import webviewService from '@/services/webview';

export default {
  showMessage (message) {
    Toast({
      message,
      duration: 1500
    });
  },

  showWarning (message) {
    Toast({
      message,
      iconClass: 'fa fa-exclamation',
      duration: 1500
    });
  },

  showTip (message) {
    Toast({
      message,
      iconClass: 'fa fa-check',
      duration: 1500
    });
  }
};
