<template>
  <div class="set-item-score-modal">
    <div class="set-item-score-modal__content">
      <div class="set-item-score-modal__title">
        <span class="set-item-score-modal__back-button" @click="$emit('close-modal')">
          <back-icon></back-icon>
        </span>
        编辑第{{ item.itemOrdinal }}题分数
      </div>
      <mt-field
        v-model.number="newFullScore"
        :placeholder="$t('TeacherQuiz.placeholder.questionScore')"
        class="set-item-score-modal__full-score"
        type="number"
        disableClear>
      </mt-field>
      <div>
        <mt-button
          class="set-item-score-modal__confirm-button"
          type="primary"
          @click="confirm()">
          {{ $t('TeacherQuiz.label.confirm') }}
        </mt-button>
      </div>
    </div>
    <div class="set-item-score-modal__mask" @click="$emit('close-modal')">
      <mask-layer></mask-layer>
    </div>
  </div>
</template>

<script>
  import BackIcon  from '@/components/BackIcon';
  import MaskLayer from '@/components/MaskLayer';

  import Toast from '@/components/__mint-ui/Toast';
  import webviewService  from '@/services/webview';

  export default {
    components: {
      BackIcon,
      MaskLayer
    },

    props: ['item'],

    data () {
      return {
        newFullScore: this.item.fullScore
      };
    },

    computed: {
      fullScoreHasChanged () {
        return (this.newFullScore !== this.item.fullScore);
      }
    },

    methods: {
      confirm () {
        if (this.newFullScore <= 0)
          Toast.showWarning('试题分数需大于0');
        else if (!this.fullScoreHasChanged)
          this.$emit('close-modal');
        else
          this.$emit('click-confirm-button', this.newFullScore);
      }
    },

    mounted () {
      window.setTimeout(() => { this.$el.querySelector('input').focus(); }, 300);
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('quiz.bubblesheet.setScoreModal', () => this.$emit('close-modal'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('quiz.bubblesheet.setScoreModal');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .set-item-score-modal {
    &__content {
      position: fixed;
      z-index: $highest-z-index + 2;
      top: 4rem;
      left: 2%;
      width: 96%;
      background-color: $white;
      border-radius: .1rem;
    }

    &__title {
      height: 1.2rem;
      background-color: $blue;
      border-radius: .1rem .1rem 0 0;
      color: $white;
      font-size: $h4;
      line-height: 1.2rem;
      text-align: center;
    }

    &__back-button {
      position: absolute;
      top: 0;
      left: .15rem;
    }

    &__full-score {
      width: 100%;
    }

    &__confirm-button {
      margin: .5rem 5%;
      width: 90%;
    }
  }
</style>

