<template>
    <div class="summary-report">
        <div class="summary-stats">
            <div class="score-distribution" ref="score-distribution">
            </div>
            <div class="score-table">
                <div><span>{{report.quiz.quizSummary.fullScore}}</span><label>总分</label></div>
                <div><span>{{averageScore | roundTwo}}</span><label>平均分</label></div>
                <div><span>{{averageScoreRatio | roundTwo}}<small>%</small></span><label>得分率</label></div>
                <div><span>{{topScore | roundTwo}}</span><label>最高分</label></div>
                <div><span>{{report.classrooms.length}}</span><label>参与班级</label></div>
                <div><span>{{aggregateQuizEntries.length}}</span><label>参与学生</label></div>
            </div>
        </div>
        <div class="classroom-comparison">
            <div class="section-header">参与班级</div>
            <classroom-card v-for="classroom in report.classrooms" :key="classroom.classroomId"
                :classroom="classroom" @view-classroom-report="viewClassroomReport(classroom)"></classroom-card>
        </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    import ClassroomCard from './_summary-report/ClassroomCard'

    export default {
        components: {
            //   ListSortTransition,
            //   ModalTransition,
            //   SortPopup,
            //   ItemCard,
            //   ItemModal
            ClassroomCard
        },

        props: ['report'],

        data() {
            return {

            };
        },
        mounted() {

            var myChart = echarts.init(this.$refs['score-distribution']);

            // Draw the chart
            myChart.setOption({
                xAxis: {
                    data: ['0%-19%', '20%-39%', '40%-59%', '60%-79%', '80%-100%']
                },
                tooltip: {
                    show: true
                },
                yAxis: {
                    show: false
                },
                series: [{
                    type: 'bar',
                    data: this.scoreDistribution
                }],
                grid: {
                    left: 10,
                    top: 25,
                    right: 10,
                    bottom: 35
                },
                color: ['#26a2ff']
            });
        },

        filters: {
            roundTwo(n) {
                return Math.round(n * 100) / 100
            }
        },

        computed: {
            aggregateQuizEntries() {
                return [].concat.apply([], this.report.classrooms.map(c => c.quizTakeableEntries.filter(qe => !qe
                    .quizAnswerDeletedAt && !!qe.quizAnswerResult)));
            },
            aggregateScores() {
                return this.aggregateQuizEntries.map(qe => qe.quizAnswerResult.summary.totalScore).sort();
            },
            aggregateScoreRatios() {
                return this.aggregateQuizEntries.map(qe => qe.quizAnswerResult.summary.totalScoreRatio).sort();
            },
            averageScore() {
                return this.report.quiz.quizSummary.averageScore;
            },
            averageScoreRatio() {
                return this.report.summary.averageScoreRatio * 100;
            },
            medianScore() {
                const mid = Math.ceil(this.aggregateScores.length / 2);
                return this.aggregateScores.length % 2 == 0 ? (this.aggregateScores[mid] + this.aggregateScores[mid -
                    1]) / 2 : this.aggregateScores[mid - 1];
            },
            topScore() {
                return Math.max(...this.aggregateScores)
            },
            scoreDistribution() {
                const c1 = this.aggregateScoreRatios.filter(s => s < 0.2).length
                const c2 = this.aggregateScoreRatios.filter(s => s >= 0.2 && s < 0.4).length
                const c3 = this.aggregateScoreRatios.filter(s => s >= 0.4 && s < 0.6).length
                const c4 = this.aggregateScoreRatios.filter(s => s >= 0.6 && s < 0.8).length
                const c5 = this.aggregateScoreRatios.filter(s => s >= 0.8 && s <= 1).length

                return [c1, c2, c3, c4, c5]
            }
        },

        methods: {
            viewClassroomReport(classroom) {
                this.$emit('view-classroom-report',classroom)
            }
        }
    };
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables';
    @import 'src/assets/style/mixins';

    .summary-report {
        .summary-stats {
            // margin: .1rem 0;
            background-color: white;
            border-bottom: 1px solid $gray;

            .score-distribution {
                width: 100%;
                height: 7rem;
            }

            .score-table {
                width: 100%;
                display: flex;
                flex-wrap: wrap;

                &>div {
                    width: 33.33%;
                    height: 2.5rem;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    overflow: hidden;

                    span {
                        font-size: $h2;
                        color: $darker-gray;
                        // color: $blue;
                        margin-bottom: .2rem;
                        font-weight: $bold;
                    }

                    label {
                        font-size: $h5;
                        color: $gray;
                    }
                }
            }

        }


        .classroom-comparison {
            margin: .1rem 0;

            .section-header {
                font-size: $h5;
                padding: 0rem .5rem;
                margin-top: .35rem;
                padding-bottom: .05rem;
            }

        }

    }
</style>