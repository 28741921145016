<template>
  <div class="taker-modal">
    <div class="taker-modal__content">
      <div class="taker-modal__title">
        <span class="taker-modal__back-button" @click="$emit('close-modal')">
          <back-icon></back-icon>
        </span>
        <span>
          {{ quizTaker.quizTakerNumber }} - {{ quizTaker.quizTakerName }}
        </span>
      </div>

      <div class="taker-modal__answer-result">
        <div class="taker-modal__item-card" v-for="item in entry.quizAnswerResult.items">
          <div v-if="item.type === 'subjectiveSkip'">
            <div class="taker-modal__item-summary">
              <span>第{{ item.itemOrdinal }}题</span>
              <span>主观题</span>
            </div>
            <div class="taker-modal__no-choice-prompt">
              主观题无选项
            </div>
          </div>

          <div v-else>
            <div class="taker-modal__item-summary">
              <span>第{{ item.itemOrdinal }}题</span>
              <span>
                正确答案:
                <i v-if="hasCorrectAnswer(item)">
                  {{ getCorrectAnswerLabel(item) }}
                </i>
                <span v-else>
                  未设置
                </span>
              </span>
            </div>
            <div class="taker-modal__choice-list">
              <span
                v-for="(choice, index) in item.answerChoices"
                class="taker-modal__choice-item"
                :class="{ 'is-selected': choice === '1', 'is-correct': item.result === 'correct' }"
                :key="index">
                {{ getBubbleLabel(item.type, index) }}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="taker-modal__operation">
        <div class="taker-modal__remove-button" @click="remove()">
          <i class="fa fa-trash-o"></i>
          删除
        </div>
        <div class="taker-modal__edit-button" @click="editAnswerModalVisible = true">
          <i class="fa fa-pencil-square-o"></i>
          修改
        </div>
        <edit-answer-modal
          v-if="editAnswerModalVisible"
          :entry="entry"
          @close-modal="editAnswerModalVisible = false"
          @click-confirm-button="toEditAnswer">
        </edit-answer-modal>
      </div>
    </div>
    <div class="taker-modal__mask" @click="$emit('close-modal')">
      <mask-layer></mask-layer>
    </div>
  </div>
</template>

<script>
  import BackIcon  from '@/components/BackIcon';
  import MaskLayer from '@/components/MaskLayer';

  import EditAnswerModal from './_taker-modal/EditAnswerModal';

  import MessageBox from '@/components/__mint-ui/MessageBox';
  import webviewService from '@/services/webview';

  export default {
    components: {
      BackIcon,
      MaskLayer,
      EditAnswerModal
    },

    props: ['entry'],

    data () {
      return {
        editAnswerModalVisible: false
      };
    },

    computed: {
      quizTaker () {
        return this.entry.quizTaker;
      }
    },

    methods: {
      remove () {
        const quizTakerName     = this.entry.quizTaker.quizTakerName;
        const quizAnswerEntryId = this.entry.quizAnswerEntryId;

        MessageBox.openInModal(
          {
            title: '提示',
            message: `确定删除${quizTakerName}的成绩吗?`,
            showConfirmButton: true,
            showCancelButton: true
          },
          () => {
            this.$emit('click-remove-button', { quizAnswerEntryId });
          }
        );
      },

      hasCorrectAnswer (item) {
        return (item.choices.indexOf('1') !== -1);
      },
      getCorrectAnswerLabel (item) {
        return item.choices
                .split('')
                .reduce((acc, c, i) => {
                  if (c === '0')
                    return acc;
                  else
                    return acc + this.getBubbleLabel(item.type, i);
                }, '');
      },
      getBubbleLabel (type, index) {
        if (type === 'single' || type === 'multiple') {
          return ('ABCDEFGHIJK')[index];
        }
        else if (type === 'boolean') {
          return ('TF')[index];
        }
      },

      toEditAnswer ({ quizAnswerEntryId, quizAnswerSheet }) {
        this.editAnswerModalVisible = false;
        this.$emit('click-edit-button', { quizAnswerEntryId, quizAnswerSheet });
      }
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('quiz.report.classroomReport.takerModal', () => this.$emit('close-modal'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('quiz.report.classroomReport.takerModal');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';
  @import 'src/assets/style/mixins';

  .taker-modal {
    &__content {
      position: fixed;
      z-index: $highest-z-index + 2;
      top: 10%;
      left: 2%;
      width: 96%;
      bottom: 10%;
      background-color: $white;
      border-radius: .1rem;
    }

    &__title {
      height: 1.2rem;
      background-color: $blue;
      border-radius: .1rem .1rem 0 0;
      color: $white;
      font-size: $h4;
      line-height: 1.2rem;
      text-align: center;
    }

    &__back-button {
      position: absolute;
      top: 0;
      left: .15rem;
    }

    &__answer-result {
      margin: .3rem 0;
      // height: 7rem;
      // max-height: 7rem;
      height: calc(100% - 2.4rem);
      overflow-y: auto;
    }

    &__item-card {
      padding: .3rem;
      border-bottom: 1px solid $light-gray;
      font-size: $h5;
    }

    &__item-summary {
      span {
        display: inline-block;
        min-width: 1.2rem;
      }

      i {
        color: $blue;
      }
    }

    &__no-choice-prompt {
      margin-top: .3rem;
      color: $gray;
    }

    &__choice-list {
      margin-top: .3rem;
    }

    &__choice-item {
      margin-left: .15rem;
      display: inline-block;
      width: .5rem;
      height: .5rem;
      border: 1px solid $black;
      border-radius: 50%;
      box-sizing: border-box;
      line-height: .5rem;
      text-align: center;

      &:first-child {
        margin-left: 0;
      }

      &.is-selected {
        background-color: $red;
        border-color: $red;
        color: $white;
      }

      &.is-selected.is-correct {
        background-color: $blue;
        border-color: $blue;
        color: $white;
      }
    }

    &__operation {
      display: flex;
      width: 100%;
    }

    &__remove-button,
    &__edit-button {
      display: inline-block;
      height: 1.2rem;
      background-color: $blue;
      color: $white;
      flex: 1;
      font-size: $h4;
      line-height: 1.2rem;
      text-align: center;

      &:active {
        @include pressed(0, 0, 0, 0);
      }
    }

    &__remove-button {
      border-right: 1px solid $white;
    }
  }
</style>
