<template>
  <div class="scanning-guide-modal">
    <div class="scanning-guide-modal__content">
      <div class="scanning-guide-modal__title">
        <span
          class="scanning-guide-modal__back-button"
          @click="$emit('close-modal')"
        >
          <close-icon></close-icon>
        </span>
      </div>
      <mt-swipe :auto="5000" class="scanning-guide-modal__swipe-container">
        <mt-swipe-item class="scanning-guide-modal__swipe-item">
          <div class="scanning-guide-modal__swipe-item-title">正确扫描方式</div>
          <div class="scanning-guide-modal__swipe-item-content">
            <img
              src="@/assets/images/scanning-example.gif"
              style="height: 90%"
            />
          </div>
        </mt-swipe-item>
        <mt-swipe-item class="scanning-guide-modal__swipe-item">
          <div class="scanning-guide-modal__swipe-item-title">手机保持竖屏扫描</div>
          <div class="scanning-guide-modal__swipe-item-content">
            <img
              src="@/assets/images/phone-horizontal-example.png"
              style="max-width: 95%;max-height: 70%"
            />
            <div style="margin-top: 1rem">
                答题卡和手机方向一定要一致！
            </div>
          </div>
        </mt-swipe-item>
        <mt-swipe-item class="scanning-guide-modal__swipe-item">
          <div class="scanning-guide-modal__swipe-item-title">切换摄像头</div>
          <div class="scanning-guide-modal__swipe-item-content">
            <img
              src="@/assets/images/switch-camera-example.png"
              style="max-width: 95%;max-height: 35%"
            />
            <div style="margin-top:.75rem;margin-bottom:.75rem;width: 95%;text-align:center">
                <p style="margin-bottom:.4rem">如果出现摄像头模糊、焦距过大等问题，<br>点击 <b style="color:#26a2ff">切换摄像头</b> 。</p>
                <p style="margin-bottom:.5rem">选择扫描效果最佳的摄像头。</p>
                <p>如果摄像头无法启动，请试用 <b style="color:#26a2ff">兼容模式</b>。</p>
            </div>
            <!-- <img
              src="@/assets/images/compatibility-mode.png"
              style="max-width: 95%;max-height: 10%"
            /> -->
          </div>
        </mt-swipe-item>
      </mt-swipe>
    </div>
    <div class="scanning-guide-modal__mask" @click="$emit('close-modal')">
      <mask-layer></mask-layer>
    </div>
  </div>
</template>

<script>
import CloseIcon from "@/components/CloseIcon";
import MaskLayer from "@/components/MaskLayer";
import webviewService from "@/services/webview";

export default {
  components: {
    CloseIcon,
    MaskLayer,
  },

  props: ["sortKey"],

  beforeCreate() {
    webviewService.pushBackButtonCallback(
      "quiz.report.classroomReport.sortPopup",
      () => this.$emit("click-mask")
    );
  },
  beforeDestroy() {
    webviewService.popBackButtonCallback(
      "quiz.report.classroomReport.sortPopup"
    );
  },
};
</script>

<style lang="scss">
@import "src/assets/style/variables";

.scanning-guide-modal {
  letter-spacing: 0;

  &__content {
    position: fixed;
    z-index: $highest-z-index + 2;
    top: 10%;
    bottom: 10%;
    left: 2%;
    width: 96%;
    background-color: $white;
    border-radius: 0.1rem;
  }

  &__title {
    position: absolute;
    height: 1.2rem;
    width: 100%;
    // background-color: $blue;
    // color: $white;
    border-radius: 0.1rem 0.1rem 0 0;
    font-size: $h4;
    line-height: 1.2rem;
    text-align: center;
    z-index: $highest-z-index;
  }

  &__back-button {
    position: absolute;
    top: 0;
    left: 0.25rem;
  }

  &__swipe-container {
    position: relative;
    height: 100%;
    max-height: 100%;
    overflow: hidden;

    .mint-swipe-indicator.is-active {
      background: $blue;
    }
  }

  &__swipe-item {
    height: calc(100% - 1.2rem);
    max-height: calc(100% - 1.2rem);
    overflow: hidden;
  }

  &__swipe-item-title {
    height: 1.2rem;
    font-size: $h4;
    line-height: 1.2rem;
    text-align: center;
    font-weight: bold;
  }

  &__swipe-item-content {
      padding: 0 .3rem;
      font-size: $h4;
    height: calc(100% - .8rem);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
