<template>
  <div
    class="notification-card"
    :class="{ 'is-first': isFirst }"
    @click="$router.push({ name: 'notification', params: { notificationId: notification.notificationId } })">
    <div class="notification-card__title">
      <div class="text">{{ notification.notificationTitle }}</div>
      <div class="date">{{ createdDate }}</div>
    </div>
    <div class="notification-card__content" v-html="notification.notificationContent"></div>
    <div class="notification-card__actions">
      <a>查看全文</a>
    </div>
  </div>
</template>

<script>
  import formatDate from '@/services/format-date';

  export default {
    props: ['notification', 'isFirst'],

    computed: {
      createdDate () {
        return formatDate(this.notification.createdAt * 1000);
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';
  @import 'src/assets/style/mixins';

  .notification-card {
    // @include pressed(0, 0, 0, -.35rem);

    position: relative;
    // padding: .35rem 0 0 0;
    margin: .35rem .25rem;
    // padding: .1rem;
    background-color: $white;
    padding: .35rem;
    height: 2.8rem;
    // border-top: 1px solid $light-gray;
    border-radius: 2%;

    &.is-first {
      border-top: none;
    }

    &__title {
      display: flex;
      width: 100%;
      position:relative;

      .text {
        @include truncate(60%);
        font-size: $h4;
        text-align: left;
      }
      
      .date {
        @include truncate(40%);
        text-align: right;
        color: $dark-gray;
        font-size: $h5;
      }
      
    }

    &__content {
      margin-top: .35rem;
      font-size: $h5;
      height: 1.25rem;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $dark-gray;
      line-height: $h5 + .15rem;
    }

    &__actions {
      margin-top: .35rem;
      font-size: $h5;
      color: $blue
    }



    &__arrow {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      width: 1rem;
      height: 1.6rem;

      &:after {
        position: absolute;
        top: .8rem;
        right: .5rem;
        width: .15rem;
        height: .15rem;
        border: solid 2px $blue;
        border-top-width: 0;
        border-left-width: 0;
        content: '';
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  }
</style>
