<template>
  <div class="notification-list">
    <div class="notification-list__header">
      <mt-header fixed title="通知">
        <router-link to="/teacher/profile" slot="left">
          <mt-button icon="back"></mt-button>
        </router-link>
      </mt-header>
    </div>
    <div class="notification-list__main">
      <div class="notification-list__empty" v-if="!notifications||notifications.length < 1">暂无通知</div>

      <notification-card v-for="(notification, index) in notifications" :key="index" :is-first="index === 0"
        :notification="notification">
      </notification-card>

    </div>
  </div>
</template>

<script>
  import NotificationCard from "./_notification-list/NotificationCard";

  import checkAccess from "@/services/check-access";
  import webviewService from "@/services/webview";

  export default {
    components: {
      NotificationCard,
    },

    data() {
      return {
        notifications: [
          // {
          //   notificationId: "123456789",
          //   notificationTitle: "Thank you",
          //   notificationContent: "<p>Lorem Ipsum，也称乱数假文或者哑元文本， 是印刷及排版领域所常用的虚拟文字。</p><p>由于曾经一台匿名的打印机刻意打乱了一盒印刷字体从而造出一本字体样品书，Lorem Ipsum从西元15世纪起就被作为此领域的标准文本使用。它不仅延续了五个世纪，还通过了电子排版的挑战，其雏形却依然保存至今。</p><p>在1960年代，&rdquo;Leatraset&rdquo;公司发布了印刷着Lorem Ipsum段落的纸张，从而广泛普及了它的使用。最近，计算机桌面出版软件&rdquo;Aldus PageMaker&rdquo;也通过同样的方式使Lorem Ipsum落入大众的视野。</p>",
          //   notificationType: "announcement",
          //   notificationTargets: [],
          //   promptUser: true,
          //   promptImage: "",
          //   notificationActions: [],
          //   createdAt: 1604285594.925833,
          // },
          // {
          //   notificationId: "234567890",
          //   notificationTitle: "服务通知",
          //   notificationContent: "<p>Lorem Ipsum，也称乱数假文或者哑元文本， 是印刷及排版领域所常用的虚拟文字。由于曾经一台匿名的打印机刻意打乱了一盒印刷字体从而造出一本字体样品书，Lorem Ipsum从西元15世纪起就被作为此领域的标准文本使用。它不仅延续了五个世纪，还通过了电子排版的挑战，其雏形却依然保存至今。在1960年代，&rdquo;Leatraset&rdquo;公司发布了印刷着Lorem Ipsum段落的纸张，从而广泛普及了它的使用。最近，计算机桌面出版软件&rdquo;Aldus PageMaker&rdquo;也通过同样的方式使Lorem Ipsum落入大众的视野。</p>",
          //   notificationType: "announcement",
          //   notificationTargets: [],
          //   promptUser: true,
          //   promptImage: "",
          //   notificationActions: [],
          //   createdAt: 1604285594.925833,
          // }
        ],
      };
    },

    beforeCreate() {
      checkAccess("loggedIn");
      webviewService.pushBackButtonCallback("notificationList", () =>
        this.$router.push({
          name: "teacherProfile"
        })
      );
    },
    beforeDestroy() {
      webviewService.popBackButtonCallback("notificationList");
    },
  };
</script>

<style lang="scss" scoped>
  @import "src/assets/style/variables";

  .notification-list {
    &__header {
      position: relative;
      height: $header-height;
    }

    &__main {
      // padding-left: 0.3rem;
      // background-color: $white;
      // border-bottom: 1px solid $light-gray;

      // div {
      //   padding: 1rem 0;
      //   width: 100%;
      //   background-color: $white;
      //   font-size: $h4;
      //   text-align: center;
      // }
    }

    &__empty {
      font-size: $h4;
      text-align: center;
      position: relative;
      margin: .35rem .25rem;
      background-color: $white;
      padding: .35rem;
      height: 2.8rem;
      border-radius: 2%;
      display: flex;
      justify-content: center;
      align-content: center;
      align-items: center;
      color: grey;
    }
  }
</style>