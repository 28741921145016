<template>
    <div class="unsupported__wechat">
        <div class="message-container">
            <p>抱歉，目前不支持微信浏览器</p>
            <p>请点击右上方按钮，选择<b>在浏览器中打开</b></p>
            <p>苹果手机请使用Safari浏览器</p>
        </div>
        <img class="arrow" src="@/assets/images/icons/up-arrow.svg"/>
    </div>
</template>

<script>
    export default {


    };
</script>
<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .unsupported__wechat {
      position: relative;
      height: 60vh;
      width: 100%;
      color: $white;
      background-color: $blue;

      .message-container {
          font-size: $h4;
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          left: 0;
          display: flex;
          flex-direction: column;
          padding: 1rem;
          justify-content: center;
          align-items: center;

          p {
              margin-bottom: 0.2rem;
          }

      }

      .arrow {
          position: fixed;
          width: 2rem;
          top: .3rem;
          right: -.3rem;
          color: $white;
      }
  
  }




</style>