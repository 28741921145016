<template>
  <div>
    <div class="header">
      <mt-header fixed title="意见反馈">
        <router-link to="/teacher/profile" slot="left">
          <mt-button icon="back"></mt-button>
        </router-link>
      </mt-header>
    </div>

    <iframe :src="urlWithUserState" frameborder="0"></iframe>
  </div>
</template>

<script>
import webviewService from '@/services/webview'

export default {
  computed: {
    account() {
      return this.$store.state.session.account
    },
    urlWithUserState() {
      return (
        `https://support.qq.com/product/59426?openid=${
          this.account.userId
        }&nickname=${this.account.fullname}&avatar=` +
        'https://tucao.qq.com/static/desktop/img/products/def-product-logo.png'
      )
    }
  },
  data() {
    return {}
  },

  methods: {},
  created() {},
  beforeCreate() {
    webviewService.pushBackButtonCallback('feedback', () =>
      this.$router.push({ name: 'teacherProfile' })
    )
  },
  beforeDestroy() {
    webviewService.popBackButtonCallback('feedback')
  }
}
</script>

<style lang="scss" scoped>

.header {
  position: relative;
  height: 1.2rem;
}
  
iframe {
  height: calc(100vh - 1.2rem);
  width: 100%;
}
</style>
