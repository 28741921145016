<template>
  <div class="operations-popup">
    <div class="operations-popup__content">
      <div
        class="operations-popup__remove-button"
        @click.stop="$emit('click-remove-button')">
        删除该班级
      </div>
    </div>
    <div class="operations-popup__mask" @touchstart.prevent="$emit('touch-mask')" @click.prevent="$emit('touch-mask')">
      <mask-layer></mask-layer>
    </div>
  </div>
</template>

<script>
  import MaskLayer from '@/components/MaskLayer';
  import webviewService from '@/services/webview';

  export default {
    components: {
      MaskLayer
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('classroomList.classroomCard.operationsPopup', () => this.$emit('touch-mask'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('classroomList.classroomCard.operationsPopup');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .operations-popup {
    &__content {
      position: fixed;
      z-index: $highest-z-index + 2;
      top: 40%;
      left: 10%;
      width: 80%;
      height: 1.3rem;
      border-radius: .05rem;
      background: $white;
    }

    &__remove-button {
      padding-left: .5rem;
      height: 1.3rem;
      font-size: $h4;
      line-height: 1.3rem;
      color: $red;
    }

    &__duplicat-button {
      border-bottom: 1px solid $light-gray;
    }
  }
</style>
