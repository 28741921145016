<template>
  <div class="edit-classroom">
    <div class="edit-classroom__header">
      <mt-header fixed :title="$t('TeacherClassroomList.title.editClassroom')">
        <router-link to="" slot="left">
          <mt-button icon="back" @click="$emit('click-back-button')"></mt-button>
        </router-link>
      </mt-header>
    </div>
    <div class="edit-classroom__main">
      <div class="edit-classroom__prompt">
        {{ $t('TeacherClassroomList.classroomNamePrompt') }}
      </div>
      <mt-field
        v-model.trim="classroomName"
        class="edit-classroom__classroom-name"
        :placeholder="$t('TeacherClassroomList.placeholder.classroomName')"
        type="text"
        disableClear>
      </mt-field>
    </div>
    <div class="edit-classroom__footer">
      <mt-button
        class="edit-classroom__done-button"
        type="primary"
        @click="done()">
        确认
      </mt-button>
    </div>

    <div class="edit-classroom__remove-classroom-prompt">
      <span @click="removeClassroom()">删除班级</span>
    </div>
  </div>
</template>

<script>
  import Toast      from '@/components/__mint-ui/Toast';
  import Indicator  from '@/components/__mint-ui/Indicator';
  import MessageBox from '@/components/__mint-ui/MessageBox';

  import urls from '@/api/teacher-urls';
  import webviewService from '@/services/webview';

  export default {
    props: ['classroom'],

    data () {
      return {
        classroomId  : this.classroom.classroomId,
        classroomName: this.classroom.classroomName
      };
    },

    computed: {
      classroomNameHasChanged () {
        return (this.classroomName !== this.classroom.classroomName);
      }
    },

    methods: {
      __editClassroom ({ classroomId, classroomName }) {
        const url = urls['classroom']({ classroomId });
        return this.$http.put(url, { classroomId, classroomName });
      },
      __removeClassroom ({ classroomId }) {
        const url = urls['classroom']({ classroomId });
        return this.$http.delete(url);
      },

      done () {
        if (!this.classroomName) {
          Toast.showWarning('请填写班级名称');
        }
        else if (!this.classroomNameHasChanged) {
          this.$emit('click-back-button')
        }
        else {
          Indicator.open();
          this.__editClassroom({ classroomId: this.classroomId, classroomName: this.classroomName })
              .then(
                rep => {
                  Indicator.close();
                  this.$emit('click-done-button')
                },
                err => {
                  Indicator.close();
                }
              );
        }
      },

      removeClassroom () {
        MessageBox.open(
          {
            message: `确认删除${this.classroom.classroomName}吗?`,
            showConfirmButton: true,
            showCancelButton: true
          },
          () => {
            this.__removeClassroom({ classroomId: this.classroom.classroomId })
                .then(rep => {
                  this.$router.push({ name: 'teacherClassroomList' });
                  Toast.showTip('删除成功');
                });
          }
        );
      }
    },

    mounted () {
      window.setTimeout(() => { this.$el.querySelector('input').focus(); }, 300);
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('classroom.editClassroom', () => this.$emit('click-back-button'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('classroom.editClassroom');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .edit-classroom {
    &__header {
      position: relative;
      height: $header-height;
    }

    &__main {
      margin-top: .3rem;
    }

    &__prompt {
      margin: .1rem .3rem;
      color: $dark-gray;
      font-size: $h5;
      text-align: left;
    }

    &__footer {
      text-align: center;
    }

    &__done-button {
      margin-top: .5rem;
      width: 9rem;
    }

    &__remove-classroom-prompt {
      margin-top: .8rem;
      width: 100%;
      color: $red;
      font-size: $h5;
      text-align: center;
      text-decoration: underline;

      span {
        &:active {
          opacity: .5;
        }
      }
    }
  }
</style>
