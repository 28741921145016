<template>
  <div class="select-school">
    <search-school
      @click-back-button="$emit('click-back-button')"
      @click-school-item="selectSchool">
    </search-school>
  </div>
</template>

<script>
  import SearchSchool from '@/components/teacher/SearchSchool';
  import webviewService from '@/services/webview';

  export default {
    components: {
      SearchSchool
    },

    methods: {
      selectSchool (school) {
        this.$emit('click-school-item', school);
      }
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('enter.register.selectSchool', () => this.$emit('click-back-button'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('enter.register.selectSchool');
    }
  };
</script>

<style lang="scss" scoped>

</style>
