<template>
  <div class="result-bar">
    <div class="result-bar__successful-mark">
      <span>扫描成功</span>
    </div>
    <div class="result-bar__close-button" @click="$emit('click-close-button')">
      <i class="fa fa-times result-bar__close-button"></i>
    </div>
    <div style="display: flex">
      <div class="result-bar__score">
        <i class="result-bar__score-value">{{ result.totalScore }}</i>
        <span>分</span>
      </div>
    </div>
    <div style="display: flex">
      <div class="result-bar__student-name">
        <i class="fa fa-user-o"></i>
        <span>姓名:</span>
        <span>{{ result.studentName }}</span>
      </div>
      <div class="result-bar__student-quiz-number">
        <i class="fa fa-address-card-o"></i>
        <span>学号:</span>
        <span>{{ result.studentQuizNumber }}</span>
      </div>
      <div class="result-bar__score-rate">
        <span>得分率:</span>
        <i class="result-bar__score-rate-value">{{ formatRatio(result.totalScoreRatio) }}%</i>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['result'],

    methods: {
      formatRatio (ratio) {
        return Number((ratio * 100).toFixed(0));
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .result-bar {
    position: relative;
    padding: 0 .3rem;
    width: 100%;
    height: 3rem;
    background-color: $white;
    box-sizing: border-box;
    font-size: $h4;
    opacity: .8;

    &__successful-mark {
      position: absolute;
      z-index: $high-z-index;
      top: 0;
      left: 0;
      width: 1.6rem;
      height: 1.6rem;
      background-image: linear-gradient(135deg, $blue, $blue 50%, transparent 0);
      font-size: $h5;

      span {
        position: absolute;
        top: .1rem;
        left: .15rem;
        display: block;
        width: .8rem;
        color: $white;
        font-weight: $bold;
        transform: rotate(-45deg);
      }
    }

    &__close-button {
      position: absolute;
      z-index: $high-z-index;
      top: 0;
      right: 0;
      padding: .1rem .3rem 0 0;
      width: 1rem;
      height: 1rem;
      font-size: $h3;
      text-align: right;
    }

    &__score {
      height: 1.5rem;
      flex: 1;
      line-height: 1.5rem;
      text-align: center;
    }

    &__score-value {
      color: $blue;
      font-size: $h1;
    }

    &__student-name,
    &__student-quiz-number,
    &__score-rate {
      display: inline-block;
      width: 50%;
      height: 1.5rem;
      flex: 1;
      line-height: 1.5rem;
      text-align: center;
    }
  }
</style>
