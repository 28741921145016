import eruda from 'eruda'

import {
    Plugins
} from '@capacitor/core';
const {
    Storage
} = Plugins;

let debugState = {
    
    state: {
        debug: false
    },

    getters: {
        debug: state => state.debug
    },

    mutations: {

        TOGGLE_DEBUG(state) {

            state.debug = !state.debug, state.debug ? eruda.init() : eruda.destroy();

            Storage.set({
                key: 'debug',
                value: JSON.stringify(state.debug)
            });
        }
    },
}

let initDebug = false;

Storage.get({
    key: 'debug'
}).then(get_debug => {

    initDebug = JSON.parse(get_debug?.value)

    if (typeof initDebug !== 'boolean') initDebug = false;

    if (initDebug) debugState.state.debug = true, eruda.init();
});

export default debugState;