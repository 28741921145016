<template>
  <div class="classroom">
    <div v-if="classroomVisible">
      <div v-if="classroom === null">
        <div class="classroom__header">
          <mt-header fixed :title="$route.params.classroomName">
            <router-link to="/teacher/classroom-list" slot="left">
              <mt-button icon="back"></mt-button>
            </router-link>
          </mt-header>
        </div>
        <div class="classroom__main">
          <div class="classroom__retry" v-show="retryVisible" @click="retry__fetchClassroom()">
            班级获取失败，点击重试
          </div>
        </div>
      </div>

      <div v-else>
        <div class="classroom__header">
          <mt-header fixed :title="classroom.classroomName">
            <router-link to="/teacher/classroom-list" slot="left">
              <mt-button icon="back"></mt-button>
            </router-link>
          </mt-header>
          <span
            class="classroom__more-button"
            @click="dropdownMenuVisible = true">
            <i class="fa fa-bars"></i>
          </span>
          <dropdown-menu
            @click-create-student-button="toCreateStudent"
            @click-remove-students-button="toRemoveStudents"
            @click-edit-classroom-button="toEditClassroom"
            @click-mask="dropdownMenuVisible = false"
            :classroom="classroom"
            v-if="dropdownMenuVisible">
          </dropdown-menu>
        </div>
        <div class="classroom__main">
          <div v-if="classroom.students.length > 0">
            <div class="classroom__student-list-header">
              <span class="classroom__student-list-header__student-name">
                {{ $t('TeacherClassroom.label.studentName') }}
              </span>
              <span class="classroom__student-list-header__student-quiz-number">
                {{ $t('TeacherClassroom.label.studentQuizNumber') }}
              </span>
            </div>
            <div class="classroom__student-list">
              <student-card
                v-for="(student, index) in classroom.students"
                @click="toEditStudent(student)"
                :key="student.studentId"
                :is-first="index === 0"
                :student="student">
              </student-card>
              <div class="classroom__student-list__count">
                {{ classroom.students.length }}个学生
              </div>
            </div>
          </div>

          <div class="classroom__create-student" v-else>
            <div class="classroom__create-student-prompt">
              {{ $t('TeacherClassroom.createStudentPrompt') }}
            </div>
            <mt-button
              class="classroom__create-student-button"
              type="primary"
              @click="createStudentVisible = true">
              {{ $t('TeacherClassroom.label.createStudent') }}
            </mt-button>
          </div>
        </div>

        <div
          class="classroom__upload-students-tip"
          v-show="classroom.students.length === 0">
          <div class="classroom__upload-students-tip__title">
            <i class="fa fa-cloud-upload"></i>
            <span>您可以批量上传学生!</span>
          </div>
          <div class="classroom__upload-students-tip__content">
            在电脑浏览器中输入网址 <b>http://mkyice.cn</b>, 并登录账号,即可使用excel批量上传学生名单。
          </div>
        </div>
      </div>
    </div>

    <slide-transition>
      <edit-classroom
        @click-back-button="editClassroomVisible = false"
        @click-done-button="editClassroomDone"
        :classroom="classroom"
        v-if="editClassroomVisible">
      </edit-classroom>
    </slide-transition>

    <slide-transition>
      <create-student
        @click-back-button="createStudentVisible = false"
        @click-continue-button="createStudentContinue"
        @click-done-button="createStudentDone"
        :classroom="classroom"
        v-if="createStudentVisible">
      </create-student>
    </slide-transition>

    <slide-transition>
      <edit-student
        @click-back-button="editStudentVisible = false"
        @click-confirm-button="editStudentDone"
        @click-remove-button="removeStudentDone"
        :classroom="classroom"
        :student="studentForEdit"
        v-if="editStudentVisible">
      </edit-student>
    </slide-transition>

    <remove-students
      @click-back-button="removeStudentsVisible = false"
      @click-remove-button="removeStudentsDone"
      :classroom="classroom"
      v-if="removeStudentsVisible">
    </remove-students>
  </div>
</template>

<script>
  import SlideTransition from '@/components/SlideTransition';

  import StudentCard    from './_classroom/StudentCard';
  import DropdownMenu   from './_classroom/DropdownMenu';
  import CreateStudent  from './_classroom/CreateStudent';
  import EditStudent    from './_classroom/EditStudent';
  import RemoveStudents from './_classroom/RemoveStudents';
  import EditClassroom  from './_classroom/EditClassroom';

  import Indicator from '@/components/__mint-ui/Indicator';
  import Toast     from '@/components/__mint-ui/Toast';

  import urls from '@/api/teacher-urls';

  import checkAccess    from '@/services/check-access';
  import webviewService from '@/services/webview';

  import { findWhere, without } from 'underscore';

  export default {
    components: {
      SlideTransition,
      StudentCard,
      DropdownMenu,
      CreateStudent,
      EditStudent,
      RemoveStudents,
      EditClassroom
    },

    data () {
      return {
        classroom: null,
        studentForEdit: null,

        editClassroomVisible: false,
        dropdownMenuVisible: false,
        createStudentVisible: false,
        editStudentVisible: false,
        removeStudentsVisible: false,

        retryVisible: false
      };
    },

    computed: {
      classroomVisible () {
        return !this.editClassroomVisible
            && !this.createStudentVisible
            && !this.editStudentVisible
            && !this.removeStudentsVisible;
      },

      networkStatus () {
        return this.$store.state.networkStatus;
      }
    },

    watch: {
      networkStatus (val, oldVal) {
        if (val === 'online' && oldVal === 'offline')
          if (this.retryVisible) this.retry__fetchClassroom();
      }
    },

    methods: {
      __fetchClassroom ({ classroomId }) {
        const url = urls['classroom']({ classroomId });
        return this.$http.get(url);
      },

      fetchClassroom () {
        Indicator.open(this.$i18n.t('__Common.loading'));

        this.__fetchClassroom({ classroomId: this.$route.params.classroomId })
            .then(
              rep => {
                this.classroom = rep.data;
                Indicator.close();
              },
              err => {
                this.retryVisible = true;
                Indicator.close();
              }
            );
      },

      retry__fetchClassroom () {
        this.retryVisible = false;
        this.fetchClassroom();
      },

      toEditClassroom () {
        this.editClassroomVisible = true;
        this.dropdownMenuVisible = false;
      },
      editClassroomDone () {
        this.__fetchClassroom({ classroomId: this.$route.params.classroomId })
            .then(rep => {
              this.classroom = rep.data;
              this.editClassroomVisible = false;
              Toast.showTip('编辑完成');
            });
      },

      toCreateStudent () {
        this.createStudentVisible = true;
        this.dropdownMenuVisible = false;
      },
      createStudentContinue () {
        this.__fetchClassroom({ classroomId: this.$route.params.classroomId })
            .then(rep => this.classroom = rep.data);
      },
      createStudentDone () {
        this.__fetchClassroom({ classroomId: this.$route.params.classroomId })
            .then(rep => {
              this.classroom = rep.data;
              this.createStudentVisible = false;
              Toast.showTip('创建完成');
            });
      },

      toEditStudent (student) {
        this.studentForEdit = student;
        this.editStudentVisible = true;
      },
      editStudentDone () {
        this.__fetchClassroom({ classroomId: this.$route.params.classroomId })
            .then(rep => {
              this.classroom = rep.data;
              this.studentForEdit = null;
              this.editStudentVisible = false;
              Toast.showTip('编辑成功');
            });
      },
      removeStudentDone () {
        this.__fetchClassroom({ classroomId: this.$route.params.classroomId })
            .then(rep => {
              this.classroom = rep.data;
              this.studentForEdit = null;
              this.editStudentVisible = false;
              Toast.showTip('删除成功');
            });
      },

      toRemoveStudents () {
        this.removeStudentsVisible = true;
        this.dropdownMenuVisible = false;
      },
      removeStudentsDone () {
        this.__fetchClassroom({ classroomId: this.$route.params.classroomId })
            .then(rep => {
              this.classroom = rep.data;
              this.removeStudentsVisible = false;
              Toast.showTip('删除成功');
            });
      }
    },

    created () {
      this.fetchClassroom();
    },

    beforeCreate () {
      checkAccess('loggedIn');
      webviewService.pushBackButtonCallback('classroom', () => this.$router.push({ name: 'teacherClassroomList' }));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('classroom');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';
  @import 'src/assets/style/mixins';

  .classroom {
    &__header {
      position: relative;
      height: $header-height;
    }

    &__more-button {
      @include pressed(0, 0, 0, -.4rem);

      position: fixed;
      z-index: $highest-z-index;
      top: 0;
      right: 0;
      display: inline-block;
      width: .8rem;
      height: $header-height;
      color: $white;
      font-size: $h3;
      line-height: $header-height;
    }

    &__main {
      background-color: $white;
      border-bottom: 1px solid $light-gray;
    }

    &__retry {
      height: 2rem;
      font-size: $h5;
      line-height: 2rem;
      text-align: center;
    }

    &__student-list {
      margin-top: .8rem;

      &__count {
        padding: .6rem 0;
        background-color: $light-gray;
        color: $dark-gray;
        font-size: $h4;
        text-align: center;
      }
    }

    &__student-list-header {
      position: fixed;
      z-index: $high-z-index;
      top: $header-height;
      padding-left: .3rem;
      width: 10rem;
      height: .8rem;
      background-color: $light-gray;
      font-size: $h5;
      line-height: .8rem;

      &__student-name {
        display: inline-block;
        width: 6rem;
      }

      &__student-quiz-number {
        display: inline-block;
        width: 3rem;
      }
    }

    &__create-student {
      text-align: center;
    }

    &__create-student-prompt {
      padding: 1rem 0;
      font-size: $h4;
    }

    &__create-student-button {
      margin-bottom: 1rem;
      width: 90%;
    }

    &__upload-students-tip {
      margin: 10% 5%;
      padding: .5rem .3rem;
      width: 90%;
      background-color: $white;
      border: 2px solid $blue;
      border-radius: .1rem;
      box-sizing: border-box;
      text-align: center;

      &__title {
        font-size: $h4;

        i {
          color: $blue;
        }
      }

      &__content {
        margin-top: .3rem;
        font-size: $h5;
        line-height: .5rem;

        b {
          color: $blue;
        }
      }
    }
  }
</style>
