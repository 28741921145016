<template>
  <div class="notification">
    <div class="notification__header">
      <mt-header fixed title="通知详情">
        <router-link to="/notification-list" slot="left">
          <mt-button icon="back"></mt-button>
        </router-link>
      </mt-header>
    </div>
    <div class="notification__main">
      <div class="notification__title">
        {{ notification.notificationTitle }}
      </div>
      <div class="notification__date">
        发布时间: {{ createdDate }}
      </div>
      <p class="notification__content">
        {{ notification.notificationContent }}
      </p>
    </div>
  </div>
</template>

<script>
  import checkAccess    from '@/services/check-access';
  import webviewService from '@/services/webview';
  import formatDate     from '@/services/format-date';

  import { findWhere } from 'underscore';

  // import { notifications } from '@/services/mock-data'; // Remove after set up api.

  export default {
    data () {
      return {
        // notification: findWhere(notifications, { id: this.$route.params.notificationId })
      };
    },

    computed: {
      createdDate () {
        return formatDate(this.notification.createdAt);
      }
    },

    beforeCreate () {
      checkAccess('loggedIn');
      webviewService.pushBackButtonCallback('notification', () => this.$router.push({ name: 'notificationList' }));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('notification');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .notification {
    &__header {
      position: relative;
      height: 1.2rem;
    }

    &__main {
      padding-top: .5rem;
      background-color: $white;
      border-bottom: 1px solid $light-gray;
      min-height: 10rem;
      text-align: center;
    }

    &__title {
      font-size: $h4;
    }

    &__date {
      margin-top: .3rem;
      color: $dark-gray;
      font-size: $h5;
    }

    &__content {
      margin-top: .3rem;
      padding: .3rem;
      border-top: 1px solid $light-gray;
      font-size: $h5;
      font-weight: $bold;
      line-height: .6rem;
      text-align: left;
      text-indent: .6rem;
    }
  }
</style>
