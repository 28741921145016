<template>
  <div class="edit-fullname">
    <div class="edit-fullname__header">
      <mt-header fixed :title="$t('TeacherProfile.title.editFullname')">
        <router-link to="/teacher/profile/edit" slot="left">
          <mt-button icon="back" @click="$emit('click-back-button')"></mt-button>
        </router-link>
      </mt-header>
    </div>
    <div class="edit-fullname__main">
      <mt-field
        v-model.trim="fullname"
        :placeholder="$t('TeacherProfile.placeholder.fullname')"
        class="edit-fullname__fullname"
        type="text"
        disableClear>
      </mt-field>
      <mt-button
        class="edit-fullname__confirm-button"
        type="primary"
        @click="confirm()">
        {{ $t('TeacherProfile.label.confirm') }}
      </mt-button>
    </div>
  </div>
</template>

<script>
  import Toast     from '@/components/__mint-ui/Toast';
  import Indicator from '@/components/__mint-ui/Indicator';

  import webviewService from '@/services/webview';
  import urls from '@/api/teacher-urls';

  export default {
    data () {
      let account = this.$store.state.session.account;
      return {
        fullname: account.fullname
      };
    },

    computed: {
      fullnameHasChanged () {
        let account = this.$store.state.session.account;
        return (this.fullname !== account.fullname);
      }
    },

    methods: {
      __editFullname ({ fullname }) {
        const url = urls['editFullname']();
        return this.$http.put(url, { fullname });
      },

      confirm () {
        if (!this.fullname) {
          Toast.showWarning(this.$i18n.t('TeacherProfile.warning.invliadFullname'));
        }
        else if (!this.fullnameHasChanged) {
          this.$emit('click-back-button');
        }
        else {
          Indicator.open();
          this.__editFullname({ fullname: this.fullname })
              .then(
                rep => {
                  Indicator.close();
                  Toast.showTip('编辑成功');

                  this.$store.commit('UPDATE_SESSION_ACCOUNT', { key: 'fullname', newVal: this.fullname });
                  this.$emit('click-confirm-button');
                },
                err => {
                  Indicator.close();
                }
              );
        }
      }
    },

    mounted () {
      window.setTimeout(() => { this.$el.querySelector('input').focus(); }, 300);
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('profile.editAccount.editFullname', () => this.$emit('click-back-button'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('profile.editAccount.editFullname');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .edit-fullname {
    &__header {
      height: $header-height;
    }

    &__main {
      margin-top: .5rem;
      text-align: center;
    }

    &__confirm-button {
      margin-top: .5rem;
      width: 90%;
    }
  }
</style>
