<template>
  <div class="top-refresh" v-if="visible">
    <transition name="fade">
      <div class="top-refresh__icon" v-if="iconVisible">
        <i class="fa fa-refresh fa-spin fa-fw"></i>
      </div>
    </transition>
  </div>
</template>

<script>
  import webviewService from '@/services/webview';

  export default {
    data () {
      return {
        // Hide top-refresh component on android4+,
        // because `touchend` event doesn't work properly in android4+ webview.
        visible: webviewService.checkIsAndroid4() ? false : true,
        iconVisible: false
      };
    },

    created () {
      let startClientY, currentClientY;

      window.addEventListener('touchstart', e => {
        if (window.pageYOffset === 0)
          startClientY = e.touches[0].clientY;
      }, false);

      window.addEventListener('touchmove', e => {
        if (window.pageYOffset === 0) {
          currentClientY = e.touches[0].clientY;

          if (currentClientY - startClientY > 0) this.iconVisible = true;
        }
      }, false);

      window.addEventListener('touchend', e => {
        if (this.iconVisible) {
          window.setTimeout(() => {
            this.iconVisible = false;

            if (window.pageYOffset <= 0)
              if (currentClientY - startClientY > 30) this.$emit('refresh');
          }, 500);
        }
      }, false);
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .top-refresh {
    &__icon {
      position: fixed;
      z-index: 1003;
      top: 2rem;
      left: 0;
      width: 100%;
      color: $blue;
      font-size: $h3 + .1;
      text-align: center;
    }

    .fade-enter-active,
    .fade-leave-active {
      transition: all .5s ease;
    }

    .fade-enter,
    .fade-leave-to {
      transform: translateY(-2rem);
      opacity: 0;
    }
  }
</style>

