<template>
  <div class="classroom-card" :class="{ 'is-first': isFirst, 'is-selected': classroom.selected }">
    <div v-if="studentsCount > 0">
      <label>
        <div class="classroom-card__classroom-name">
          {{ classroom.classroomName }}
        </div>
        <div class="classroom-card__summary">
          <span>扫描数量:{{ scansCount }}</span>
          <span>学生数量:{{ studentsCount }}</span>
        </div>
        <div class="classroom-card__selector">
          <input
            type="checkbox"
            :checked="classroom.selected"
            @change="$emit('click-selector')">
          <span></span>
        </div>
      </label>
      <div class="classroom-card__check-student-list" @click="studentListModalVisible = true">
        查看学生列表
      </div>
      <student-list-modal
        v-if="studentListModalVisible"
        :classroom-name="classroom.classroomName"
        :students="classroom.students"
        @close-modal="studentListModalVisible = false">
      </student-list-modal>
    </div>

    <div v-else>
      <label @click="showPrompt()">
        <div class="classroom-card__classroom-name">
          {{ classroom.classroomName }}
        </div>
        <span class="classroom-card__summary">
          <span>扫描数量:{{ scansCount }}</span>
          <span>学生数量:{{ studentsCount }}</span>
        </span>
        <div class="classroom-card__selector">
          <input type="checkbox" :checked="classroom.selected" disabled>
          <span></span>
        </div>
      </label>
    </div>
  </div>
</template>

<script>
  import MessageBox from '@/components/__mint-ui/MessageBox';
  import StudentListModal from './_classroom-card/StudentListModal';

  export default {
    components: {
      StudentListModal
    },

    props: ['classroom', 'is-first'],

    data () {
      return {
        studentListModalVisible: false
      };
    },

    computed: {
      studentsCount () {
        return this.classroom.students.length;
      },
      scansCount () {
        return this.classroom.summary.answerResultsTotal;
      }
    },

    methods: {
      showPrompt () {
        MessageBox.open(
          {
            message: `${this.classroom.classroomName}暂未添加学生，是否为该班级创建学生?`,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: '去创建'
          },
          () => this.$router.push({ name: 'teacherClassroom', params: { classroomId: this.classroom.classroomId } })
        );
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';
  @import 'src/assets/style/mixins';

  .classroom-card {
    position: relative;
    border-top: 1px solid $light-gray;

    &.is-first {
      border-top: none;
    }

    &.is-selected {
      color: $blue;
    }

    label {
      @include pressed(0, 0, 0, 0);

      position: relative;
      padding: 0 .3rem;
      display: block;
      height: 1.9rem;
      font-size: $h4;
      line-height: 1.2rem;
    }

    &__classroom-name {
      @include truncate(5rem);
      height: .8rem;
    }

    &__summary {
      color: $gray;
      font-size: $h5;
      vertical-align: top;

      span {
        margin-right: .3rem;
      }
    }

    &__selector {
      position: absolute;
      top: 0;
      right: .1rem;
      height: 1.2rem;
      width: 1rem;
      text-align: center;

      input {
        display: none;
      }

      span {
        position: relative;
        display: inline-block;
        width: .5rem;
        height: .5rem;
        background-color: $white;
        border-radius: 0;
        border: 1px solid $gray;
        vertical-align: middle;

        &:after {
          position: absolute;
          top: .1rem;
          left: .15rem;
          width: .1rem;
          height: .2rem;
          border: 2px solid transparent;
          border-left: 0;
          border-top: 0;
          content: '';
          transform: rotate(45deg) scale(0);
          transition: transform .2s, -webkit-transform .2s;
          -webkit-transform: rotate(45deg); // For android 4.4
        }
      }

      input:checked + span {
        background-color: $blue;
        border-color: $blue;
      }

      input:checked + span:after {
        border-color: $white;
        transform: rotate(45deg) scale(1);
      }
    }

    &__check-student-list {
      position: absolute;
      top: .8rem;
      left: 4rem;
      padding-top: .4rem;
      height: 1.2rem;
      color: $blue;
      font-size: $h5;
      text-decoration: underline;

      &:active {
        opacity: .8;
      }
    }
  }
</style>
