<template>
  <div class="mask-layer"></div>
</template>

<script>
  import webviewService from '@/services/webview';

  const lightBlueStr = '#26a2ff';
  const darkBlueStr  = '#082033';

  export default {
    beforeCreate () {
      webviewService.changeStatusBarColor(darkBlueStr);
    },
    beforeDestroy () {
      webviewService.changeStatusBarColor(lightBlueStr);
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .mask-layer {
    position: fixed;
    z-index: $highest-z-index + 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .8;
    background: $black;
  }
</style>
