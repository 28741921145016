import ClassroomList from '@/entries-teacher/ClassroomList';
import Classroom     from '@/entries-teacher/Classroom';
import QuizList      from '@/entries-teacher/QuizList';
import Discover      from '@/entries-teacher/Discover';
import Quiz          from '@/entries-teacher/Quiz';
import EditQuiz      from '@/entries-teacher/EditQuiz';
import QuizReport    from '@/entries-teacher/QuizReport';
import Profile       from '@/entries-teacher/Profile';
import EditAccount   from '@/entries-teacher/_profile/EditAccount';
import Scan          from '@/entries-teacher/Scan';
import Feedback      from '@/entries-teacher/feedback/Feedback'


export default [
  { path: '/teacher/classroom-list'        , name: 'teacherClassroomList', component: ClassroomList },
  { path: '/teacher/classroom/:classroomId', name: 'teacherClassroom'    , component: Classroom     },
  { path: '/teacher/quiz-list'             , name: 'teacherQuizList'     , component: QuizList      },
  { path: '/teacher/discover'              , name: 'teacherDiscover'     , component: Discover      },
  { path: '/teacher/quiz/:quizId'          , name: 'teacherQuiz'         , component: Quiz          },
  { path: '/teacher/quiz/:quizId/edit'     , name: 'teacherEditQuiz'     , component: EditQuiz      },
  { path: '/teacher/quiz/:quizId/report'   , name: 'teacherQuizReport'   , component: QuizReport    },
  { path: '/teacher/profile'               , name: 'teacherProfile'      , component: Profile       },
  { path: '/teacher/profile/edit'          , name: 'teacherEditAccount'  , component: EditAccount   },
  { path: '/teacher/scan'                  , name: 'teacherScan'         , component: Scan          },
  { path: '/teacher/feedback'              , name: 'Feedback'            , component: Feedback      }
];
