<template>
  <div class="password-login">
    <div class="password-login__header">
      <mt-header fixed title="密码登录">
        <router-link to="" slot="left">
          <mt-button icon="back" @click="$emit('click-back-button')"></mt-button>
        </router-link>
      </mt-header>
    </div>
    <div class="password-login__main">
      <mt-field
        v-model.trim="mobile"
        :placeholder="$t('Login.placeholder.mobile')"
        type="tel"
        disableClear>
      </mt-field>
      <span
        class="password-login__reset-mobile-button"
        v-show="resetMobileButtonVisible"
        @click="mobile = ''">
        <i class="fa fa-times"></i>
      </span>
      <mt-field
        v-model="password"
        :placeholder="$t('Login.placeholder.password')"
        :type="passwordIsHidden ? 'password' : 'text'"
        disableClear>
      </mt-field>
      <span class="password-login__eye-icon" :class="{ 'is-dark': passwordIsHidden }">
        <i
          class="fa fa-eye-slash"
          v-if="passwordIsHidden"
          @click="passwordIsHidden = false">
        </i>
        <i
          class="fa fa-eye"
          v-else
          @click="passwordIsHidden = true">
        </i>
      </span>
      <mt-button type="primary" class="password-login__login-button" @click="login()">
        {{ $t('Login.label.login') }}
      </mt-button>
      <div class="password-login__forget-password">
        <span @click="forgetPassword()">忘记密码?</span>
      </div>
    </div>
  </div>
</template>

<script>
  import Toast      from '@/components/__mint-ui/Toast';
  import Indicator  from '@/components/__mint-ui/Indicator';
  import MessageBox from '@/components/__mint-ui/MessageBox';

  import webviewService from '@/services/webview';

  import urls         from '@/api/teacher-urls';
  import errorHandler from '@/api/error-handler';

  export default {
    data () {
      return {
        mobile: '',
        password: '',

        passwordIsHidden: true,
        resetMobileButtonVisible: false
      };
    },

    watch: {
      mobile (val, oldVal) {
        if (oldVal === '') this.resetMobileButtonVisible = true;
        if (val    === '') this.resetMobileButtonVisible = false;
      }
    },

    computed: {
      mobileIsValid () {
        return /^1(3|4|5|6|7|8|9)\d{9}$/.test(this.mobile);
      }
    },

    methods: {
      __login ({ mobile, password }) {
        const url = urls['passwordLogin']();
        return this.$http.post(url, { mobile, password });
      },

      login () {
        if (!this.mobile) {
          Toast.showWarning(this.$i18n.t('Login.warning.inputMobile'));
        }
        else if (!this.mobileIsValid) {
          Toast.showWarning('手机格式错误');
        }
        else if (!this.password) {
          Toast.showWarning('请填写密码');
        }
        else {
          Indicator.open('登录中...');
          this.__login({ mobile: this.mobile, password: this.password })
              .then(
                rep => {
                  Indicator.close();
                  this.$store.commit('SET_SESSION', { session: rep.data });
                  this.$router.push({ name: 'teacherClassroomList' });
                },
                err => {
                  Indicator.close();
                  errorHandler.handle(err);
                }
              );
        }
      },

      forgetPassword () {
        MessageBox.open(
          {
            message: '您可以使用验证码方式登录系统后重置密码',
            showConfirmButton: true,
            showCancelButton: false,
            confirmButtonText: '去登录'
          },
          () => {
            this.$emit('click-back-button');
          }
        );
      }
    },

    mounted () {
      window.setTimeout(() => { this.$el.querySelector('input').focus(); }, 300);
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('enter.passwordLogin', () => this.$emit('click-back-button'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('enter.passwordLogin');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .password-login {
    &__header {
      height: $header-height;
    }

    &__main {
      position: relative;
      text-align: center;
    }

    &__reset-mobile-button {
      position: absolute;
      top: .4rem;
      right: .3rem;
      color: $gray;
      font-size: $h4;

      &:active {
        opacity: .5;
      }
    }

    &__eye-icon {
      position: absolute;
      top: 1.55rem;
      right: .3rem;
      color: $blue;
      font-size: $h3;

      &:active {
        opacity: .5;
      }

      &.is-dark {
        color: $dark-gray;
      }
    }

    &__login-button {
      margin-top: .5rem;
      width: 90%;
    }

    &__forget-password {
      margin-top: .5rem;
      width: 100%;
      color: $dark-gray;
      font-size: $h5;
      text-align: center;
      text-decoration: underline;

      &:active {
        opacity: .5;
      }
    }
  }
</style>
