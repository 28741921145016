<template>
  <div class="sort-popup">
    <div class="sort-popup__content">
      <div
        class="sort-popup__item-ordinal"
        :class="{ 'is-active': sortKey === 'itemOrdinal' }"
        @click="$emit('click-sort-by-item-ordinal-button')">
        <i class="far fa-id-card"></i>
        <!-- <i class="fas fa-list-ol"></i> -->
        按题号排序
      </div>
      <div
        class="sort-popup__correct-rate"
        :class="{'is-active': sortKey === 'correctRate' }"
        @click="$emit('click-sort-by-correct-rate-button')">
        <i class="fas fa-chart-line"></i>
        按正确率排序
      </div>
    </div>
    <div class="sort-popup__mask" @click="$emit('click-mask')">
      <mask-layer></mask-layer>
    </div>
  </div>
</template>

<script>
  import MaskLayer from '@/components/MaskLayer';
  import webviewService  from '@/services/webview';

  export default {
    components: {
      MaskLayer
    },

    props: ['sortKey'],

    beforeCreate () {
      webviewService.pushBackButtonCallback('quiz.report.itemReport.sortPopup', () => this.$emit('click-mask'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('quiz.report.itemReport.sortPopup');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .sort-popup {
    letter-spacing: 0;

    &__content {
      position: fixed;
      z-index: $highest-z-index + 2;
      top: 40%;
      left: 10%;
      width: 80%;
      height: 2.6rem;
      border-radius: .05rem;
      background: $white;
    }

    &__item-ordinal,
    &__correct-rate {
      padding-left: .5rem;
      height: 1.3rem;
      width: 100%;
      font-size: $h4;
      line-height: 1.3rem;
      text-align: left;

      &.is-active {
        color: $blue;
      }

      i {
        width: .5rem;
        margin-right: .2rem;
      }
    }

    &__item-ordinal {
      border-bottom: 1px solid $light-gray;
    }
  }
</style>
