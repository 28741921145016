<template>
  <div class="homepage"></div>
</template>

<script>
  import MessageBox from '@/components/__mint-ui/MessageBox';

  import checkAccess    from '@/services/check-access';
  import webviewService from '@/services/webview';

  export default {
    beforeCreate () {
      if (this.$store.state.session.status === 'guest')
        this.$router.push({ name: 'enter' });
      else
        this.$router.push({ name: 'teacherClassroomList' });
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .homepage {

  }
</style>
