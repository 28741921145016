<template>
    <div class="unsupported">
        <wechat v-if="wechatBrowser"></wechat>
        <ios v-else-if="ios"></ios>
        <div class="unsupported__logo-container">
            <img class="unsupported__logo" src="~images/logo.png" />
            <p class="unsupported__title">门口易测</p>
            <p class="unsupported__version"><small>{{$store.state.deviceInfo.platform}}app
                    v{{`${$store.state.deviceInfo.appVersion}:${$store.state.deviceInfo.appBuild}`}}</small></p>
        </div>

    </div>
</template>

<script>
    import Wechat from '@/entries/_unsupported/Wechat'
    import ios from '@/entries/_unsupported/iOS'
    export default {
        components: {
            Wechat,
            ios
        },
        data() {
            return {
                wechatBrowser: false,
                ios: false
            }
        },

        created() {

            if (/MicroMessenger/.test(navigator.userAgent)) {
                this.wechatBrowser = true;
            } else if (this.$store.state.deviceInfo.operatingSystem === 'ios') {
                const webkit = !!navigator.userAgent.match(/WebKit/i);
                const iOSSafari = webkit && !/crios|mqbhd|baidu|ucbrowser|aliapp|sogou/.test(navigator.userAgent.toLowerCase())
                if (!iOSSafari) this.ios = true;
            }
        }

    };
</script>
<style lang="scss" scoped>
    @import 'src/assets/style/variables';

    .unsupported {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;


        &__logo-container {
            flex: 1;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
        }

        &__logo {
            width: 2rem;
        }

        &__title {
            margin: .2rem 0;
            font-weight: bold;
        }

    }
</style>