<template>
  <div class="empty-bubbleshet">
    <div class="empty-bubbleshet__prompt">
      答题卡暂无试题，请先设置试题数量
    </div>
    <mt-field
      v-model.number="itemsCount"
      :placeholder="$t('TeacherQuiz.placeholder.questionsCount')"
      class="empty-bubbleshet__items-count"
      type="number"
      disableClear>
    </mt-field>
    <mt-button
      class="empty-bubbleshet__confirm-button"
      type="primary"
      @click="confirm()">
      {{ $t('TeacherQuiz.label.confirm') }}
    </mt-button>
  </div>
</template>

<script>
  import Toast from '@/components/__mint-ui/Toast';

  export default {
    data () {
      return {
        itemsCount: null
      };
    },

    computed: {
      itemsCountIsValid () {
        return (this.itemsCount > 0) && (this.itemsCount <= 100);
      }
    },

    methods: {
      confirm () {
        if (!this.itemsCount) {
          Toast.showWarning('请输入试题数量');
        }
        else if (!this.itemsCountIsValid) {
          Toast.showWarning('试题数量范围1-100');
        }
        else {
          this.$emit('click-confirm-button', this.itemsCount);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .empty-bubbleshet {
    &__prompt {
      padding-left: .3rem;
      height: .8rem;
      font-size: $h5;
      line-height: 1rem;
    }

    &__items-count {
      width: 100%;
    }

    &__confirm-button {
      margin: .5rem 0 0 .5rem;
      width: 90%;
    }
  }
</style>
