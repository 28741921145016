<template>
  <div class="validate-modal">
    <div class="validate-modal__content">
      <div class="validate-modal__title">
        <span class="validate-modal__back-button" @click="$emit('close-modal')">
          <back-icon></back-icon>
        </span>
        扫描验证
      </div>
      <div class="validate-modal__student" v-if="validateObj.gradecam_id === ''">
        <div class="validate-modal__student__prompt">
          答卷对应的学生需要您进行确认:
        </div>
        <select v-model="studentQuizNumber" @change="showTip('选择学生生效')">
          <option disabled value="">请选择</option>
          <option v-for="student in students" :value="student.studentQuizNumber">
            <span>{{ student.studentName }}</span>
            <span>{{ student.studentQuizNumber }}</span>
          </option>
        </select>
      </div>

      <div class="validate-modal__question-list" v-if="questions.length > 0">
        <div class="validate-modal__question-list__prompt">
          以下试题的答案需要您进行确认:
        </div>
        <div class="validate-modal__question-item" v-for="question in questions">
          <div class="validate-modal__question-ordinal">
            第{{ question.itemOrdinal }}题
          </div>
          <div class="validate-modal__choice-list">
            <span
              v-for="(choice, index) in question.choices"
              class="validate-modal__choice-item"
              :class="{ 'is-correct': checkChoiceCorrect(question, index) }"
              :key="index"
              @click="toggleChoiceCorrect(question, index)">
              {{ getBubbleLabel(question.type, index) }}
            </span>
          </div>
        </div>
      </div>
      <div>
        <mt-button
          class="validate-modal__confirm-button"
          type="primary"
          @click="confirm()">
          确认
        </mt-button>
      </div>
    </div>
    <div class="validate-modal__mask" @click="$emit('close-modal')">
      <mask-layer></mask-layer>
    </div>
  </div>
</template>

<script>
  import BackIcon  from '@/components/BackIcon';
  import MaskLayer from '@/components/MaskLayer';

  import Toast from '@/components/__mint-ui/Toast';
  import { findWhere } from 'underscore';

  export default {
    components: {
      BackIcon,
      MaskLayer
    },

    props: ['validateObj', 'students', 'quiz'],

    data () {
      return {
        studentQuizNumber: this.validateObj.gradecam_id,
        questions        : this.__getQuestions()
      };
    },

    computed: {
      studentQuizNumberIsValid () {
        return this.studentQuizNumber !== '';
      }
    },

    methods: {
      __getQuestions () {
        let validateObj_questionItems              = this.validateObj.items.filter(item => item.type === 'question');
        let bubbleSheetStructure__withChoicesItems = this.quiz.quizPaper.bubbleSheetStructure.items.filter(item => item.type !== 'subjectiveSkip');

        return validateObj_questionItems.map(questionItem => {
          let questionItem_itemOrdinal = Number(questionItem.label.match(/\d+/)[0]);
          let withChoicesItem = bubbleSheetStructure__withChoicesItems[questionItem_itemOrdinal - 1];

          let label       = questionItem.label;
          let value       = questionItem.value;
          let itemOrdinal = withChoicesItem.itemOrdinal;
          let type        = withChoicesItem.type;
          let choices     = withChoicesItem.choices;

          return { label, value, itemOrdinal, type, choices };
        });
      },

      getBubbleLabel (type, index) {
        if (type === 'single' || type === 'multiple') {
          return ('ABCDEFGHIJK')[index];
        }
        else if (type === 'boolean') {
          return ('TF')[index];
        }
      },

      showTip (message) {
        Toast.showTip(message);
      },

      checkChoiceCorrect (question, choiceIndex) {
        let choiceValue = ('ABCDEFGHIJK')[choiceIndex];
        return (question.value.indexOf(choiceValue) === -1) ? false : true;
      },

      toggleChoiceCorrect (question, choiceIndex) {
        let choiceValue = ('ABCDEFGHIJK')[choiceIndex];

        if (question.value.indexOf(choiceValue) === -1)
          question.value = question.value + choiceValue;
        else
          question.value = question.value.replace(choiceValue, '');
      },

      confirm () {
        if (!this.studentQuizNumberIsValid) {
          Toast.showWarning('请选择学生');
        }
        else {
          let items = this.validateObj.items;

          items.forEach(item => {
            if (item.type === 'number') {
              item.value    = this.studentQuizNumber;
              item.validate = false;
            }
            else if (item.type === 'question') {
              let question = findWhere(this.questions, { label: item.label });

              item.value    = question.value;
              item.validate = false;
            }
          });

          this.$emit('click-confirm-button', items);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';
  @import 'src/assets/style/mixins';

  .validate-modal {
    &__content {
      position: fixed;
      z-index: $highest-z-index + 2;
      top: 1.3rem;
      left: 2%;
      width: 96%;
      background-color: $white;
      border-radius: .1rem;
    }

    &__title {
      height: 1.2rem;
      background-color: $blue;
      border-radius: .1rem .1rem 0 0;
      color: $white;
      font-size: $h4;
      line-height: 1.2rem;
      text-align: center;
    }

    &__back-button {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      width: 1rem;
      height: 1.2rem;
    }

    &__student {
      padding: .5rem .3rem;
      width: 100%;

      &__prompt {
        margin-bottom: .3rem;
        font-size: $h5;
      }

      select {
        padding: .2rem 1.2rem .2rem .3rem;
        width: 4.5rem;
        background-image: url('~images/arrow.png');
        background-position: 90% center;
        background-repeat: no-repeat;
        background-size: .3rem;
        border: 1px solid $gray;
        font-size: $h4;
        line-height: .6rem;

        &:active {
          opacity: .5;
        }
      }
    }

    &__question-list {
      margin: 0 .3rem;
      padding: .5rem 0;
      height: 5rem;
      border-top: 1px solid $light-gray;
      max-height: 5rem;
      overflow-y: auto;

      &__prompt {
        margin-bottom: .3rem;
        font-size: $h5;
      }
    }

    &__question-item {
      border-bottom: 1px solid $light-gray;
    }

    &__question-ordinal {
      display: inline-block;
      width: 1.2rem;
      font-size: $h5;
    }

    &__choice-list {
      padding: .2rem 0;
      display: inline-block;
    }

    &__choice-item {
      margin-left: .15rem;
      display: inline-block;
      width: .5rem;
      height: .5rem;
      border: 1px solid $black;
      border-radius: 50%;
      box-sizing: border-box;
      font-size: $h5;
      line-height: .5rem;
      text-align: center;

      &:active {
        opacity: .5;
      }

      &:first-child {
        margin-left: 0;
      }

      &.is-correct {
        background-color: $blue;
        border-color: $blue;
        color: $white;
      }
    }

    &__confirm-button {
      margin: .5rem 5%;
      width: 90%;
    }
  }
</style>

