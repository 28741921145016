<template>
    <div class="download-bubblesheet-modal">
        <div class="download-bubblesheet-modal__content">
            <div class="download-bubblesheet-modal__title">
                <span class="download-bubblesheet-modal__back-button" @click="$emit('close-modal')">
                    <back-icon></back-icon>
                </span>
                下载答题卡
                <!--
        <span
          class="download-bubblesheet-modal__send-button"
          v-show="downloadUrls.html !== ''"
          @click="sendToEmailModalVisible = true">
          <i class="fa fa-share"></i>
          <span>发送到邮箱</span>
        </span>
        <send-to-email-modal
          v-if="sendToEmailModalVisible"
          @close-modal="sendToEmailModalVisible = false"
          @click-confirm-button="sendBubblesheetToEmail">
        </send-to-email-modal>
-->
            </div>
            <div class="download-bubblesheet-modal__set-stid-digits-prompt">
                答题卡学号位数
            </div>
            <div class="download-bubblesheet-modal__set-stid-digits">
                <mt-field placeholder="学号位数（3-10）之间" type="number" :attr="{ min: 3, max: 10 }" v-model="digits"
                    @input="postMsg" ref="digits-field"></mt-field>
            </div>

            <div class="download-bubblesheet-modal__prompt" v-show="downloadUrls.html === ''">
                <span>答题卡加载中</span>
                <i class="fa fa-spinner fa-spin"></i>
                <br />
                <br />
                <span>如答题卡无法正常下载,请至电脑端mkyice.cn登录下载</span>
            </div>
            <div class="loadIcon" v-show="downloading">
                <mt-spinner :size="120" type="fading-circle" color="#26a2ff"></mt-spinner>
            </div>
            <div class="download-bubblesheet-modal__bubblesheet-container">
                <iframe class="download-bubblesheet-modal__iframe" frameBorder="0" ref="download-bubblesheet-modal__iframe"></iframe>
            </div>

            <div class="download-bubblesheet-modal__buttons-container">
                <mt-button class="download-bubblesheet-modal__download-button"
                    :class="{ 'is-active': downloadUrls.png !== '' || !downloading }" type="primary"
                    :disabled="downloading" @click="downloadBubblesheet()">
                    确认下载
                </mt-button>
            </div>
        </div>
        <div class="download-bubblesheet-modal__mask" @click="$emit('close-modal')">
            <mask-layer></mask-layer>
        </div>
    </div>
</template>

<script>
    import BackIcon from '@/components/BackIcon'
    import MaskLayer from '@/components/MaskLayer'

    import SendToEmailModal from './_download-bubblesheet-modal/SendToEmailModal'

    import Toast from '@/components/__mint-ui/Toast'

    import urls from '@/api/teacher-urls'

    import formService from '@/services/__gradecam/form'
    import download from '@/services/download'
    import webviewService from '@/services/webview'
    import _ from 'lodash'
    import {
        pluck,
        union
    } from 'underscore'

    export default {
        components: {
            BackIcon,
            MaskLayer,
            SendToEmailModal
        },

        props: ['quiz', 'downlodQuery'],

        data() {
            return {
                downloadUrls: {
                    html: '/bubblesheet.html',
                    pdf: '',
                    png: ''
                },
                sendToEmailModalVisible: false,
                downloading: true,
                digits: 0
            }
        },
        methods: {
            __fetchClassrooms({
                quizId
            }) {
                const url = urls['quizDataClassrooms']({
                    quizId
                })
                return this.$http.get(url)
            },
            downloadBubblesheet() {
                this.downloading = true
                this.$refs['download-bubblesheet-modal__iframe'].contentWindow.postMessage('getdownload')

                const now = new Date().getTime()
                const getStatus = ({
                    data
                }) => {
                    const next = new Date().getTime()
                    if (next < now + 8 * 1000) {
                        // console.log(data)
                        if (data === 'uploadOk') {
                            setTimeout(() => {
                                this.downloading = false
                            }, 1000)
                        } else if (data.startsWith('data:')) {
                            let filename = `bbs_${Date.now()}.png`
                            webviewService.downloadBase64Image(data, filename)
                            setTimeout(() => {
                                this.downloading = false
                            }, 1000)
                        }
                    } else {
                        window.removeEventListener('message', getStatus)
                        setTimeout(() => {
                            this.downloading = false
                        }, 1000)
                    }
                }
                window.addEventListener('message', getStatus)
            },
            sendBubblesheetToEmail() {
                // TODO: connect to api.
                this.sendToEmailModalVisible = false
            },
            postMsg: _.debounce(function () {
                this.$refs['download-bubblesheet-modal__iframe'].contentWindow.postMessage({
                    type: 'changeDigits',
                    value: this.digits
                })
            }, 500)
        },
        created() {
            
            this.__fetchClassrooms({
                    quizId: this.quiz.quizId
                })
                .then(rep => {
                    let classrooms = rep.data.classrooms
                    let students = union(...pluck(classrooms, 'students'))
                    students.sort((s1, s2) => s2.studentQuizNumber.length - s1.studentQuizNumber.length)

                    this.digits = students[0] ? students[0].studentQuizNumber.length : 8;

                    this.$refs['download-bubblesheet-modal__iframe'].onload = () => {
                        this.downloading = false;
                    }

                    this.$refs['download-bubblesheet-modal__iframe'].src = `${this.downloadUrls.html}${this.downlodQuery}`
                }).catch(() => {
                    this.$refs['download-bubblesheet-modal__iframe'].src = `${this.downloadUrls.html}${this.downlodQuery}`
                })
        },
        mounted() {
            // console.log(this.$refs['download-bubblesheet-modal__iframe'])
            // console.log(bbs_iframe)
            // iframe.onload = function () {
            //     this.downloading = false;
            // };
        },

        beforeCreate() {
            webviewService.pushBackButtonCallback('quiz.bubblesheet.downloadBubblesheetModal', () => this.$emit(
                'close-modal'))

        },
        beforeDestroy() {
            webviewService.popBackButtonCallback('quiz.bubblesheet.downloadBubblesheetModal')
        }
    };
</script>

<style lang="scss" scoped>
    @import 'src/assets/style/variables';

    .download-bubblesheet-modal {
        &__content {
            position: fixed;
            z-index: $highest-z-index + 2;
            top: 1.9rem;
            left: 2%;
            width: 96%;
            height: 12rem;
            background-color: $white;
            border-radius: 0.1rem;
        }

        &__title {
            height: 1.2rem;
            background-color: $blue;
            border-radius: 0.1rem 0.1rem 0 0;
            color: $white;
            font-size: $h4;
            line-height: 1.2rem;
            text-align: center;
        }

        &__back-button {
            position: absolute;
            top: 0;
            left: 0.15rem;
        }

        &__send-button {
            position: absolute;
            right: 0.3rem;
            font-size: $h5;

            &:active {
                opacity: 0.5;
            }
        }

        &__set-stid-digits-prompt {
            margin: .5rem 0 .1rem 0;
            padding-left: .2rem;
            font-size: $h5;
            font-weight: $bold;
        }

        &__set-stid-digits {
            margin-top: .2rem;
            width: 100%;
        }

        &__prompt {
            position: absolute;
            top: 4rem;
            left: 0;
            width: 100%;
            font-size: $h3;
            text-align: center;
        }

        &__bubblesheet-container {
            position: absolute;
            left: 0;
            right: 0;
            top: 3.6rem;
            bottom: 0;
            height: 6.2rem;
            overflow: hidden;
            padding: 0.2rem;
        }

        &__iframe {
            position: relative;
            width: 100%;
            height: 100%;
            zoom: 0.95;
            -moz-transform: scale(0.95);
            -moz-transform-origin: 0 0;
            -o-transform: scale(0.95);
            -o-transform-origin: 0 0;
            -webkit-transform: scale(0.95);
            -webkit-transform-origin: 0 0;
        }

        &__buttons-container {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 2rem;
            overflow: hidden;
        }

        &__download-button {
            margin: 0.5rem 5%;
            width: 90%;
            background-color: $gray;

            &.is-active {
                background-color: $blue;
            }
        }

        .loadIcon {
            width: 100%;
            height: 6.5rem;
            margin-top: 1.25rem;
            position: absolute;
            top: 1.2rem;
            left: 0;
            background: white;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        // .mint-cell.mint-field {
        //   ::v-deep .mint-cell-wrapper {
        //     display: flex;
        //     border: 0.02rem solid #26a2ff;
        //     background-color: #26a2ff;
        //     .mint-cell-title {
        //       width: 240px;
        //     }
        //     .mint-cell-value {
        //       height: 1rem;
        //       padding-left: 0.5rem;
        //       padding-right: 0.2rem;
        //       background-color: white;
        //       .mintui.mintui-field-error {
        //         font-size: 0.5rem;
        //       }
        //     }
        //   }
        // }
    }
</style>