<template>
  <div class="enter">
    <div v-show="smsLoginVisible">
      <div class="enter__header">
        <mt-header fixed title="注册 / 登录"></mt-header>
      </div>
      <div class="enter__main">
        <mt-field
          v-model.trim="mobile"
          :placeholder="$t('Login.placeholder.mobile')"
          type="tel"
          disableClear>
        </mt-field>
        <span
          class="enter__reset-mobile-button"
          v-show="resetMobileButtonVisible"
          @click="mobile = ''">
          <i class="fa fa-times"></i>
        </span>
        <mt-field
          v-model.trim="verificationCode"
          placeholder="验证码"
          type="text"
          disableClear>
        </mt-field>
        <mt-button
          class="enter__send-verification-code-button"
          type="primary"
          @click="sendVerificationCode()"
          :disabled="!mobileIsValid || verificationCodeSending">
          <span v-if="verificationCodeSending">
            已发送({{ verificationCodeCountdown }}s)
          </span>
          <span v-else>
            获取验证码
          </span>
        </mt-button>
        <mt-button type="primary" class="enter__enter-button" @click="enter()">
          进入门口易测
        </mt-button>
      </div>
      <div class="enter__password-login">
        <span>或</span>
        <mt-button class="enter__password-login__button" @click="passwordLoginVisible = true">
          使用密码登录
        </mt-button>
      </div>
<!--
      <div class="enter__wechat-login" v-show="wechatLoginVisible">
        <span class="enter__wechat-login__prompt">
          使用微信登录
        </span>
        <span class="enter__wechat-login__logo" @click="loginByWechat">
          <i class="fa fa-weixin"></i>
        </span>
      </div>
-->
    </div>

    <slide-transition>
      <password-login
        v-if="passwordLoginVisible"
        @click-back-button="passwordLoginVisible = false">
      </password-login>
    </slide-transition>

    <slide-transition>
      <register
        v-if="registerVisible"
        :registration-id="registrationId"
        @click-back-button="registerVisible = false">
      </register>
    </slide-transition>
  </div>
</template>

<script>
  import SlideTransition from '@/components/SlideTransition';

  import PasswordLogin from './_enter/PasswordLogin';
  import Register      from './_enter/Register';

  import Toast      from '@/components/__mint-ui/Toast';
  import Indicator  from '@/components/__mint-ui/Indicator';
  import MessageBox from '@/components/__mint-ui/MessageBox';

  import checkAccess    from '@/services/check-access';
  import webviewService from '@/services/webview';

  import urls         from '@/api/teacher-urls';
  import errorHandler from '@/api/error-handler';

  export default {
    components: {
      SlideTransition,
      PasswordLogin,
      Register
    },

    data () {
      return {
        mobile: '',
        verificationCode: '',
        verificationCodeSending: false,
        verificationCodeCountdown: 60,

        registrationId: null,

        resetMobileButtonVisible: false,
        passwordLoginVisible: false,
        registerVisible: false
      };
    },

    watch: {
      mobile (val, oldVal) {
        if (oldVal === '') this.resetMobileButtonVisible = true;
        if (val    === '') this.resetMobileButtonVisible = false;
      }
    },

    computed: {
      wechatLoginVisible () {
        // Open wechat login after We pass Tencent verification.
        return false;

        // return webviewService.wechatIsInstalled();
      },

      smsLoginVisible () {
        return (!this.passwordLoginVisible && !this.registerVisible);
      },

      mobileIsValid () {
        return /^1(3|4|5|6|7|8|9)\d{9}$/.test(this.mobile);
      }
    },

    methods: {
      __startVerificationCodeTimer () {
        const intervalId =
          window.setInterval(
            () => {
              if (this.verificationCodeCountdown === 0) {
                window.clearInterval(intervalId);

                this.verificationCodeSending   = false;
                this.verificationCodeCountdown = 60;
              } else {
                this.verificationCodeCountdown--;
              }
            },
            1000
          );
      },

      __sendVerificationCode ({ mobile }) {
        const url = urls['enterVerificationCode']();
        return this.$http.post(url, { mobile });
      },

      __verify ({ mobile, verificationCode }) {
        const url = urls['verify']();
        return this.$http.post(url, { mobile, verificationCode });
      },

      __toRegister ({ registrationId }) {
        this.registrationId  = registrationId;
        this.registerVisible = true;
      },

      __toLogin () {
        this.$store.dispatch('FETCH_SESSION').then(rep => this.$router.push({ name: 'teacherClassroomList' }));
      },

      sendVerificationCode () {
        Indicator.open('发送中...');
        this.__sendVerificationCode({ mobile: this.mobile })
            .then(
              rep => {
                Indicator.close();
                this.verificationCodeSending = true;
                this.__startVerificationCodeTimer();
              },
              err => {
                Indicator.close();
                errorHandler.handle(err);
              }
            );
      },

      enter () {
        if (!this.mobile) {
          Toast.showWarning(this.$i18n.t('Login.warning.inputMobile'));
        }
        else if (!this.mobileIsValid) {
          Toast.showWarning('手机格式错误');
        }
        else if (!this.verificationCode) {
          Toast.showWarning('请填写验证码');
        }
        else {
          Indicator.open();
          this.__verify({ mobile: this.mobile, verificationCode: this.verificationCode })
              .then(
                rep => {
                  Indicator.close();

                  const type = rep.data.type;
                  if (type === 'registration') this.__toRegister(rep.data.data);
                  if (type === 'login')        this.__toLogin(rep.data.data);
                },
                err => {
                  Indicator.close();
                  errorHandler.handle(err);
                }
              );
        }
      },

      loginByWechat () {
        webviewService.authByWechat()
                      .then(
                        rep => {
                          // TODO: send request to bankend.
                        }
                      );
      }
    },

    beforeCreate () {
      checkAccess('guest');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .enter {
    /* Hack for ios issue: when long press the blank area, the background will turn gray. */
    height: 90vh;

    &__header {
      height: $header-height;
    }

    &__main {
      position: relative;
      text-align: center;
    }

    &__reset-mobile-button {
      position: absolute;
      top: .4rem;
      right: 3.8rem;
      color: $gray;
      font-size: $h4;

      &:active {
        opacity: .5;
      }
    }

    &__send-verification-code-button {
      position: absolute;
      top: .2rem;
      right: .2rem;
      width: 3.3rem;
      height: .8rem;
    }

    &__enter-button {
      margin-top: .5rem;
      width: 90%;
    }

    &__password-login {
      span {
        margin-top: .3rem;
        width: 100%;
        display: inline-block;
        color: $dark-gray;
        font-size: $h5;
        text-align: center;
      }

      &__button {
        margin-top: .3rem;
        margin-left: 5%;
        width: 90%;
        color: $blue;
      }
    }

    &__wechat-login {
      margin-top: 3rem;
      text-align: center;

      &__prompt {
        position: relative;
        padding: 0 .2rem;
        color: $dark-gray;
        font-size: $h5;

        &:before,
        &:after {
          top: .2rem;
          position: absolute;
          width: 3rem;
          height: 1px;
          border-bottom: 1px solid $dark-gray;
          content: '',
        }
        &:before {
          left: -3rem;
        }
        &:after {
          right: -3rem;
        }
      }

      &__logo {
        margin-top: .5rem;
        display: block;
        color: $green;
        font-size: $h2;
      }
    }
  }
</style>
