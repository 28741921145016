import Vue from 'vue'
import MessageBox from '@/components/__mint-ui/MessageBox';
import semver from 'semver';
import store from '@/store'

import 'capacitor-plugin-file-downloader';

import {
    Plugins,
    FilesystemDirectory
} from '@capacitor/core';
const {
    Haptics,
    App,
    Browser,
    FileDownloader,
    Filesystem,
    Modals,
    Network,
    SplashScreen,
    StatusBar,
    Toast
} = Plugins;

import {
    FileOpener
} from '@ionic-native/file-opener'

export default {

    pwa_update(manual) {
        if (window.updateAvailable) {
            MessageBox.open({
                    message: '检测到了新版本。现在更新？',
                    closeOnClickModal: true,
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: '更新',
                    cancelButtonText: '忽略'
                },
                () => window.location.reload(true),
                () => {}
            );
        } else {
            if (manual)
            MessageBox.open({
                    message: '已经是最新版本',
                    closeOnClickModal: true,
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonText: '确定',
                },
                () => {},
                () => {}
            );
        }
    },



    async android_update(manual = true) {        
        const versionInfo = await this.android_getLatestVersionInfo();
        if (!versionInfo) return;

        const apkData = versionInfo.apkData;
        const validUpdate = semver.lt(store.state.deviceInfo.appVersion, apkData.versionName);

        if (!validUpdate) {
            console.log(store.state.deviceInfo.appVersion, apkData.versionName)
            if (manual)
            MessageBox.open({
                    message: '已经是最新版本',
                    closeOnClickModal: true,
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonText: '确定',
                },
                () => {},
                () => {}
            );
            return;
        };

        this.android_getApk(apkData,manual)
    },

    async android_getApk(apkData,manual) {

        
        // TODO: check if file already downloaded


        // Check if already downloading
        if (window.__downloadingApk) {
            Toast.show({
                text: '正在下载'
            });
            return;
        };

        const apkUrl = `${process.env.VUE_APP_BASE_URL}/apks/current/${apkData.outputFile}`;

        // let request;
        // if (window.XMLHttpRequest) request = new XMLHttpRequest();
        // else request = new ActiveXObject("Microsoft.XMLHTTP");
        // request.open('GET', apkUrl, false);
        // request.send();

        let request = await Vue.http.head(apkUrl);

        if (request.status == 200) {
            MessageBox.open({
                    message: '检测到了新版本。现在下载？',
                    closeOnClickModal: true,
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: '下载',
                    cancelButtonText: '忽略'
                },
                () => this.android_downloadApk(apkData),
                () => {}
            );
        } else {
            if (manual)
            MessageBox.open({
                    message: '已经是最新版本',
                    closeOnClickModal: true,
                    showConfirmButton: true,
                    showCancelButton: false,
                    confirmButtonText: '确定',
                },
                () => {},
                () => {}
            );
        }
    },

    async android_downloadApk(apkData) {

        window.__downloadingApk = true;

        Toast.show({
            text: '正在下载'
        });

        const apkUrl = `${process.env.VUE_APP_BASE_URL}/apks/current/${apkData.outputFile}`

        try {
            const resp = await Vue.http.get(apkUrl, {
                responseType: 'blob'
            });
    
            const blob = await resp.blob();
    
            const reader = new FileReader();
            await new Promise((resolve, reject) => {
                reader.onload = resolve;
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
    
            const appendFileRes = await Filesystem.writeFile({
                path: `${apkData.outputFile}`,
                data: reader.result,
                directory: FilesystemDirectory.Data
            });
    
            const apkLocalPath = appendFileRes.uri
    
            FileOpener.open(apkLocalPath, 'application/vnd.android.package-archive')
                .then(() => console.log('File is opened'))
                .catch(e => console.log('Error opening file', e));
        } catch(err) {

            Toast.show({
                text: '下载失败'
            });

        }
        
        
        window.__downloadingApk = false;
    },


    async android_getLatestVersionInfo() {

        const versionJsonUrl = `${process.env.VUE_APP_BASE_URL}/apks/current/output.json`

        const resp = await fetch(versionJsonUrl);

        const data = await resp.json();

        if (data && data[0]) return data[0]

        return null;
    }


}