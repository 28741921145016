import Vue         from 'vue';
import VueResource from 'vue-resource';

import store from '@/store';

import webviewService from '@/services/webview';

Vue.use(VueResource);

Vue.http.interceptor.before = (request, next) => {
  // request.withCredentials = true; // Allow cross-site cookies.
  request.headers.set('MENCO-ASSESSMENT-MID', webviewService.get_mId());
  request.headers.set('MENCO-ASSESSMENT-UAV', webviewService.get_uav());

  if (request.method === 'POST' || request.method === 'PUT' || request.method === 'DELETE')
    if (store.state.networkStatus === 'offline') return;

  next();
};

