<template>
  <div class="edit-count-modal">
    <div class="edit-count-modal__content">
      <div class="edit-count-modal__title">
        <span class="edit-count-modal__back-button" @click="$emit('close-modal')">
          <back-icon></back-icon>
        </span>
        {{ $t('TeacherQuiz.label.modifyCount') }}
      </div>
      <mt-field
        v-model.number="itemsCount"
        :placeholder="$t('TeacherQuiz.placeholder.questionsCount')"
        class="edit-count-modal__items-count"
        type="number"
        disableClear>
      </mt-field>
      <div>
        <mt-button
          class="edit-count-modal__confirm-button"
          type="primary"
          @click="confirm()">
          {{ $t('TeacherQuiz.label.confirm') }}
        </mt-button>
      </div>
    </div>
    <div class="edit-count-modal__mask" @click="$emit('close-modal')">
      <mask-layer></mask-layer>
    </div>
  </div>
</template>

<script>
  import BackIcon  from '@/components/BackIcon';
  import MaskLayer from '@/components/MaskLayer';

  import Toast from '@/components/__mint-ui/Toast';
  import webviewService  from '@/services/webview';

  export default {
    components: {
      BackIcon,
      MaskLayer
    },

    props: ['count'],

    data () {
      return {
        itemsCount: this.count
      };
    },

    computed: {
      itemsCountIsValid () {
        return (this.itemsCount > 0) && (this.itemsCount <= 100);
      }
    },

    methods: {
      confirm () {
        if (!this.itemsCount)
          Toast.showWarning('请输入试题数量');
        else if (!this.itemsCountIsValid)
          Toast.showWarning('试题数量范围1-100');
        else
          this.$emit('click-confirm-button', this.itemsCount);
      }
    },

    mounted () {
      window.setTimeout(() => { this.$el.querySelector('input').focus(); }, 300);
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('quiz.bubblesheet.editCountModal', () => this.$emit('close-modal'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('quiz.bubblesheet.editCountModal');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .edit-count-modal {
    &__content {
      position: fixed;
      z-index: $highest-z-index + 2;
      top: 4rem;
      left: 2%;
      width: 96%;
      background-color: $white;
      border-radius: .1rem;
    }

    &__title {
      height: 1.2rem;
      background-color: $blue;
      border-radius: .1rem .1rem 0 0;
      color: $white;
      font-size: $h4;
      line-height: 1.2rem;
      text-align: center;
    }

    &__back-button {
      position: absolute;
      top: 0;
      left: .15rem;
    }

    &__items-count {
      width: 100%;
    }

    &__confirm-button {
      margin: .5rem 5%;
      width: 90%;
    }
  }
</style>

