<template>
  <div class="validate-modal">
    <div class="validate-modal__content">
      <div class="validate-modal__title">
        <span class="validate-modal__back-button" @click="$emit('close-modal')">
          <back-icon></back-icon>
        </span>
        学号不存在
      </div>
      <div class="validate-modal__student">
        <div class="validate-modal__student__prompt">
          答卷对应的学生需要您进行确认:
        </div>
        <select v-model="studentQuizNumber" @change="showTip('选择学生生效')">
          <option disabled value="">请选择</option>
          <option
            v-for="student in students"
            :value="student.studentQuizNumber"
            :key="student.studentQuizNumber"
          >
            <span>{{ student.studentName }}</span>
            <span>{{ student.studentQuizNumber }}</span>
          </option>
        </select>
      </div>
      <div>
        <mt-button
          class="validate-modal__confirm-button"
          type="primary"
          @click="confirm()"
        >
          确认
        </mt-button>
      </div>
    </div>
    <div class="validate-modal__mask" @click="$emit('close-modal')">
      <mask-layer></mask-layer>
    </div>
  </div>
</template>

<script>
import BackIcon from "@/components/BackIcon";
import MaskLayer from "@/components/MaskLayer";

import Toast from "@/components/__mint-ui/Toast";
import { findWhere } from "underscore";

export default {
  components: {
    BackIcon,
    MaskLayer,
  },

  props: ["scanObj", "students"],

  data() {
    return {
      studentQuizNumber: "",
    };
  },
  created() {
      console.log(this.scanObj)
  },

  computed: {
    studentQuizNumberIsValid() {
      return this.studentQuizNumber !== "";
    },
  },

  methods: {
    showTip(message) {
      Toast.showTip(message);
    },

    confirm() {
      if (!this.studentQuizNumberIsValid) {
        Toast.showWarning("请选择学生");
      } else {
        this.scanObj.gradecam_id = this.studentQuizNumber
        this.$emit("click-confirm-button", this.scanObj);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/variables";
@import "src/assets/style/mixins";

.validate-modal {
  &__content {
    position: fixed;
    z-index: $highest-z-index + 2;
    top: 1.3rem;
    left: 2%;
    width: 96%;
    background-color: $white;
    border-radius: 0.1rem;
  }

  &__title {
    height: 1.2rem;
    background-color: $blue;
    border-radius: 0.1rem 0.1rem 0 0;
    color: $white;
    font-size: $h4;
    line-height: 1.2rem;
    text-align: center;
  }

  &__back-button {
    position: absolute;
    top: 0;
    left: 0;
    display: inline-block;
    width: 1rem;
    height: 1.2rem;
  }

  &__student {
    padding: 0.5rem 0.3rem;
    width: 100%;

    &__prompt {
      margin-bottom: 0.3rem;
      font-size: $h5;
    }

    select {
      padding: 0.2rem 1.2rem 0.2rem 0.3rem;
      width: 4.5rem;
      background-image: url("~images/arrow.png");
      background-position: 90% center;
      background-repeat: no-repeat;
      background-size: 0.3rem;
      border: 1px solid $gray;
      font-size: $h4;
      line-height: 0.6rem;

      &:active {
        opacity: 0.5;
      }
    }
  }

  &__question-list {
    margin: 0 0.3rem;
    padding: 0.5rem 0;
    height: 5rem;
    border-top: 1px solid $light-gray;
    max-height: 5rem;
    overflow-y: auto;

    &__prompt {
      margin-bottom: 0.3rem;
      font-size: $h5;
    }
  }

  &__question-item {
    border-bottom: 1px solid $light-gray;
  }

  &__question-ordinal {
    display: inline-block;
    width: 1.2rem;
    font-size: $h5;
  }

  &__choice-list {
    padding: 0.2rem 0;
    display: inline-block;
  }

  &__choice-item {
    margin-left: 0.15rem;
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    border: 1px solid $black;
    border-radius: 50%;
    box-sizing: border-box;
    font-size: $h5;
    line-height: 0.5rem;
    text-align: center;

    &:active {
      opacity: 0.5;
    }

    &:first-child {
      margin-left: 0;
    }

    &.is-correct {
      background-color: $blue;
      border-color: $blue;
      color: $white;
    }
  }

  &__confirm-button {
    margin: 0.5rem 5%;
    width: 90%;
  }
}
</style>
