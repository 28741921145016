import { Indicator } from 'mint-ui';
import webviewService from '@/services/webview';

export default {
  open (message) {
    Indicator.open(message);
    webviewService.pushBackButtonCallback('indicator', () => this.close());
  },

  close () {
    Indicator.close();
    webviewService.popBackButtonCallback('indicator');
  }
};
