import Vue from 'vue'
import urls from '@/api/teacher-urls'

const baseUrl = process.env.VUE_APP_WVS_BASE_URL

export function fetchSession() {
  const url = urls['session']()
  return Vue.http.get(url)
};

export function fetch_mId() {
  const url = baseUrl + '/_meta/mId';;
  return Vue.http.post(url)
}
