<template>
  <div class="edit-account">
    <div v-show="editingField === null">
      <div class="edit-account__header">
        <mt-header fixed :title="$t('TeacherProfile.title.account')">
          <a slot="left" @click="$router.go(-1)">
            <mt-button icon="back" @click="$emit('click-back-button')"></mt-button>
          </a>
        </mt-header>
      </div>
      <div class="edit-account__main">
        <div class="edit-account__fullname" @click="editingField = 'fullname'">
          <mt-cell :title="$t('TeacherProfile.label.fullname')" is-link></mt-cell>
          <span class="edit-account__fullname__content">{{ account.fullname }}</span>
        </div>
        <div class="edit-account__mobile" @click="editingField = 'mobile'">
          <mt-cell title="修改手机号" is-link></mt-cell>
          <span class="edit-account__mobile__content">{{ account.mobile }}</span>
        </div>
        <div class="edit-account__modify-password" @click="editingField = 'password'">
          <mt-cell :title="$t('TeacherProfile.label.modifyPassword')" is-link></mt-cell>
        </div>
        <div class="edit-account__school" @click="editingField = 'school'">
          <mt-cell :title="$t('TeacherProfile.label.school')" is-link></mt-cell>
          <span class="edit-account__subject__content">
            {{ account.schoolInfo.directorySchoolName ? account.schoolInfo.directorySchoolName : '未填写' }}
          </span>
        </div>
        <div class="edit-account__subject" @click="editingField = 'subject'">
          <mt-cell :title="$t('TeacherProfile.label.subject')" is-link></mt-cell>
          <span class="edit-account__subject__content">
            {{ getSubjectLabel(account.subject) ? getSubjectLabel(account.subject) : '未填写' }}
          </span>
        </div>
        <div class="edit-account__logout" @click="logout()">
          <mt-cell :title="$t('TeacherProfile.label.logout')"></mt-cell>
        </div>
      </div>
    </div>

    <slide-transition>
      <edit-fullname
        @click-back-button="editingField = null"
        @click-confirm-button="editingField = null"
        v-if="editingField === 'fullname'">
      </edit-fullname>
    </slide-transition>

    <slide-transition>
      <change-mobile
        @click-back-button="editingField = null"
        @click-confirm-button="editingField = null"
        v-if="editingField === 'mobile'">
      </change-mobile>
    </slide-transition>

    <slide-transition>
      <edit-password
        @click-back-button="editingField = null"
        @click-confirm-button="editingField = null"
        v-if="editingField === 'password'">
      </edit-password>
    </slide-transition>
    <slide-transition>
      <edit-subject
        @click-back-button="editingField = null"
        @click-subject-item="editingField = null"
        v-if="editingField === 'subject'">
      </edit-subject>
    </slide-transition>
    <slide-transition>
      <edit-school
        @click-back-button="editingField = null"
        @click-school-item="editingField = null"
        v-if="editingField === 'school'">
      </edit-school>
    </slide-transition>
  </div>
</template>

<script>
  import SlideTransition from '@/components/SlideTransition';

  import EditFullname from './_edit-account/EditFullname';
  import EditPassword from './_edit-account/EditPassword';
  import EditSubject  from './_edit-account/EditSubject';
  import EditSchool   from './_edit-account/EditSchool';
  import ChangeMobile from './_edit-account/ChangeMobile';

  import Toast      from '@/components/__mint-ui/Toast';
  import Indicator  from '@/components/__mint-ui/Indicator';
  import MessageBox from '@/components/__mint-ui/MessageBox';

  import subjectOptions from '@/services/subject-options';
  import webviewService from '@/services/webview';

  import urls from '@/api/teacher-urls';

  import { findWhere } from 'underscore';

  export default {
    components: {
      SlideTransition,
      EditFullname,
      EditPassword,
      EditSubject,
      EditSchool,
      ChangeMobile
    },

    data () {
      return {
        editingField: null
      };
    },

    computed: {
      account () {
        return this.$store.state.session.account;
      }
    },

    methods: {
      __logout ({ userId }) {
        const url = urls['logout']();
        return this.$http.post(url, { userId });
      },

      logout () {
        MessageBox.open(
          {
            message: this.$i18n.t('TeacherProfile.message.logout'),
            showConfirmButton: true,
            showCancelButton: true
          },
          () => {
            const userId = this.account.userId;

            Indicator.open('正在退出...');
            this.__logout({ userId }).then(
              rep => {
                Indicator.close();
                this.$store.commit('SET_SESSION', { session: rep.data });
                this.$router.push({ name: 'enter' });
              },
              err => {
                Indicator.close();
              }
            );
          }
        );
      },

      getSubjectLabel (value) {
        return (value === null) ? '' : findWhere(subjectOptions, { value })['label'];
      }
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('profile.editAccount', () => this.$emit('click-back-button'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('profile.editAccount');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';
  @import 'src/assets/style/mixins';

  .edit-account {
    &__header {
      height: 1.2rem;
    }

    &__main {
      margin-top: .5rem;
    }

    &__fullname,
    &__mobile,
    &__school,
    &__subject {
      position: relative;

      &__content {
        position: absolute;
        top: .37rem;
        right: .8rem;
        display: inline-block;
        color: $dark-gray;
        font-size: $h4;
      }
    }


    &__modify-password {
      margin-top: .5rem;
    }

    &__logout {
      @include pressed(0, 0, 0, 0);

      position: relative;
      margin-top: .5rem;
      color: $blue;
      text-align: center;
    }
  }
</style>
