<template>
  <div class="network-issue-bar">
    <span>网络连接不可用</span>
  </div>
</template>

<script>
  export default {

  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .network-issue-bar {
    position: fixed;
    z-index: $highest-z-index + 3;
    bottom: 2rem;
    width: 100%;
    text-align: center;

    span {
      display: inline-block;
      padding: .3rem 0;
      width: 3rem;
      background-color: $darker-gray;
      border-radius: .2rem;
      color: $white;
      font-size: $h5;
    }
  }
</style>
