import Vue     from 'vue';
import VueI18n from 'vue-i18n';

import ch from './ch';
import en from './en';

Vue.use(VueI18n);

export default new VueI18n({
  locale: 'ch',
  messages: { ch, en }
});
