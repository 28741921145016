<template>
  <div class="nav-tab-bar">
    <div
      class="nav-tab-bar__bubblesheet"
      :class="{ 'is-active': currentTab === 'bubblesheet' }"
      @click="$emit('click-tab-item', 'bubblesheet')">
      <i class="fa fa-table"></i>
      {{ $t('TeacherQuiz.label.bubblesheet') }}
    </div>
    <div
      class="nav-tab-bar__scan"
      :class="{ 'is-active': currentTab === 'scan' }"
      @click="goToScan()">
      <i class="fa fa-camera"></i>
      {{ $t('TeacherQuiz.label.scan') }}
    </div>
    <div
      class="nav-tab-bar__report"
      :class="{ 'is-active': currentTab === 'report' }"
      @click="goToReport()">
      <i class="fas fa-chart-bar"></i>
      {{ $t('TeacherQuiz.label.report') }}
    </div>
  </div>
</template>

<script>
  import Toast from '@/components/__mint-ui/Toast';

  export default {
    props: ['quiz', 'currentTab'],

    computed: {
      isScanDisabled () {
        return this.quiz.quizPaper.bubbleSheetStructure.items.length === 0;
      },
      isReportDisabled () {
        return this.quiz.quizSummary.answerResultsTotal === 0;
      }
    },

    methods: {
      goToScan () {
        if (this.isScanDisabled)
          Toast.showWarning(this.$i18n.t('TeacherQuiz.warning.setBubblesheet'));
        else
          this.$emit('click-tab-item', 'scan');
      },

      goToReport () {
        if (this.isScanDisabled)
          Toast.showWarning(this.$i18n.t('TeacherQuiz.warning.setBubblesheet'));
        else if (this.isReportDisabled)
          Toast.showWarning(this.$i18n.t('TeacherQuiz.warning.scanBubblesheet'));
        else
          this.$emit('click-tab-item', 'report');
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .nav-tab-bar {
    letter-spacing: -1rem;
    text-align: center;
    

    &__bubblesheet,
    &__scan,
    &__report {
      // position: fixed;
      z-index: $highest-z-index;
      top: 1.3rem;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      width: 26%;
      height: 0.9rem;
      color: $white;
      border: 1px solid $white;
      font-size: $h5;
      letter-spacing: 0;
      // line-height: 0.9rem;

      &.is-active {
        background-color: $white;
        color: $blue;
      }

      i {
        margin-right: 0.1rem;
      }
    }

    &__bubblesheet,
    &__scan {
      border-right: none;
    }

    &__bubblesheet {
      border-radius: .1rem .01rem .01rem .1rem;
      // left: 17%;
    }

    &__scan {
      border-radius: .01rem;
      // left: 39%;
    }

    &__report {
      border-radius: .01rem .1rem .1rem .01rem;
      // left: 61%;
    }
  }
</style>
