<template>
  <div class="student-list-modal">
    <div class="student-list-modal__content">
      <div class="student-list-modal__title">
        <span class="student-list-modal__back-button" @click="$emit('close-modal')">
          <back-icon></back-icon>
        </span>
        已创建学生({{ students.length }})
      </div>
      <div class="student-list-modal__table">
        <div class="student-list-modal__table-header">
          <span class="student-list-modal__table-header__student-name">姓名</span>
          <span class="student-list-modal__table-header__student-quiz-number">考号</span>
        </div>
        <div class="student-list-modal__table-content">
          <div class="student-list-modal__student-card" v-for="student in students">
            <span class="student-list-modal__student-card__student-name">
              {{ student.studentName }}
            </span>
            <span class="student-list-modal__student-card__student-quiz-number">
              {{ student.studentQuizNumber }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="student-list-modal__mask" @click="$emit('close-modal')">
      <mask-layer></mask-layer>
    </div>
  </div>
</template>

<script>
  import BackIcon  from '@/components/BackIcon';
  import MaskLayer from '@/components/MaskLayer';

  import Toast from '@/components/__mint-ui/Toast';
  import webviewService  from '@/services/webview';

  export default {
    components: {
      BackIcon,
      MaskLayer
    },

    props: ['students'],

    beforeCreate () {
      webviewService.pushBackButtonCallback('quiz.bubblesheet.createStudent.studentListModal', () => this.$emit('close-modal'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('quiz.bubblesheet.createStudent.studentListModal');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .student-list-modal {
    &__content {
      position: fixed;
      z-index: $highest-z-index + 2;
      top: 4.5rem;
      left: 2%;
      width: 96%;
      background-color: $white;
      border-radius: .1rem;
    }

    &__title {
      height: 1.2rem;
      background-color: $blue;
      border-radius: .1rem .1rem 0 0;
      color: $white;
      font-size: $h4;
      line-height: 1.2rem;
      text-align: center;
    }

    &__back-button {
      position: absolute;
      top: 0;
      left: .15rem;
    }

    &__table {
      padding: .5rem;
    }

    &__table-header {
      display: flex;
      font-size: $h5;

      &__student-name {
        flex: 2;
      }

      &__student-quiz-number {
        flex: 1;
      }
    }

    &__table-content {
      margin-top: .3rem;
      max-height: 8rem;
      overflow-y: auto;
    }

    &__student-card {
      padding: .3rem 0;
      display: flex;
      font-size: $h4;

      &__student-name {
        flex: 2;
      }

      &__student-quiz-number {
        flex: 1;
      }
    }

    &__confirm-button {
      margin: .5rem 5%;
      width: 90%;
    }
  }
</style>

