<template>
  <div class="classroom-card">

    <div
      class="classroom-card__main"
      @contextmenu="handleContext($event)"
      @click="toClassroomPage"
      v-touch:touchhold="handlePress">
      <div class="classroom-card__name">{{ classroom.classroomName }}</div>
      <div class="classroom-card__summary">
        <span>创建时间: {{ createdDate }}</span>
        <span>学生人数: {{ studentsCount }}</span>
        <span
          class="classroom-card__create-students-prompt"
          v-show="studentsCount === 0">
          (暂无学生，请添加)
        </span>
      </div>
      <div class="classroom-card__arrow"></div>
    </div>

    <operations-popup
      v-if="operationsPopupVisible"
      @click-remove-button="removeClassroom()"
      @touch-mask="operationsPopupVisible = false">
    </operations-popup>
  </div>
</template>

<script>
  import OperationsPopup from './_classroom-card/OperationsPopup';

  import Toast      from '@/components/__mint-ui/Toast';
  import MessageBox from '@/components/__mint-ui/MessageBox';

  import urls from '@/api/teacher-urls';

  import formatDate     from '@/services/format-date';
  import webviewService from '@/services/webview';

  export default {
    components: {
      OperationsPopup
    },

    props: ['classroom', 'isFirst'],

    data () {
      return {
        operationsPopupVisible: false
      };
    },

    computed: {
      createdDate () {
        return formatDate(this.classroom.createdAt * 1000);
      },
      studentsCount () {
        return this.classroom.students.length;
      }
    },

    methods: {
      __removeClassroom ({ classroomId }) {
        const url = urls['classroom']({ classroomId });
        return this.$http.delete(url);
      },

      removeClassroom () {
        MessageBox.open(
          {
            message: `确认删除${this.classroom.classroomName}吗? 也将会删除此班级的测验数据。`,
            showConfirmButton: true,
            showCancelButton: true
          },
          () => {
            this.operationsPopupVisible = false;
            this.__removeClassroom({ classroomId: this.classroom.classroomId })
                .then(rep => {
                  this.$emit('remove-classroom-done');
                  Toast.showTip('删除成功');
                });
          },
          () => {
            this.operationsPopupVisible = false;
          }
        );
      },

      toClassroomPage () {
        this.$router.push({
          name: 'teacherClassroom',
          params: { classroomId: this.classroom.classroomId, classroomName: this.classroom.classroomName }
        });
      },

      handlePress () {
        webviewService.haptic();
        this.operationsPopupVisible = true;
      },

      handleContext (e) {
        e.preventDefault()
        this.handlePress();
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';
  @import 'src/assets/style/mixins';

  .classroom-card {
    &__main {
      @include pressed(0, 0, 0, -.35rem);
      position: relative;
      padding: .35rem 0 0 .3rem;
      height: 1.6rem;
      border-bottom: 1px solid $light-gray;
    }

    &__name {
      @include truncate(5rem);
      font-size: $h4;
    }

    &__summary {
      margin-top: .35rem;
      color: $dark-gray;
      font-size: $h5;

      span {
        margin-right: .1rem;
      }
    }

    &__create-students-prompt {
      color: $blue;
    }

    &__arrow {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      width: 1rem;
      height: 1.6rem;

      &:after {
        position: absolute;
        top: .8rem;
        right: .5rem;
        width: .15rem;
        height: .15rem;
        border: solid 2px $blue;
        border-top-width: 0;
        border-left-width: 0;
        content: '';
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  }
</style>
