<template>
  <div
    class="taker-card"
    :class="{ 'is-first': isFirst, 'is-dark': !hasResult }"
    @click="$emit('click-taker', entry)">
    <span class="taker-card__taker-name">
      {{ quizTaker.quizTakerName }}
    </span>
    <span class="taker-card__taker-number">
      {{ quizTaker.quizTakerNumber }}
    </span>
    <span class="taker-card__correct-rate">
      <span class="taker-card__correct-rate__bar" v-if="hasResult">
        <i>{{ totalScore }}分</i>
        <mt-progress :value="formatRatio(totalScoreRatio)" :bar-height="40"></mt-progress>
      </span>
      <span v-else>
        {{ $t('TeacherQuiz.label.nonscan') }}
      </span>
    </span>
  </div>
</template>

<script>
  export default {
    props: ['entry', 'is-first'],

    computed: {
      hasResult () {
        return (this.entry.quizAnswerResult !== null);
      },
      quizTaker () {
        return this.entry.quizTaker;
      },
      totalScore () {
        if (this.entry.quizAnswerResult !== null)
          return this.entry.quizAnswerResult.summary.totalScore;
      },
      totalScoreRatio () {
        if (this.entry.quizAnswerResult !== null)
          return this.entry.quizAnswerResult.summary.totalScoreRatio;
      }
    },

    methods: {
      formatRatio (ratio) {
        return Number((ratio * 100).toFixed(0));
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';
  @import 'src/assets/style/mixins';

  .taker-card {
    @include pressed(0, 0, 0, 0);

    position: relative;
    padding: 0 .3rem;
    height: 1.2rem;
    border-top: 1px solid $light-gray;
    font-size: $h4;
    line-height: 1.2rem;

    &.is-first {
      border-top: none;
    }

    &.is-dark {
      color: $gray;
    }

    &__taker-name,
    &__taker-number,
    &__correct-rate {
      @include truncate(33%);

      display: inline-block;
      // width: 33%;
    }

    &__correct-rate {
      position: relative;

      &__bar {
        display: inline-block;
        width: 2.8rem;
        vertical-align: middle;
      }

      i {
        position: absolute;
        z-index: $high-z-index;
        top: .05rem;
        left: .1rem;
        color: $black;
        font-size: $h5;
      }
    }
  }
</style>
