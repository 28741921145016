// import Vue from 'vue';
// import { VueHammer } from 'vue2-hammer'

// VueHammer.config.press = {
//     time: 800
// };


// Vue.use(VueHammer)

import Vue from 'vue'
import Vue2TouchEvents from 'vue2-touch-events'

Vue.use(Vue2TouchEvents)