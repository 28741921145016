import i18n from '@/i18n';

export default [
  { value: 'chinese'  , label: i18n.t('__Common.subject.chinese')   },
  { value: 'math'     , label: i18n.t('__Common.subject.math')      },
  { value: 'english'  , label: i18n.t('__Common.subject.english')   },
  { value: 'physics'  , label: i18n.t('__Common.subject.physics')   },
  { value: 'chemistry', label: i18n.t('__Common.subject.chemistry') },
  { value: 'biology'  , label: i18n.t('__Common.subject.biology')   },
  { value: 'history'  , label: i18n.t('__Common.subject.history')   },
  { value: 'geography', label: i18n.t('__Common.subject.geography') },
  { value: 'politics' , label: i18n.t('__Common.subject.politics')  },
  { value: 'other'    , label: i18n.t('__Common.subject.other')     }
];
