<template>
  <div class="edit-subject">
    <div class="edit-subject__header">
      <mt-header fixed title="选择任教科目">
        <router-link to="/teacher/profile/edit" slot="left">
          <mt-button icon="back" @click="$emit('click-back-button')"></mt-button>
        </router-link>
      </mt-header>
    </div>
    <div class="edit-subject__main">
      <div
        v-for="(op, index) in subjectOptions"
        class="edit-subject__subject-item"
        :class="{ 'is-first': index === 0 }"
        :key="index"
        @click="selectSubject(op.value)">
        {{ op.label }}
      </div>
    </div>
  </div>
</template>

<script>
  import Toast     from '@/components/__mint-ui/Toast';
  import Indicator from '@/components/__mint-ui/Indicator';

  import subjectOptions from '@/services/subject-options';
  import webviewService from '@/services/webview';

  import urls from '@/api/teacher-urls';

  export default {
    data () {
      return {
        subjectOptions
      };
    },

    computed: {
      account () {
        return this.$store.state.session.account;
      }
    },

    methods: {
      __editSubject ({ subject }) {
        const url = urls['editSubject']();
        return this.$http.put(url, { subject });
      },

      selectSubject (subject) {
        const hasChanged = (subject !== this.account.subject);
        if (!hasChanged) return this.$emit('click-back-button');

        Indicator.open();
        this.__editSubject({ subject })
            .then(
              rep => {
                Indicator.close();
                Toast.showTip('编辑成功');

                this.$store.commit('UPDATE_SESSION_ACCOUNT', { key: 'subject', newVal: subject });
                this.$emit('click-subject-item');
              },
              err => {
                Indicator.close();
              }
            );
      }
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('profile.editAccount.editSubject', () => this.$emit('click-back-button'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('profile.editAccount.editSubject');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .edit-subject {
    &__header {
      height: 1.2rem;
    }

    &__main {
      margin-top: .5rem;
      padding-left: .3rem;
      background-color: $white;
      border-top: 1px solid $light-gray;
      border-bottom: 1px solid $light-gray;
    }

    &__subject-item {
      padding: .3rem 0;
      border-top: 1px solid $light-gray;
      font-size: $h4;

      &.is-first {
        border-top: none;
      }
    }
  }
</style>
