<template>
  <div class="item-report">
    <div class="item-report__table">
      <div class="item-report__table-header" @click="sortPopupVisible = true">
        <span class="item-report__table-header__item">题号</span>
        <span class="item-report__table-header__item">正确率</span>

        <span class="item-report__sort-button">
          <i class="fas fa-sort"></i>
        </span>
      </div>
      <sort-popup
        v-if="sortPopupVisible"
        :sort-key="sortKey"
        @click-sort-by-item-ordinal-button="sortByItemOrdinal"
        @click-sort-by-correct-rate-button="sortByCorrectRate"
        @click-mask="sortPopupVisible = false">
      </sort-popup>
      <div class="item-report__table-content">
        <list-sort-transition>
          <item-card
            v-for="(item, index) in items"
            :item="item"
            :key="item.itemOrdinal"
            :is-first="index === 0"
            @click-item="showItemModal">
          </item-card>
        </list-sort-transition>
      </div>
      <modal-transition>
        <item-modal
          v-if="itemModalVisible"
          :item="itemForModal"
          @close-modal="hideItemModal">
        </item-modal>
      </modal-transition>
    </div>
  </div>
</template>

<script>
  import ListSortTransition  from '@/components/ListSortTransition';
  import ModalTransition     from '@/components/ModalTransition';

  import SortPopup from './_item-report/SortPopup';
  import ItemCard  from './_item-report/ItemCard';
  import ItemModal from './_item-report/ItemModal';

  import Toast from '@/components/__mint-ui/Toast';

  export default {
    components: {
      ListSortTransition,
      ModalTransition,
      SortPopup,
      ItemCard,
      ItemModal
    },

    props: ['report'],

    data () {
      return {
        sortKey: '',

        sortPopupVisible: false,
        itemModalVisible: false,
        itemForModal: null
      };
    },

    computed: {
      items () {
        return this.report.items;
      }
    },

    methods: {
      sortByItemOrdinal () {
        this.items.sort((item1, item2) => item1.itemOrdinal - item2.itemOrdinal);
        this.sortKey = 'itemOrdinal';
        this.sortPopupVisible = false;
      },
      sortByCorrectRate () {
        this.items.sort((item1, item2) => {
          if (item1.averageScoreRatio === null)
            return 1;
          else if (item2.averageScoreRatio === null)
            return -1
          else
            return (item2.averageScoreRatio - item1.averageScoreRatio);
        });

        this.sortKey = 'correctRate';
        this.sortPopupVisible = false;
      },

      showItemModal (item) {
        if (item.type === 'subjectiveSkip') {
          Toast.showMessage('该题为主观题');
        }
        else {
          this.itemForModal = item;
          this.itemModalVisible = true;
        }
      },
      hideItemModal () {
        this.itemForModal = null;
        this.itemModalVisible = false;
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';
  @import 'src/assets/style/mixins';

  .item-report {
    &__table-header {
      @include pressed(0, 0, 0, 0);

      position: relative;
      padding: .3rem;
      display: flex;

      &__item {
        flex: 1;
        font-size: $h5;
        // font-weight: bold;
      }
    }

    &__sort-button {
      position: absolute;
      top: .25rem;
      right: .3rem;
      font-size: $h4;
    }

    &__table-content {
      background-color: $white;
      border-bottom: 1px solid $light-gray;
    }
  }
</style>
