import store from '@/store'
import {
    findWhere
} from 'underscore';

function loadSdk() {
    // console.log('loadSdk 2')
    let script = window.document.createElement('script');
    script.src = './static/gradecam/sdk/gcsdk_noui_6.5.1.3.0b.min.js';
    // script.src = 'https://cdn-qnhd.menco.cn/assets/vendors/gradecam/gc/noplugin/6.5.1.3/gcsdk_noui_6.5.1.3.0b.min.js'

    script.onload = function () {
        console.log('checking weather conditions. is it raining?')
    }

    script.onerror = function (err) {
        console.log(err, 'error')
    }
    window.document.body.appendChild(script);
};

function renderCamera($container) {
    $container.appendChild(window.gradecam.getElement());
    window.document.querySelector('#gcplugin0').style.backgroundImage = '';
};

function setMode(examLength) {
    window.gradecam.setMode({
        type: 'exam',
        exam_length: examLength,
        auto_length: true
    });
};

function bindEvents({
    scanCallback,
    scanIssueCallback,
    validateCallback
}) {


    window.gradecam.bind('scan', scanCallback);

    window.gradecam.bind('scanIssue', scanIssueCallback);
    window.gradecam.setShowMessages(false);

    window.gradecam.setValidateCallback(validateCallback);
};

function unbindEvents() {
    window.gradecam.unbind('pluginLoad');
    window.gradecam.unbind('scan');

    window.gradecam.unbind('scanIssue');
    window.gradecam.setShowMessages(true);

    window.gradecam.setValidateCallback(function () {});
};

// function setDefaultCamera() {
//     window.gradecam.setOptions({
//         high_res: true,
//         selected_camera: findDefaultCamera()
//     })
// }

function setDefaultCameraOnFirstInit() {

    if (window.gradecam.getOption("selected_camera")) return;

    // console.log('no default camera found')

    let cameraList = window.gradecam.getCameraList()

    if (!cameraList || cameraList.length === 0) {
        return null
    }

    // 安卓机器，则启动最后一个摄像机
    if (/(Android)/i.test(window.navigator.userAgent.toLowerCase())) {
        window.gradecam.setOptions({
            "selected_camera": cameraList[cameraList.length - 1]
        });
    } else {
        for (let camera of cameraList) {
            if (/back|后|split/.test(camera.toLowerCase())) {
                window.gradecam.setOptions({
                    "selected_camera": camera
                });
            }
        }
    }
}


window._GCREGION = 'asia';
window._GCNOPLUGINVER = '6.5.1.3';

export default {
    init({
        $cameraContainer,
        examLength,
        students
    }, callbacks) {
        window.gradeCamOnAPILoad = () => {
            setMode(examLength);
            window.gradecam.eventHanders = callbacks;

            let initDone = false;
            window.setTimeout(() => {
                if (!initDone) callbacks.initFailedCallback();
            }, 20000);

            window.gradecam.bind('pluginLoad', () => {
                setDefaultCameraOnFirstInit();
                initDone = true;
                callbacks.pluginLoadCallback();
            });

            bindEvents(callbacks);
            renderCamera($cameraContainer);

            window.gradecam.___mencoApi = {};
            window.gradecam.___mencoApi.validateGcIdExistence = items => {
                let idItem = items.last();

                const isExist = Boolean(findWhere(students, {
                    studentQuizNumber: idItem.get('value')
                }));
                if (!isExist) idItem.set({
                    value: '',
                    validate: true
                });
            };
        };
        if (!window.gradecam) {
            loadSdk();
        } else {
            // let initCallback = callbacks.initCallback
            stop() // 防止离开(SPA)页面时，未调用 stop()

            setMode(examLength);
            window.gradecam.eventHanders = callbacks;

            let initDone = false;
            // window.setTimeout(() => {
            //     if (!initDone) callbacks.initFailedCallback();
            // }, 10000);

            // window.gradecam.bind('pluginLoad', () => {
            //     setDefaultCamera();
            //     initDone = true;
            //     callbacks.pluginLoadCallback();
            // });

            // console.log(window.gradecam.getOption("selected_camera"))
            window.gradecam.startCamera();
            initDone = true;
            callbacks.pluginLoadCallback();

            bindEvents(callbacks);
            renderCamera($cameraContainer);

            window.gradecam.___mencoApi = {};
            window.gradecam.___mencoApi.validateGcIdExistence = items => {
                let idItem = items.last();

                const isExist = Boolean(findWhere(students, {
                    studentQuizNumber: idItem.get('value')
                }));
                if (!isExist) idItem.set({
                    value: '',
                    validate: true
                });
            };
        }
    },

    getCameraList() {
        return window.gradecam.getCameraList()
    },

    getSelectedCamera() {
        return window.gradecam.getOption("selected_camera")
    },

    setCamera(camera) {
        // window.gradecam.stopCamera();
        // console.log('current-camera:', this.getSelectedCamera(), 'next-camera:', camera)
        window.gradecam.setOptions({
            "selected_camera": camera
        });
        // window.gradecam.startCamera();
    },

    restartCamera() {
        window.gradecam.stopCamera()
        setTimeout(() => {
            window.gradecam.startCamera();
        }, 1000);
    },

    pause() {
        unbindEvents();
    },
    resume() {
        bindEvents(window.gradecam.eventHanders);
    },
    stop() {
        unbindEvents()
        window.gradecam.stopCamera()
    }
};