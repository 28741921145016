import Vue from 'vue';
import Vuex from 'vuex';

import actions from './actions';
import mutations from './mutations';

import debug from './modules/debug'
import scanner from './modules/scanner'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    session: null,
    networkStatus: null,
    deviceInfo: null
  },
  actions,
  mutations,
  modules: {
    debug,
    scanner
  },
});