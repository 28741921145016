<template>
  <div class="back-top">
    <transition name="fade">
      <span
        class="back-top__circle"
        v-show="visible"
        @click="backTop()">
        <i class="back-top__arrow"></i>
      </span>
    </transition>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        visible: false
      }
    },

    methods: {
      backTop () {
        window.scrollTo(0, 0);
      }
    },

    mounted () {
      window.addEventListener('scroll', () => {
        if (window.scrollY > 2000)
          this.visible = true;
        else
          this.visible = false;
      });
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .back-top {
    &__circle {
      position: fixed;
      z-index: $highest-z-index;
      right: .5rem;
      bottom: 1.6rem;
      width: 1rem;
      height: 1rem;
      background-color: $blue;
      border-radius: 50%;
      color: $white;
      font-size: $h2;
      line-height: 1rem;
      opacity: .85;
      text-align: center;
    }

    &__arrow {
      position: absolute;
      top: .6rem;
      left: .3rem;
      width: .35rem;
      height: .35rem;
      border: solid .05rem $white;
      border-top-width: 0;
      border-left-width: 0;
      content: "";
      transform: translateY(-50%) rotate(-135deg);
      -webkit-transform: translateY(-50%) rotate(-135deg);
    }

    .fade-enter-active,
    .fade-leave-active {
      transition: all .3s ease;
    }

    .fade-enter,
    .fade-leave-to {
      transform: translateY(.3rem);
      opacity: 0;
    }
  }
</style>
