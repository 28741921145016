import { render, staticRenderFns } from "./EditStudent.vue?vue&type=template&id=2a66e2c7&scoped=true&"
import script from "./EditStudent.vue?vue&type=script&lang=js&"
export * from "./EditStudent.vue?vue&type=script&lang=js&"
import style0 from "./EditStudent.vue?vue&type=style&index=0&id=2a66e2c7&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a66e2c7",
  null
  
)

export default component.exports