<template>
  <div class="search-school">
    <div class="search-school__header">
      <span class="search-school__back-button" @click="$emit('click-back-button')">
        <back-icon></back-icon>
      </span>
      <input
        class="search-school__search-input"
        type="text"
        v-model="search"
        :placeholder="$t('Register.placeholder.searchSchool')">
    </div>
    <div class="search-school__list" v-show="schools.length > 0">
      <div class="search-school__list__prompt">
        {{ $t('Register.label.searchResult') }}
      </div>
      <div
        class="search-school__list__item"
        @click="$emit('click-school-item', school)"
        v-for="school in schools" :key="school.id">
        {{ school.name }}
      </div>
    </div>
  </div>
</template>

<script>
  import BackIcon from '@/components/BackIcon';
  import urls from '@/api/teacher-urls';
  import { debounce } from 'underscore';

  let __searchSchools = debounce(($vm, { search }) => {
    const url = urls['searchSchools']({ search });
    $vm.$http.get(url).then(
      rep => $vm.schools = rep.data.directorySchools
    );
  }, 300);

  export default {
    components: {
      BackIcon
    },

    data () {
      return {
        schools: [],
        search: ''
      };
    },

    watch: {
      search () {
        __searchSchools(this, { search: this.search });
      }
    },

    mounted () {
      window.setTimeout(() => { this.$el.querySelector('input').focus(); }, 300);
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .search-school {
    &__header {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: $header-height;
      background-color: $blue;
    }

    &__back-button {
      position: absolute;
      z-index: $high-z-index;
      top: 0;
      left: 0.25rem;
      color: $white;
      font-size: $h4;
      line-height: $header-height;
      text-align: center;
    }

    &__search-input {
      position: absolute;
      top: .5rem;
      left: 1.2rem;
      padding: 0 0 .1rem .1rem;
      width: 80%;
      background-color: $blue;
      border: none;
      border-bottom: 1px solid $white;
      color: $white;
      font-size: $h4;

      &::-webkit-input-placeholder {
        color: $white;
      }
    }

    &__list {
      margin-top: 1.2rem;
      background-color: $white;

      &__prompt {
        padding-left: .25rem;
        height: .6rem;
        background-color: $light-gray;
        font-size: $h5;
        line-height: .6rem;
      }

      &__item {
        margin: 0 .25rem;
        height: 1rem;
        border-bottom: 1px solid $light-gray;
        font-size: $h4;
        line-height: 1rem;
      }
    }
  }
</style>
