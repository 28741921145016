export default {
  __Common: {
    loading: '加载中...',
    footerTabBar: {
      quiz: '测验',
      class: '班级',
      discover: '发现',
      me: '我的'
    },
    loadMore: {
      loading: '正在加载中...',
      allLoaded: '已经到底啦 ＞﹏＜'
    },
    action: {
      edit: '编辑',
      remove: '删除'
    },
    subject: {
      chinese: '语文',
      math: '数学',
      english: '英语',
      physics: '物理',
      chemistry: '化学',
      biology: '生物',
      history: '历史',
      geography: '地理',
      politics: '政治',
      other: '其他'
    },
    grade: {
      1: '一年级',
      2: '二年级',
      3: '三年级',
      4: '四年级',
      5: '五年级',
      6: '六年级',
      7: '初一',
      8: '初二',
      9: '初三',
      10: '高一',
      11: '高二',
      12: '高三'
    }
  },
  Login: {
    title: '登录',
    placeholder: {
      mobile: '手机号',
      password: '密码'
    },
    label: {
      login: '登录'
    },
    warning: {
      inputMobile: '请填写手机号'
    }
  },
  Register: {
    title: {
      newUser: '新用户注册',
      teacher: '老师注册',
      student: '学生注册'
    },
    registerByWechat: '使用微信注册',
    verificationCodePrompt: '已向您的手机{mobile}发送验证码，请填写:',
    registering: '注册中...',
    placeholder: {
      mobile: '手机号',
      password: '密码',
      verificationCode: '验证码',
      fullname: '姓名',
      accessCode: '访问码',
      school: '所在学校',
      subject: '任教科目',
      searchSchool: '请输入学校名称'
    },
    label: {
      iAmTeacher: '我是老师',
      iAmStudent: '我是学生',
      next: '下一步',
      done: '完成注册',
      resendCaptcha: '重新获取',
      resendCountdown: '已发送({countdown}s)',
      subject: '任课科目:',
      cancel: '取消',
      searchResult: '搜索结果'
    },
    warning: {
      selectRole: '请选择身份',
      inputMobile: '请填写手机号',
      inputPassword: '请填写密码',
      inputVerificationCode: '请填写验证码',
      inputFullname: '请填写姓名',
      inputAccessCode: '请填写访问码',
      inputSchool: '请填写所在学校',
      invalidMobile: '手机号格式错误',
      invalidPasswordContent: '密码只能为数字或字母',
      invalidPasswordLength: '密码长度需大于等于6'
    }
  },
  TeacherProfile: {
    title: {
      account: '个人信息',
      editFullname: '编辑姓名',
      editPassword: '修改密码',
      editSubject: '编辑科目'
    },
    placeholder: {
      newPassword: '新的密码',
      repeatedPassword: '重复密码',
      fullname: '姓名'
    },
    label: {
      notification: '通知',
      about: '关于',
      avatar: '头像',
      fullname: '姓名',
      mobile: '手机号',
      modifyPassword: '修改密码',
      subject: '科目',
      school: '学校',
      logout: '退出登录',
      confirm: '确认'
    },
    warning: {
      invliadFullname: '姓名不能为空',
      inputNewPassword: '请填写新的密码',
      inputRepeatedPassword: '请填写重复密码',
      invalidPasswordContent: '密码只能为数字或字母',
      invalidPasswordLength: '密码长度需大于等于6',
      invalidRepeatedPassword: '新密码与重复密码不一致',
    },
    message: {
      logout: '确认退出系统吗?',
      modifyPasswordDone: '操作成功,请重新登录系统!'
    }
  },
  TeacherQuizList: {
    title: {
      quiz: '测验',
      createQuiz: '创建测验',
      editQuiz: '编辑测验'
    },
    label: {
      new: '+ 新建',
      questionsCount: '{count}个试题',
      scansCount: '{count}个扫描',
      averageCorrectRate: '正确率',
      subject: '所属科目:',
      class: '参加班级:',
      next: '下一步',
      creationDone: '完成创建',
      nonscan: '未扫',
      editionDone: '确认'
    },
    placeholder: {
      quizName: '测验名称',
      questionsCount: '试题数量',
      quizDescription: '测验说明'
    },
    warning: {
      inputQuizName: '请填写测验名称',
      inputQuestionsCount: '请填写试题数量',
      invalidQuestionsCount: '试题数量范围1-100'
    },
    message: {
      removeQuizDone: '删除成功',
      createQuizDone: '创建成功',
      editQuizDone: '编辑成功'
    }
  },
  TeacherQuiz: {
    label: {
      bubblesheet: '答题卡',
      scan: '扫描',
      report: '报告',
      selectClasses: '请选择参加测验的班级:',
      downloadBubblesheet: '下载答题卡',
      scanBubblesheet: '扫描答题卡',
      classReport: '班级报告',
      questionReport: '试题报告',
      correctRate: '正确率',
      score: '测验得分',
      studentsCount: '学生总数',
      scannedCount: '扫描总数',
      studentName: '姓名',
      studentQuizNumber: '考号',
      nonscan: '未扫描',
      questionOrdinal: '题号',
      switch: '切换',
      scanOverview: '扫描情况',
      sortBystudentQuizNumber: '按考号排序',
      sortByCorrectRate: '按正确率排序',
      sortByQuestionOrdinal: '按题号排序',
      singleQuestion: '单选题',
      multipleQuestion: '多选题',
      booleanQuestion: '是非题',
      subjectiveQuestion: '主观题',
      point: '{score}分',
      edit: '编辑',
      remove: '删除',
      question: '试题',
      fullScore: '总分',
      noBubblePrompt: '主观题无选项',
      modifyCount: '修改试题数量',
      selectAll: '全选',
      unselectAll: '取消全选',
      removeSelected: '删除选中({count})',
      editSelected: '编辑选中({count})',
      confirm: '确认'
    },
    placeholder: {
      questionsCount: '试题数量(1-100)',
      questionScore: '试题分数'
    },
    warning: {
      selectClasses: '请选择参加测验的班级',
      setBubblesheet: '请先设置答题卡',
      scanBubblesheet: '请先扫描答题卡'
    }
  },
  TeacherClassroomList: {
    classroomNamePrompt: '建议格式: 2017级16班',
    title: {
      class: '班级',
      createClassroom: '创建班级',
      editClassroom: '编辑班级'
    },
    label: {
      new: '+ 新建',
      session: '{session}学年',
      studentsCount: '{count}个学生',
      creationDone: '完成创建',
      editionDone: '确认'
    },
    placeholder: {
      classroomName: '班级名称'
    },
    warning: {
      inputClassroomName: '请填写班级名称'
    },
    message: {
      removeClassroomDone: '删除成功',
      createClassroomDone: '创建成功',
      editClassroomDone: '编辑成功'
    }
  },
  TeacherClassroom: {
    studentQuizNumberPrompt: '温馨提示: 学生考号只能为3到10位的数字',
    createStudentPrompt: '当前班级暂无学生，请添加',
    title: {
      createStudent: '创建学生',
      editStudent: '编辑学生'
    },
    label: {
      studentName: '姓名',
      studentQuizNumber: '考号',
      accessCode: '访问码',
      confirm: '确认',
      success: '创建成功',
      done: '完成创建',
      continue: '继续创建',
      sort: '拼音排序',
      removeStudents: '删除学生',
      createStudent: '创建学生',
      remove: '- 删除({count})'
    },
    placeholder: {
      studentName: '学生姓名',
      studentQuizNumber: '学生考号'
    },
    warning: {
      inputStudentName: '请输入学生姓名',
      inputstudentQuizNumber: '请输入学生考号',
      invalidstudentQuizNumber: '考号须为3到10位的数字'
    },
    message: {
      studentInfo: '姓名: {studentName}, 考号: {studentQuizNumber}',
      removeStudents: '确认删除选中的{count}名学生?'
    }
  },
  StudentQuizList: {
    title: '测验',
    label: {
      point: '分',
      nonscore: '暂未评分',
      sortByScore: '得分排序',
      sortByDate: '时间排序'
    }
  },
  StudentProfile: {
    title: {
      me: '我',
      accessCode: '访问码',
      bind: '绑定'
    },
    label: {
      accessCode: '访问码',
      notification: '通知',
      about: '关于',
      unbind: '解绑',
      boundDate: '绑定时间: {date}',
      boundClass: '绑定班级: ',
      created: '创建的',
      boundStudent: '绑定学生: {name}',
      bind: '+ 绑定',
      next: '下一步',
      confirmBind: '确认绑定'
    },
    warning: {
      inputAccessCode: '请输入访问码',
      invalidAccessCode: '访问码不存在'
    },
    placeholder: {
      accessCode: '访问码'
    },
    message: {
      unbindAccessCode: '确认解绑{teacherName}创建的{className}中的{studentName}同学吗?',
      bindAccessCode: '确认绑定到{teacherName}创建的{className}中的{studentName}同学吗?',
      successfulBind: '绑定成功'
    }
  }
};
