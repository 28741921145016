<template>
  <div class="quiz-list">
    <div v-if="quizListVisible">
      <div v-if="quizzes === null">
        <div class="quiz-list__header">
          <mt-header fixed title="测验"></mt-header>
        </div>
        <div class="quiz-list__main is-retry">
          <div class="quiz-list__retry" v-show="retryVisible" @click="retry__fetchQuizzes()">
            测验列表获取失败，点击重试
          </div>
        </div>
        <div class="quiz-list__footer">
          <footer-tab-bar current-tab="quiz" fixed></footer-tab-bar>
        </div>
      </div>

      <div v-else>
        <div class="quiz-list__header">
          <mt-header fixed :title="$t('TeacherQuizList.title.quiz')"></mt-header>
          <span
            class="quiz-list__new-button"
            @click="createQuizVisible = true">
            {{ $t('TeacherQuizList.label.new') }}
          </span>
        </div>
        <div class="quiz-list__main">
          <div v-if="quizzes.length > 0">
            <list-transition>
              <quiz-card
                v-for="quiz in quizzes"
                :quiz="quiz"
                :key="quiz.quizId"
                @remove-quiz-done="retry__fetchQuizzes()"
                @duplicate-quiz-done="retry__fetchQuizzes()">
              </quiz-card>
            </list-transition>
            <div class="quiz-list__count">
              {{ quizzesTotal }}个测验
            </div>
          </div>
          <div class="quiz-list__create-quiz" v-else>
            <div class="quiz-list__create-quiz-prompt">
              您尚未创建测验，请添加
            </div>
            <mt-button
              class="quiz-list__create-quiz-button"
              type="primary"
              @click="createQuizVisible = true">
              创建测验
            </mt-button>
          </div>
        </div>
        <div class="quiz-list__footer">
          <back-top></back-top>
          <footer-tab-bar current-tab="quiz" fixed></footer-tab-bar>
        </div>
        <top-refresh @refresh="retry__fetchQuizzes()"></top-refresh>
      </div>
    </div>

    <slide-transition>
      <create-quiz
        @click-back-button="createQuizVisible = false"
        v-if="createQuizVisible">
      </create-quiz>
    </slide-transition>
  </div>
</template>

<script>
  import ListTransition  from '@/components/ListTransition';
  import SlideTransition from '@/components/SlideTransition';
  import TopRefresh      from '@/components/TopRefresh';
  import BackTop         from '@/components/BackTop';
  import FooterTabBar    from '@/components/teacher/FooterTabBar';

  import QuizCard   from './_quiz-list/QuizCard';
  import CreateQuiz from './_quiz-list/CreateQuiz';

  import Toast      from '@/components/__mint-ui/Toast';
  import Indicator  from '@/components/__mint-ui/Indicator';

  import checkAccess from '@/services/check-access';
  import urls from '@/api/teacher-urls';

  import { without } from 'underscore';

  export default {
    components: {
      ListTransition,
      SlideTransition,
      TopRefresh,
      BackTop,
      FooterTabBar,
      QuizCard,
      CreateQuiz
    },

    data () {
      return {
        quizzes: null,
        quizzesTotal: null,

        createQuizVisible: false,

        retryVisible: false
      };
    },

    computed: {
      quizListVisible () {
        return !this.createQuizVisible;
      },

      networkStatus () {
        return this.$store.state.networkStatus;
      }
    },

    watch: {
      networkStatus (val, oldVal) {
        if (val === 'online' && oldVal === 'offline')
          if (this.retryVisible) this.retry__fetchQuizzes();
      }
    },

    methods: {
      __fetchQuizzes () {
        const url = urls['quizzes']();
        return this.$http.get(url);
      },

      fetchQuizzes () {
        Indicator.open(this.$i18n.t('__Common.loading'));

        this.__fetchQuizzes().then(
          rep => {
            this.quizzes      = rep.data.quizzes;
            this.quizzesTotal = rep.data.quizzesTotal;
            Indicator.close();
          },
          err => {
            this.retryVisible = true;
            Indicator.close();
          }
        );
      },

      retry__fetchQuizzes () {
        this.retryVisible = false;
        this.fetchQuizzes();
      }
    },

    created () {
      this.fetchQuizzes();
    },

    beforeCreate () {
      checkAccess('loggedIn');
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';
  @import 'src/assets/style/mixins';

  .quiz-list {
    &__header {
      position: relative;
      height: $header-height;
    }

    &__main {
      margin-top: .5rem;
      padding: 0 .3rem;

      &.is-retry {
        margin-top: 0;
        padding: 0 0 0 .3rem;
        background-color: $white;
        border-bottom: 1px solid $light-gray;
      }
    }

    &__retry {
      height: 2rem;
      font-size: $h5;
      line-height: 2rem;
      text-align: center;
    }

    &__count {
      padding: .6rem 0;
      color: $dark-gray;
      font-size: $h4;
      text-align: center;
    }

    &__create-quiz {
      text-align: center;
    }

    &__create-quiz-prompt {
      padding: .5rem 0 1rem 0;
      font-size: $h4;
    }

    &__create-quiz-button {
      margin-bottom: 1rem;
      width: 90%;
    }

    &__footer {
      height: 1.3rem;
    }

    &__new-button {
      @include pressed(0, 0, 0, 0);

      position: fixed;
      z-index: $highest-z-index;
      top: 0;
      right: 0;
      padding-right: .3rem;
      display: inline-block;
      width: 1.5rem;
      height: $header-height;
      color: $white;
      font-size: $h4;
      line-height: $header-height;
      text-align: right;
    }
  }
</style>
