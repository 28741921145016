<template>
  <div class="scan">
    <div class="scan__header">
      <div class="scan__back-button" @click="finishScan()">
        <back-icon></back-icon>
      </div>
      <div class="scan__quiz-name">
        <!-- <i class="fa fa-camera"></i> -->
        <span>{{ quizName }}</span>
      </div>
      <div class="scan__show-scan-guide" @click="showScanningGuideModal">
        <span>
          <!-- <i class="far fa-question-circle"></i> -->
          帮助
        </span>
      </div>
      <student-list-modal
        v-if="studentListModalVisible"
        :students="students"
        @close-modal="studentListModalVisible = false"
      ></student-list-modal>
    </div>
    <div class="scan__main">
      <div class="scan__camera-container"></div>
      <div class="scan__progress" v-if="students !== null && gcPluginLoaded" @click="showScanList()">
        <div class="scan__progress__bar">
          <mt-progress
            :value="formatRatio(scannedProgressRatio)"
            :bar-height="25"
          ></mt-progress>
        </div>
        <div class="scan__progress__prompt">
          <span
            >扫描进度: {{ scannedStudentsCount }} / {{ studentsCount }}</span
          >
          <span v-show="scannedProgressRatio === 1">😊</span>
        </div>
      </div>
      <div class="scan__camera" v-if="gcPluginLoaded">
        <div class="scan__camera__prompt" @click="showCameraSelect">
          <span>切换摄像头</span>
        </div>
      </div>
      <!-- <div class="scan__guide" v-if="gcPluginLoaded">
        <mt-palette-button content="+" @click="showScanningGuideModal">
          <div class="my-icon-button"></div>
        </mt-palette-button>
      </div> -->
      <div class="scan__scanning-result">
        <transition name="fade">
          <result-bar
            v-if="resultBarVisible"
            :result="scanningResult"
            @click-close-button="resultBarVisible = false"
          >
          </result-bar>
        </transition>
      </div>
      <modal-transition>
        <validate-modal
          v-if="validateModalVisible"
          :validate-obj="validateHandler.validateObj"
          :students="students"
          :quiz="quiz"
          @click-confirm-button="confirmValidation"
          @close-modal="cancelValidation"
        ></validate-modal>
      </modal-transition>
      <modal-transition>
        <student-select-modal
          v-if="studentSelectVisible"
          :scanObj="studentSelectHandler.scanObj"
          :students="students"
          @click-confirm-button="confirmStudentSelect"
          @close-modal="cancelStudentSelect"
        ></student-select-modal>
      </modal-transition>
      <modal-transition>
        <camera-select-modal
          v-if="cameraSelectModalVisible"
          :cameraList="gradecamCameraList"
          :selectedCameraIndex="selectedCameraIndex"
          @click-select-camera="selectCamera"
          @close-modal="cancelCameraSelect"
        ></camera-select-modal>
      </modal-transition>
      <modal-transition>
        <scanning-guide-modal
          v-if="scanningGuideModalVisible"
          @close-modal="closeScanningGuideModal"
        ></scanning-guide-modal>
      </modal-transition>
    </div>
  </div>
</template>

<script>
import ModalTransition from "@/components/ModalTransition";
import BackIcon from "@/components/BackIcon";

import ResultBar from "./_scan/ResultBar";
import ValidateModal from "./_scan/ValidateModal";
import StudentSelectModal from "./_scan/StudentSelectModal";
import StudentListModal from "./_scan/StudentListModal";
import CameraSelectModal from "./_scan/CameraSelectModal";
import ScanningGuideModal from "./_scan/ScanningGuideModal";

import Toast from "@/components/__mint-ui/Toast";
import Indicator from "@/components/__mint-ui/Indicator";
import MessageBox from "@/components/__mint-ui/MessageBox";

import urls from "@/api/teacher-urls";

import checkAccess from "@/services/check-access";
import webviewService from "@/services/webview";
import scanService from "@/services/__gradecam/scan";
import beep from "@/services/beep";

import { findWhere, pluck, indexOf, union } from "underscore";

export default {
  components: {
    ModalTransition,
    BackIcon,
    ResultBar,
    ValidateModal,
    StudentSelectModal,
    StudentListModal,
    CameraSelectModal,
    ScanningGuideModal,
  },

  data() {
    return {
      quiz: null,
      students: null,
      classrooms: null,

      userMediaError: null,

      gcPluginLoaded: false,

      scanningResult: null,
      duplicateIdIssueCount: 0,
      validateHandler: null,

      studentSelectHandler: null,
      studentSelectVisible: false,

      resultBarVisible: false,
      validateModalVisible: false,
      studentListModalVisible: false,

      messageLock: false, // 在提示扫描重复时 MessageBox 每次只显示一次
      currentScannedStudent: null,

      cameraList: [],
      gradecamCameraList: [],
      selectedCameraIndex: null,
      cameraSelectModalVisible: false,

      scanningGuideModalVisible: false,
    };
  },

  computed: {
    networkStatus() {
      return this.$store.state.networkStatus;
    },

    quizName() {
      return this.quiz === null ? "测验名称" : this.quiz.quizName;
    },
    studentsCount() {
      if (this.students !== null) return this.students.length;
    },
    scannedStudentsCount() {
      if (this.students !== null)
        return this.students.filter((s) => s.quizAnswerResult !== null).length;
    },
    scannedProgressRatio() {
      if (this.students !== null)
        return this.scannedStudentsCount / this.studentsCount;
    },
    scannerLowQualityAdapter() {
      return this.$store.getters.lowQualityAdapter
    }
  },

  watch: {
    networkStatus(val, oldVal) {
      if (val === "offline" && oldVal === "online") {
        scanService.pause();
        this.__showPrompt("网络连接不可用，请检查网络情况");
      }
    },
    scannerLowQualityAdapter: {
      handler: function() {
        scanService.restartCamera()
      }
    }
  },

  methods: {
    async navigatorGetCameraList() {
      const deviceList = await navigator.mediaDevices.enumerateDevices();

      return deviceList.filter((device) => device.kind === "videoinput");
    },

    __fetchQuiz({ quizId }) {
      const url = urls["quiz"]({
        quizId,
      });
      return this.$http.get(url);
    },
    __fetchClassrooms({ quizId }) {
      const url = urls["quizDataClassrooms"]({
        quizId,
      });
      return this.$http.get(url);
    },
    __saveScanResult({
      quizId,
      studentQuizNumber,
      classroomIds,
      gcData,
      overWrite,
    }) {
      const url = urls["quizDataGcScan"]({
        quizId,
      });
      return this.$http.post(url, {
        studentQuizNumber,
        classroomIds,
        gcData,
        overWrite,
      });
    },

    showScanningResult(quizTaker, quizAnswerResult) {
      if (this.resultBarVisible) {
        this.resultBarVisible = false;
        window.setTimeout(() => {
          this.__showScanningResult({
            quizTaker,
            quizAnswerResult,
          });
        }, 500);
      } else {
        this.__showScanningResult({
          quizTaker,
          quizAnswerResult,
        });
      }
    },

    __showScanningResult({ quizTaker, quizAnswerResult }) {
      // beep();

      this.resultBarVisible = true;
      this.scanningResult = {
        studentName: quizTaker.quizTakerSource.studentName,
        studentQuizNumber: quizTaker.quizTakerNumber,
        totalScore: quizAnswerResult.summary.totalScore,
        totalScoreRatio: quizAnswerResult.summary.totalScoreRatio,
      };
    },

    __showPrompt(message) {
      MessageBox.open(
        {
          message,
          closeOnClickModal: false,
          showConfirmButton: true,
          showCancelButton: true,
          confirmButtonText: "重启摄像头",
          cancelButtonText: "稍后再试",
        },
        () => window.location.reload(),
        () => this.finishScan()
      );
    },

    __initFailedCallback() {
      Indicator.close();
      this.__showPrompt("摄像头启动失败");
    },
    __pluginLoadCallback() {
      Indicator.close();
      window.document.querySelector("html").className = "is-scanning";
      this.gcPluginLoaded = true;

      this.gradecamCameraList = scanService.getCameraList();
    },
    beginSaveScan(scanObj) {
      if (
        this.currentScannedStudent === scanObj.gradecam_id &&
        !this.hasSelectedStudent
      ) {
        let currentStudent = findWhere(this.students, {
          studentQuizNumber: scanObj.gradecam_id,
        });

        if (
          this.resultBarVisible &&
          this.scanningResult.studentQuizNumber ===
            currentStudent.studentQuizNumber
        ) {
          return;
        }

        this.showScanningResult(
          {
            quizTakerSource: {
              studentName: currentStudent.studentName,
            },
            quizTakerNumber: currentStudent.studentQuizNumber,
          },
          {
            summary: {
              totalScore: currentStudent.quizAnswerResult.summary.totalScore,
              totalScoreRatio:
                currentStudent.quizAnswerResult.summary.totalScoreRatio,
            },
          }
        );

        // Toast.showMessage("已扫描");
        return;
      }

      this.hasSelectedStudent = false;
      this.resultBarVisible = false;
      beep();

      this.currentScannedStudent = scanObj.gradecam_id;

      let duplicateStudent = null;

      for (let student of this.students) {
        if (
          scanObj.gradecam_id === student.studentQuizNumber &&
          student.quizAnswerResult
        ) {
          duplicateStudent = Object.assign({}, student);
          break;
        }
      }

      if (duplicateStudent) {
        if (this.messageLock) {
          return;
        }

        scanService.pause();
        this.messageLock = true;

        let message = `${duplicateStudent.studentName}(${duplicateStudent.studentQuizNumber})的成绩已经存在，是否覆盖？`;

        MessageBox.confirm(
          {
            title: "该学号已被扫描",
            message: message,
            closeOnClickModal: false,
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: "覆盖",
            cancelButtonText: "不覆盖",
          },
          () => {
            // 覆盖
            this.messageLock = false;
            this.saveScanResult(scanObj, true);
            scanService.resume();
          },
          () => {
            this.messageLock = false;
            scanService.resume();
          }
        );
        return;
      }

      this.saveScanResult(scanObj, false);
    },
    saveScanResult(scanObj, overWrite = false) {
      this.__saveScanResult({
        quizId: this.quiz.quizId,
        classroomIds: pluck(this.classrooms, "classroomId"),
        studentQuizNumber: scanObj.gradecam_id,
        gcData: {
          answers: scanObj.answers,
        },
        overWrite: overWrite,
      }).then(
        (rep) => {
          let quizTaker = rep.data.quizTaker;

          let quizAnswerResult = rep.data.quizAnswerResult;

          this.showScanningResult(quizTaker, quizAnswerResult);

          // this.currentScannedStudent = null

          this.students = this.students.map((s) =>
            s.studentQuizNumber === quizTaker.quizTakerNumber
              ? Object.assign(s, {
                  quizAnswerResult,
                })
              : s
          );
        },

        (err) => {
          scanService.pause();

          if (
            err.data.ERRORS[0].ERROR_TYPE ===
            "QUIZDATA___QUIZTAKERSOURCE_ALREADYEXIST"
          ) {
            let message = err.data.ERRORS[0].ERROR_MESSAGE + " 是否覆盖？";
            MessageBox.open(
              {
                title: "该学号已被扫描",
                message: message,
                closeOnClickModal: false,
                showConfirmButton: true,
                showCancelButton: true,
                confirmButtonText: "覆盖",
                cancelButtonText: "不覆盖",
              },
              () => {
                // 覆盖
                this.saveScanResult(scanObj, true);
                scanService.resume();
              },
              () => {
                scanService.resume();
              }
            );
            return;
          }

          if (
            err.data.ERRORS[0].ERROR_TYPE ===
            "QUIZDATA___QUIZTAKERSOURCE_NOTFOUND"
          ) {
            console.log(scanObj);
            this.studentSelectHandler = { scanObj };
            // MessageBox.confirm({
            //   message: '学号不存在'
            // })
            this.studentSelectVisible = true;
            return;
          }

          this.__showPrompt("服务器出了点状况呀");
        }
      );
    },
    __scanCallback(scanObj) {
      if (this.duplicateIdIssueCount > 0) this.duplicateIdIssueCount = 0;

      this.beginSaveScan(scanObj);
    },
    __scanIssueCallback(issue) {
      switch (issue.type) {
        case "duplicateId":
          this.duplicateIdIssueCount++;
          if (this.duplicateIdIssueCount > 10)
            Toast.showMessage("不能连续扫描同一张噢＞﹏＜");
          break;
        case "examLength":
          Toast.showMessage("答题卡试题数量不足噢＞﹏＜");
          break;
        case "badStructure":
          Toast.showMessage("这张答题卡有点难扫噢＞﹏＜");
          break;
        case "networkError":
          Toast.showMessage("您的网络貌似有问题噢＞﹏＜");
          break;
      }
    },
    __validateCallback(validateObj, finish) {
      if (this.resultBarVisible) this.resultBarVisible = false;

      this.validateHandler = {
        validateObj,
        finish,
      };
      this.validateModalVisible = true;
    },

    confirmValidation(items) {
      this.hasSelectedStudent = true;

      this.validateHandler.finish(items);
      this.validateModalVisible = false;
      this.validateHandler = null;
    },
    cancelValidation() {
      this.validateHandler.finish(null);
      this.validateModalVisible = false;
      this.validateHandler = null;
    },

    confirmStudentSelect(scanObj) {
      this.saveScanResult(scanObj, false);
      this.studentSelectVisible = false;
      this.studentSelectHandler = null;
      scanService.resume();
    },

    cancelStudentSelect() {
      this.studentSelectVisible = false;
      this.studentSelectHandler = null;
      scanService.resume();
    },

    selectCamera(camera) {
      this.cameraSelectModalVisible = false;
      scanService.setCamera(camera);
      scanService.resume();
    },

    showCameraSelect() {
      const selectedCameraLabel = scanService.getSelectedCamera();
      // console.log(selectedCameraLabel)
      this.selectedCameraIndex = this.gradecamCameraList.findIndex(
        (camera) => camera === selectedCameraLabel
      );
      this.cameraSelectModalVisible = true;
      scanService.pause();
    },

    cancelCameraSelect() {
      this.cameraSelectModalVisible = false;
      scanService.resume();
    },

    showScanList() {
      if (this.resultBarVisible) this.resultBarVisible = false;
      this.studentListModalVisible = true;
    },

    showScanningGuideModal() {
      scanService.pause();
      this.scanningGuideModalVisible = true;
    },

    closeScanningGuideModal() {
      this.scanningGuideModalVisible = false;
      scanService.resume();
    },

    formatRatio(ratio) {
      return Number((ratio * 100).toFixed(0));
    },

    finishScan() {
      if (window.___env === "webview_scan") {
        let result = {
          scannedStudentsCount: this.scannedStudentsCount,
        };
        webviewService.finishScan(result);
      } else {
        this.$router.back();
      }
    },

    initGradeCam() {
      // console.log('init gradecam')
      scanService.init(
        {
          $cameraContainer: window.document.querySelector(
            ".scan__camera-container"
          ),
          examLength: this.quiz.quizPaper.bubbleSheetStructure.items.filter(
            (item) => item.type !== "subjectiveSkip"
          ).length,
          students: this.students,
        },
        {
          initFailedCallback: this.__initFailedCallback,
          pluginLoadCallback: this.__pluginLoadCallback,
          scanCallback: this.__scanCallback,
          scanIssueCallback: this.__scanIssueCallback,
          validateCallback: this.__validateCallback,
        }
        // {
        //   cameraContainer: window.document.querySelector(".scan__camera-container"),
        //   examLength: this.quiz.quizPaper.bubbleSheetStructure.items.filter(
        //     item => item.type !== "subjectiveSkip"
        //   ).length,
        //   autoLength: false
        // },
        // {
        //   initFailedCallback: this.__initFailedCallback,
        //   scanCallback: this.__scanCallback,
        //   scanIssueCallback: this.__scanIssueCallback,
        //   validateCallback: this.__validateCallback
        // }
      );
    },
  },

  async mounted() {
    window.addEventListener("android:onBackPressed", this.finishScan, false);

    Indicator.open("扫描准备中...");

    // first test camera permission
    let stream = null;
    try {
      stream = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: true,
      });
      stream.getTracks().forEach(function (track) {
        track.stop();
      });
      // this.cameraList = await this.navigatorGetCameraList()
      // console.log('mounted after permission camera list: ', this.cameraList)
      /* use the stream */
    } catch (err) {
      /* handle the error */
      this.userMediaError = err;
      //无法获取摄像头。请确保已开放权限
    }

    const quizId = this.$route.query.quizId;
    const classroomIds = this.$route.query.classroomIds.split(",");

    let fetchData = async ({ quizId }) => {
      let quizRep = await this.__fetchQuiz({
        quizId,
      });
      let classroomsRep = await this.__fetchClassrooms({
        quizId,
      });
      return {
        quizRep,
        classroomsRep,
      };
    };

    fetchData({
      quizId,
    }).then((rep) => {
      let quiz = rep.quizRep.data;
      let allClassrooms = rep.classroomsRep.data.classrooms;

      let selectedClassrooms = allClassrooms.filter(
        (c) => indexOf(classroomIds, c.classroomId) !== -1
      );
      let selectedStudents = union(...pluck(selectedClassrooms, "students"));

      this.quiz = quiz;
      this.classrooms = selectedClassrooms;
      this.students = selectedStudents;

      this.initGradeCam();
    });
  },

  beforeCreate() {
    checkAccess("loggedIn");
  },
  beforeDestroy() {
    scanService.stop();
    window.document.querySelector("html").className = "";
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/style/variables";
@import "src/assets/style/mixins";

.scan {
  &__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: $header-height;
    background-color: $blue;
    color: $white;
    line-height: $header-height;
    opacity: 0.8;
    display: flex;
  }

  &__back-button {
    padding-left: 0.3rem;
    width: 0.75rem;
    font-size: $h3;
    height: $header-height;
    line-height: $header-height;
  }

  &__quiz-name {
    // width: 70%;
    flex: 1;
    font-size: $h4;
    text-align: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: $header-height;
  }

  &__show-scan-guide {
    // @include pressed(0, 0, 0, 0);
    z-index: $highest-z-index;
    padding-right: 0.3rem;
    display: inline-block;
    width: 1rem;
    height: $header-height;
    color: $white;
    font-size: $h4;
    line-height: $header-height;
    text-align: right;
  }

  &__camera-container {
    position: absolute;
    z-index: $low-z-index;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__progress {
    position: absolute;
    top: 1.75rem;
    left: 0.3rem;
    opacity: 0.9;
    display: flex;
    align-items: center;
    width: 50%;
    border-radius: 3px;
    overflow: hidden;
    height: 25px;
    cursor: pointer;
    // z-index: $high-z-index;

    &__prompt {
      color: $black;
      font-size: $h4;
      font-style: normal;
      position: absolute;
      left: 0.2rem;
      // font-weight: $bold;
      // margin-bottom: 10px;
      // z-index: $higher-z-index;
    }

    &__bar {
      width: 100%;
      position: relative;
      // display: inline-block;
      // width: 3.5rem;
      // height: 100%;
      // vertical-align: middle;
      // position: absolute;
      // top: 0;
      z-index: -1;
    }
  }

  &__camera {
    position: absolute;
    top: 1.75rem;
    right: 0.3rem;
    opacity: 0.9;
    background: #27272794;
    border-radius: 3px;
    width: 2.5rem;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__prompt {
      color: $white;
      font-size: $h4;
      font-style: normal;
      // font-weight: $bold;
      // margin: 5px;
      // z-index: $high-z-index;
    }
  }

  &__guide {
    position: absolute;
    top: 50%;
    right: 0.3rem;
    opacity: 0.9;
    // background: #27272794;
    border-radius: 3px;
    width: 2.5rem;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;

    &__prompt {
      color: $white;
      font-size: $h4;
      font-style: normal;
      // font-weight: $bold;
      // margin: 5px;
      // z-index: $high-z-index;
    }
  }

  &__scanning-result {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }

  .fade-enter-active {
    transition: all 0.5s ease;
  }

  .fade-leave-active {
    transition: all 1s ease;
  }

  .fade-enter,
  .fade-leave-to {
    transform: translateY(3rem);
    opacity: 0;
  }
}
</style>