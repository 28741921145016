<template>
  <div class="student-card" :class="{ 'is-first': isFirst }" @click="$emit('click')">
    <span class="student-card__student-name">
      {{ student.studentName }}
    </span>
    <span class="student-card__student-quiz-number">
      {{ student.studentQuizNumber }}
    </span>
    <span class="student-card__arrow"></span>
  </div>
</template>

<script>
  export default {
    props: ['student', 'is-first']
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';
  @import 'src/assets/style/mixins';

  .student-card {
    @include pressed(0, 0, 0, 0);

    position: relative;
    padding-left: .3rem;
    height: 1.2rem;
    border-top: 1px solid $light-gray;
    font-size: $h4;
    line-height: 1.2rem;

    &.is-first {
      border-top: none;
    }

    &__student-name {
      @include truncate(6rem);
      display: inline-block;
      width: 6rem;
    }

    &__student-quiz-number {
      @include truncate(3rem);
      display: inline-block;
      width: 3rem;
    }

    &__arrow {
      position: absolute;
      top: 0;
      right: 0;
      display: inline-block;
      width: 1rem;
      height: 1.5rem;

      &:after {
        position: absolute;
        top: .6rem;
        right: .5rem;
        width: .15rem;
        height: .15rem;
        border: solid 2px $blue;
        border-top-width: 0;
        border-left-width: 0;
        content: "";
        transform: translateY(-50%) rotate(-45deg);
        -webkit-transform: translateY(-50%) rotate(-45deg);
      }
    }
  }
</style>
