<template>
  <div class="register">
    <div v-show="registerVisible">
      <div class="register__header">
        <mt-header fixed title="注册">
          <router-link to="" slot="left">
            <mt-button icon="back" @click="askIfConfirmBack()"></mt-button>
          </router-link>
        </mt-header>
      </div>
      <div class="register__main">
        <mt-field
          class="register__password"
          v-model="password"
          placeholder="密码(必填)"
          :type="passwordIsHidden ? 'password' : 'text'"
          disableClear>
        </mt-field>
        <span class="register__eye-icon" :class="{ 'is-dark': passwordIsHidden }">
          <i
            class="fa fa-eye-slash"
            v-if="passwordIsHidden"
            @click="passwordIsHidden = false">
          </i>
          <i
            class="fa fa-eye"
            v-else
            @click="passwordIsHidden = true">
          </i>
        </span>
        <mt-field
          class="register__fullname"
          v-model.trim="fullname"
          placeholder="姓名(必填)"
          type="text"
          disableClear>
        </mt-field>
        <div @click="selectSchoolVisible = true">
          <mt-field
            class="register__school"
            v-model="schoolName"
            placeholder="学校"
            type="text"
            disabled
            disableClear>
          </mt-field>
        </div>
        <div @click="selectSubjectVisible = true">
          <mt-field
            class="register__subject"
            placeholder="科目"
            :value="getSubjectLabel(subject)"
            type="text"
            disabled
            disableClear>
          </mt-field>
        </div>
        <mt-button
          class="register__done-button"
          type="primary"
          @click="done()">
          完成注册
        </mt-button>
      </div>
    </div>

    <slide-transition>
      <select-school
        v-if="selectSchoolVisible"
        @click-back-button="selectSchoolVisible = false;"
        @click-school-item="selectSchool">
      </select-school>
    </slide-transition>
    <slide-transition>
      <select-subject
        v-if="selectSubjectVisible"
        @click-back-button="selectSubjectVisible = false;"
        @click-subject-item="selectSubject">
      </select-subject>
    </slide-transition>
  </div>
</template>

<script>
  import SlideTransition from '@/components/SlideTransition';

  import Toast      from '@/components/__mint-ui/Toast';
  import Indicator  from '@/components/__mint-ui/Indicator';
  import MessageBox from '@/components/__mint-ui/MessageBox';

  import SelectSchool  from './_register/SelectSchool';
  import SelectSubject from './_register/SelectSubject';

  import subjectOptions from '@/services/subject-options';
  import webviewService from '@/services/webview';

  import urls         from '@/api/teacher-urls';
  import errorHandler from '@/api/error-handler';

  import { findWhere } from 'underscore';

  export default {
    components: {
      SlideTransition,
      SelectSchool,
      SelectSubject
    },

    props: ['registrationId'],

    data () {
      return {
        subjectOptions,

        password  : '',
        fullname  : '',
        schoolInfo: null,
        subject   : null,

        schoolName: '',
        passwordIsHidden: true,

        selectSchoolVisible: false,
        selectSubjectVisible: false
      };
    },

    computed: {
      registerVisible () {
        return (!this.selectSchoolVisible && !this.selectSubjectVisible);
      },

      passwordIsContentValid () {
        return /^[0-9a-zA-Z]+$/.test(this.password);
      },
      passwordIsLengthValid () {
        return this.password.length >= 6;
      }
    },

    methods: {
      __completeRegistration ({ registrationId, password, fullname, schoolInfo, subject }) {
        const url = urls['completeRegistration']();
        return this.$http.post(url, { registrationId, password, fullname, schoolInfo, subject });
      },

      getSubjectLabel (value) {
        return (value === null) ? '' : findWhere(this.subjectOptions, { value })['label'];
      },

      selectSchool (school) {
        this.schoolName = school.name;
        this.schoolInfo = { infoEnterType: 'directorySchool', directorySchoolCode: school.directorySchoolCode };
        this.selectSchoolVisible = false;
      },

      selectSubject (subjectOption) {
        this.subject = subjectOption.value;
        this.selectSubjectVisible = false;
      },

      askIfConfirmBack () {

        MessageBox.open(
          {
            message: '您正在注册门口易测账号，确定要取消么？',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: '继续注册',
            cancelButtonText: '取消注册'
          },
          () => {

          },
          () => {
            this.$emit('click-back-button');
          }
        );

      },

      done () {
        if (!this.password) {
          Toast.showWarning('请填写密码');
        }
        else if (!this.passwordIsContentValid) {
          Toast.showWarning('密码只能为数字或字母');
        }
        else if (!this.passwordIsLengthValid) {
          Toast.showWarning('密码长度需大于等于6');
        }
        else if (!this.fullname) {
          Toast.showWarning('请填写姓名');
        }
        else {
          Indicator.open('注册中...');

          let registrationId   = this.registrationId;
          let password         = this.password;
          let fullname         = this.fullname;

          let schoolInfo       = this.schoolInfo === null ? { infoEnterType: 'userInputSchool', userInputSchoolName: '' } : this.schoolInfo;
          let subject          = this.subject    === ''   ? null                                                          : this.subject   ;

          this.__completeRegistration({ registrationId, password, fullname, schoolInfo, subject })
              .then(
                rep => {
                  Indicator.close();
                  this.$store.dispatch('FETCH_SESSION').then(rep => this.$router.push({ name: 'teacherClassroomList' }));
                },
                err => {
                  Indicator.close();
                  errorHandler.handle(err);
                }
              );
        }
      }
    },

    created () {
      Toast.showMessage('您是第一次进入门口易测，请先完成注册');
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('enter.register', () => this.askIfConfirmBack());
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('enter.register');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .register {
    &__header {
      height: 1.2rem;
    }

    &__main {
      position: relative;
      margin-top: .3rem;
      text-align: center;
    }

    &__eye-icon {
      position: absolute;
      top: .3rem;
      right: .3rem;
      color: $blue;
      font-size: $h3;

      &:active {
        opacity: .5;
      }

      &.is-dark {
        color: $dark-gray;
      }
    }

    &__done-button {
      margin-top: .5rem;
      width: 90%;
    }
  }
</style>
