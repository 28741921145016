<template>
  <div class="nested-mask-layer"></div>
</template>

<script>
  import webviewService from '@/services/webview';

  const darkBlueStr   = '#082033';
  const darkerBlueStr = '#02060a';

  export default {
    beforeCreate () {
      webviewService.changeStatusBarColor(darkerBlueStr);
    },
    beforeDestroy () {
      webviewService.changeStatusBarColor(darkBlueStr);
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .nested-mask-layer {
    position: fixed;
    z-index: $highest-z-index + 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: .8;
    background: $black;
  }
</style>
