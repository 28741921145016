<template>
  <div class="sort-popup">
    <div class="sort-popup__content">
      <div
        class="sort-popup__taker-number"
        :class="{ 'is-active': sortKey === 'takerNumber' }"
        @click="$emit('click-sort-by-taker-number-button')">
        <i class="far fa-id-card"></i>
        <span>按考号排序</span>
      </div>
      <div
        class="sort-popup__correct-rate"
        :class="{'is-active': sortKey === 'correctRate' }"
        @click="$emit('click-sort-by-correct-rate-button')">
        <i class="fas fa-chart-line"></i>
        <span>按得分排序</span>
      </div>
      <div
        class="sort-popup__resulted-at"
        :class="{'is-active': sortKey === 'resultedAt' }"
        @click="$emit('click-sort-by-resulted-at-button')">
        <i class="far fa-clock"></i>
        <span>按扫描时间排序</span>
      </div>
    </div>
    <div class="sort-popup__mask" @click="$emit('click-mask')">
      <mask-layer></mask-layer>
    </div>
  </div>
</template>

<script>
  import MaskLayer from '@/components/MaskLayer';
  import webviewService  from '@/services/webview';

  export default {
    components: {
      MaskLayer
    },

    props: ['sortKey'],

    beforeCreate () {
      webviewService.pushBackButtonCallback('quiz.report.classroomReport.sortPopup', () => this.$emit('click-mask'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('quiz.report.classroomReport.sortPopup');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .sort-popup {
    letter-spacing: 0;

    &__content {
      position: fixed;
      z-index: $highest-z-index + 2;
      top: 40%;
      left: 10%;
      width: 80%;
      height: 3.9rem;
      border-radius: .05rem;
      background: $white;
    }

    &__taker-number,
    &__correct-rate,
    &__resulted-at {
      padding-left: .5rem;
      height: 1.3rem;
      font-size: $h4;
      line-height: 1.3rem;
      text-align: left;

      &.is-active {
        color: $blue;
      }

      i {
        width: .5rem;
        margin-right: .2rem;
      }
    }

    &__taker-number,
    &__correct-rate {
      border-bottom: 1px solid $light-gray;
    }
  }
</style>
