<template>
  <div class="send-to-email-modal">
    <div class="send-to-email-modal__content">
      <div class="send-to-email-modal__title">
        <span class="send-to-email-modal__back-button" @click="$emit('close-modal')">
          <back-icon></back-icon>
        </span>
        发送答题卡到邮箱
      </div>
      <mt-field
        v-model="email"
        placeholder="邮箱地址"
        class="send-to-email-modal__email"
        type="email"
        disableClear>
      </mt-field>
      <div>
        <mt-button
          class="send-to-email-modal__confirm-button"
          type="primary"
          @click="confirm()">
          {{ $t('TeacherQuiz.label.confirm') }}
        </mt-button>
      </div>
    </div>
    <div class="send-to-email-modal__mask" @click="$emit('close-modal')">
      <nested-mask-layer></nested-mask-layer>
    </div>
  </div>
</template>

<script>
  import BackIcon        from '@/components/BackIcon';
  import NestedMaskLayer from '@/components/NestedMaskLayer';

  import Toast from '@/components/__mint-ui/Toast';
  import webviewService  from '@/services/webview';

  export default {
    components: {
      BackIcon,
      NestedMaskLayer
    },

    data () {
      return {
        email: ''
      };
    },

    computed: {
      emailIsValid () {
        return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(this.email);
      }
    },

    methods: {
      confirm () {
        if (!this.email)
          Toast.showWarning('请输入邮箱地址');
        else if (!this.emailIsValid)
          Toast.showWarning('邮箱格式错误');
        else
          this.$emit('click-confirm-button', { email: this.email });
      }
    },

    mounted () {
      window.setTimeout(() => { this.$el.querySelector('input').focus(); }, 300);
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('quiz.bubblesheet.downloadBubblesheetModal.sendToEmailModal', () => this.$emit('close-modal'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('quiz.bubblesheet.downloadBubblesheetModal.sendToEmailModal');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .send-to-email-modal {
    &__content {
      position: fixed;
      z-index: $highest-z-index + 2;
      top: 4rem;
      left: 2%;
      width: 96%;
      background-color: $white;
      border-radius: .1rem;
    }

    &__title {
      height: 1.2rem;
      background-color: $blue;
      border-radius: .1rem .1rem 0 0;
      color: $white;
      font-size: $h4;
      line-height: 1.2rem;
      text-align: center;
    }

    &__back-button {
      position: absolute;
      top: 0;
      left: .15rem;
    }

    &__email {
      width: 100%;
    }

    &__confirm-button {
      margin: .5rem 5%;
      width: 90%;
    }
  }
</style>

