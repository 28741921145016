<template>
  <div class="camera-select-modal">
    <div class="camera-select-modal__content">
      <div class="camera-select-modal__title">
        <span class="camera-select-modal__back-button" @click="$emit('close-modal')">
          <back-icon></back-icon>
        </span>
        请选择摄像头
      </div>
      <div class="camera-select-modal__table">
        <div class="camera-select-modal__table-content">
          <div class="camera-select-modal__camera-card" v-for="(camera, index) in cameraList" :key="index"
            :class="{'selected' : index===selectedCameraIndex}" @click="selectCamera(camera)">
            <span class="camera-select-modal__camera-card__camera-name">
              {{ camera }}
            </span>
          </div>
        </div>
      </div>
      <div class="camera-select-modal__quality-settings">
        <el-switch :disabled="disabled" v-model="lqScanner" inactive-text="高清模式" active-text="兼容模式">
        </el-switch>
      </div>
    </div>
    <div class="camera-select-modal__mask" @click="$emit('close-modal')">
      <mask-layer></mask-layer>
    </div>
  </div>
</template>

<script>
  import BackIcon from "@/components/BackIcon";
  import MaskLayer from "@/components/MaskLayer";

  import Vue from 'vue';
  import {
    Switch
  } from 'element-ui';
  Vue.use(Switch);

  export default {
    components: {
      BackIcon,
      MaskLayer
    },

    props: ["cameraList", "selectedCameraIndex"],
    data() {
      return {
        disabled: false
      }
    },
    computed: {
      lqScanner: {
        get: function() {
          return this.$store.getters.lowQualityAdapter
        },
        // setter
        set: function(newValue) {
          if (newValue !== this.$store.getters.lowQualityAdapter)
          this.$store.commit('TOGGLE_LQADAPTER')
        }
      }
    },
    created() {
      // console.log(this.cameraList, this.selectedCameraIndex)
      // console.log(this.lqScanner)
      // if (window.scanner_adapter && window.scanner_adapter === 'lq') this.hqScanning = false
    },
    methods: {
      selectCamera(camera) {
        // console.log(camera)
        this.$emit('click-select-camera', camera)
      }
    },
    watch: {
      lqScanner: {
        handler: function() {
          this.disabled = true;
          setTimeout(() => {
            this.disabled = false;
          }, 2000)
        }
      }
    }
  };
</script>

<style lang="scss">
  @import "src/assets/style/variables";

  .camera-select-modal {
    color: $darkest-gray;

    &__content {
      position: fixed;
      z-index: $highest-z-index + 2;
      top: 3rem;
      bottom: 15%;
      left: 2%;
      width: 96%;
      background-color: $white;
      border-radius: 0.1rem;
    }

    &__title {
      height: 1.2rem;
      background-color: $blue;
      border-radius: 0.1rem 0.1rem 0 0;
      color: $white;
      font-size: $h4;
      line-height: 1.2rem;
      text-align: center;
    }

    &__back-button {
      position: absolute;
      top: 0;
      left: 0.15rem;
    }

    &__table {
      box-sizing: border-box;
      padding: 0.2rem 0;
      height: calc(100% - 2.4rem);
      display: flex;
      flex-direction: column;
    }

    &__table-header {
      display: flex;
      font-size: $h5;
      line-height: $h5;

      &__camera-name {
        flex: 2;
      }
    }

    &__table-content {
      margin-top: 0.2rem;
      // max-height: 8rem;
      overflow-y: auto;
    }

    &__camera-card {
      padding: 0 0.5rem;
      display: flex;
      font-size: $h4;
      height: 1.2rem;
      align-items: center;
      color: $dark-gray;
      cursor: pointer;
      // line-height: $h4;

      &.selected {
        background-color: #e4eff8;
        font-weight: bold;
        // color: $blue;
        color: $darkest-gray;
      }

      &__camera-name {
        flex: 2;
        white-space: nowrap;
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
    }

    &__confirm-button {
      margin: 0.5rem 5%;
      width: 90%;
    }

    &__quality-settings {
      height: 1.2rem;
      display: flex;
      justify-content: center;
      align-items: center;

      .el-switch__label span {
        font-size: $h4 !important;
      }
    }
  }
</style>