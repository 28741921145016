<template>
  <div class="about">
    <div class="about__header">
      <mt-header fixed title="关于">
        <router-link to="/settings" slot="left">
          <mt-button icon="back"></mt-button>
        </router-link>
      </mt-header>
    </div>
    <div class="about__main">
      <img class="about__logo" style="cursor:pointer" src="~images/logo.png" @click="debugClickCount+=1"/>
      <div class="about__current-version">
        <p class="about__title">门口易测</p>
        <p class="about__version"><small>{{$store.state.deviceInfo.platform}}app v{{`${$store.state.deviceInfo.appVersion}:${$store.state.deviceInfo.appBuild}`}}</small></p>
      </div>
      <div class="about__check-update" v-if="$store.state.deviceInfo.platform !== 'ios'" @click="update()">检查新版本</div>
    </div>
  </div>
</template>

<script>
  import MessageBox from '@/components/__mint-ui/MessageBox';
  import checkAccess from '@/services/check-access';
  import webviewService from '@/services/webview';

  export default {
    data() {
      return {
        debugClickCount: 0
      }
    },
    methods: {
      update() {
        webviewService.update()
      }
    },
    beforeCreate() {
      checkAccess('loggedIn');
      webviewService.pushBackButtonCallback('about', () => this.$router.push({
        name: 'teacherProfile'
      }));
    },
    beforeDestroy() {
      webviewService.popBackButtonCallback('about');
    },
    watch: {
      debugClickCount: {
        handler: function(val) {
          if (val >= 15) {
            this.$store.commit('TOGGLE_DEBUG');
            this.debugClickCount = 0;
          }
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .about {
    &__header {
      position: relative;
      height: $header-height;
    }

    &__main {
      // margin-top: 1rem;
      position: absolute;
      left: 0;
      right: 0;
      top: $header-height;
      bottom: 0;
      font-size: $h4;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }

    &__logo {
      width: 1.8rem;
      height: 1.8rem;
    }

    &__current-version {
      margin-top: .2rem;
    }

    &__title {
      margin: .2rem 0;
      font-weight: bold;
    }

    &__version {
      font-size: .3rem;
    }

    &__check-update {
      margin-top: .5rem;
      width: 100%;
      color: $dark-gray;
      font-size: $h5;
      text-align: center;
      text-decoration: underline;

      &:active {
        opacity: .5;
      }
    }
  }
</style>