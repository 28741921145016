import store from  '@/store';
import router from '@/router';

export default function checkAccess (required) {
  const current = store.state.session.status;

  if (current !== required) {
    if (current === 'guest')    router.push({ name: 'enter' });
    if (current === 'loggedIn') router.push({ name: 'teacherClassroomList' });
  }
};
