<template>
  <div class="remove-students">
    <div class="remove-students__header">
      <mt-header fixed :title="classroom.classroomName">
        <router-link to="" slot="left">
          <mt-button icon="back" @click="$emit('click-back-button')"></mt-button>
        </router-link>
      </mt-header>
      <span class="remove-students__remove-button" @click="confirmToRemove">
        {{ $t('TeacherClassroom.label.remove', { count: selectedStudentsCount }) }}
      </span>
    </div>
    <div class="remove-students__main">
      <div class="remove-students__student-list-header">
        <span class="remove-students__student-list-header__student-name">
          {{ $t('TeacherClassroom.label.studentName') }}
        </span>
        <span class="remove-students__student-list-header__student-quiz-number">
          {{ $t('TeacherClassroom.label.studentQuizNumber') }}
        </span>
        <span
          v-show="!isAllSelected"
          class="remove-students__select-all-button"
          @click="selectAll()">
          {{ $t('TeacherQuiz.label.selectAll') }}
        </span>
        <span
          v-show="isAllSelected"
          class="remove-students__unselect-all-button"
          @click="unselectAll()">
          {{ $t('TeacherQuiz.label.unselectAll') }}
        </span>
      </div>
      <div class="remove-students__student-list">
        <student-card
          v-for="(student, index) in students"
          @click-selector="student.selected = !student.selected"
          :key="index"
          :is-first="index === 0"
          :student="student">
        </student-card>
        <div class="remove-students__student-list__count">
          {{ students.length }}个学生
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import StudentCard from './_remove-students/StudentCard';

  import Toast      from '@/components/__mint-ui/Toast';
  import MessageBox from '@/components/__mint-ui/MessageBox';

  import urls from '@/api/teacher-urls';

  import webviewService from '@/services/webview';

  import { where, pluck } from 'underscore';

  export default {
    components: {
      StudentCard
    },

    props: ['classroom'],

    data () {
      return {
        // TODO: use vuex to sync students.
        students: this.classroom.students.map(s => Object.assign({}, s, { selected: false }))
      };
    },

    computed: {
      isAllSelected () {
        return !this.students.find(s => s.selected === false);
      },
      selectedStudentsCount () {
        return where(this.students, { selected: true })['length'];
      }
    },

    methods: {
      __removeStudents ({ classroomId, studentIds }) {
        const url = urls['classroomStudents']({ classroomId });
        return this.$http.delete(url, { body: { studentIds } });
      },

      selectAll () {
        this.students.forEach(s => s.selected = true);
      },
      unselectAll () {
        this.students.forEach(s => s.selected = false);
      },

      confirmToRemove () {
        if (this.selectedStudentsCount === 0)
          Toast.showWarning('请选择需要删除的学生');
        else
          MessageBox.open(
            {
              message: this.$i18n.t('TeacherClassroom.message.removeStudents', { count: this.selectedStudentsCount }),
              showConfirmButton: true,
              showCancelButton: true
            },
            () => {
              const classroomId = this.classroom.classroomId;
              const studentIds  = pluck(where(this.students, { selected: true }), 'studentId');

              this.__removeStudents({ classroomId, studentIds })
                  .then(rep => this.$emit('click-remove-button'));
            }
          );
      }
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('classroom.removeStudents', () => this.$emit('click-back-button'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('classroom.removeStudents');
    }
  }
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';
  @import 'src/assets/style/mixins';

  .remove-students {
    &__header {
      position: relative;
      height: $header-height;
    }

    &__main {
      background-color: $white;
      border-bottom: 1px solid $light-gray;
    }

    &__student-list {
      margin-top: .8rem;

      &__count {
        padding: .6rem 0;
        background-color: $light-gray;
        color: $dark-gray;
        font-size: $h4;
        text-align: center;
      }
    }

    &__student-list-header {
      position: fixed;
      z-index: $high-z-index;
      top: $header-height;
      padding-left: .3rem;
      width: 10rem;
      height: .8rem;
      background-color: $light-gray;
      font-size: $h5;
      line-height: .8rem;

      &__student-name {
        display: inline-block;
        width: 6rem;
      }

      &__student-quiz-number {
        display: inline-block;
        width: 3rem;
      }
    }

    &__select-all-button,
    &__unselect-all-button {
      position: absolute;
      right: .6rem;
      top: 0;
      color: $blue;

      &:active {
        opacity: .5;
      }
    }

    &__remove-button {
      @include pressed(0, 0, 0, -.2rem);

      position: fixed;
      z-index: $highest-z-index;
      top: 0;
      right: 0;
      padding-right: .3rem;
      display: inline-block;
      min-width: 1.5rem;
      height: $header-height;
      color: $white;
      font-size: $h4;
      line-height: $header-height;
      text-align: right;
    }
  }
</style>
