<template>
  <div class="edit-student">
    <div class="edit-student__header">
      <mt-header fixed :title="$t('TeacherClassroom.title.editStudent')">
        <router-link to="" slot="left">
          <mt-button icon="back" @click="$emit('click-back-button')"></mt-button>
        </router-link>
      </mt-header>
    </div>
    <div class="edit-student__main">
      <div class="edit-student__prompt">
        {{ $t('TeacherClassroom.studentQuizNumberPrompt') }}
      </div>
      <mt-field
        v-model.trim="studentName"
        class="edit-student__student-name"
        :placeholder="$t('TeacherClassroom.placeholder.studentName')"
        type="text"
        disableClear>
      </mt-field>
      <mt-field
        v-model.trim="studentQuizNumber"
        class="edit-student__student-quiz-number"
        :placeholder="$t('TeacherClassroom.placeholder.studentQuizNumber')"
        type="number"
        disableClear>
      </mt-field>
    </div>
    <div class="edit-student__footer">
      <mt-button
        class="edit-student__confirm-button"
        type="primary"
        @click="confirm()">
        {{ $t('TeacherClassroom.label.confirm') }}
      </mt-button>
    </div>

    <div class="edit-student__remove-student-prompt">
      <span @click="removeStudent()">删除学生</span>
    </div>
  </div>
</template>

<script>
  import Toast      from '@/components/__mint-ui/Toast';
  import Indicator  from '@/components/__mint-ui/Indicator';
  import MessageBox from '@/components/__mint-ui/MessageBox';

  import webviewService from '@/services/webview';

  import urls         from '@/api/teacher-urls';
  import errorHandler from '@/api/error-handler';

  export default {
    props: ['classroom', 'student'],

    data () {
      return {
        studentName: this.student.studentName,
        studentQuizNumber: this.student.studentQuizNumber
      }
    },

    computed: {
      studentQuizNumberIsValid () {
        let isNumber = /^[0-9]+$/.test(this.studentQuizNumber);
        let validDigit = (this.studentQuizNumber.length >= 3) && (this.studentQuizNumber.length <= 10);

        return isNumber && validDigit;
      },

      studentNameHasChanged () {
        return (this.studentName !== this.student.studentName);
      },
      studentQuizNumberHasChanged () {
        return (this.studentQuizNumber !== this.student.studentQuizNumber);
      }
    },

    methods: {
      __editStudent ({ classroomId, studentId, studentName, studentQuizNumber }) {
        const url = urls['classroomStudents']({ classroomId });
        return this.$http.put(url, { students: [{ studentId, studentName, studentQuizNumber }] });
      },
      __removeStudent ({ classroomId, studentId }) {
        const url = urls['classroomStudents']({ classroomId });
        return this.$http.delete(url, { body: { studentIds: [ studentId ] } });
      },

      confirm () {
        if (!this.studentName) {
          Toast.showWarning(this.$i18n.t('TeacherClassroom.warning.inputStudentName'));
        }
        else if (!this.studentQuizNumber) {
          Toast.showWarning(this.$i18n.t('TeacherClassroom.warning.inputstudentQuizNumber'));
        }
        else if (!this.studentQuizNumberIsValid) {
          Toast.showWarning(this.$i18n.t('TeacherClassroom.warning.invalidstudentQuizNumber'));
        }
        else if (!this.studentNameHasChanged && !this.studentQuizNumberHasChanged) {
          this.$emit('click-back-button');
        }
        else {
          const classroomId       = this.classroom.classroomId;
          const studentId         = this.student.studentId;
          const studentName       = this.studentName;
          const studentQuizNumber = this.studentQuizNumber;

          Indicator.open();
          this.__editStudent({ classroomId, studentId, studentName, studentQuizNumber })
              .then(
                rep => {
                  Indicator.close();
                  this.$emit('click-confirm-button');
                },
                err => {
                  Indicator.close();
                  errorHandler.handle(err);
                }
              );
        }
      },

      removeStudent () {
        MessageBox.open(
          {
            message: `确认删除${this.student.studentName}吗?`,
            showConfirmButton: true,
            showCancelButton: true
          },
          () => {
            this.__removeStudent({ classroomId: this.classroom.classroomId, studentId: this.student.studentId })
                .then(rep => this.$emit('click-remove-button'));
          }
        );
      }
    },

    mounted () {
      window.setTimeout(() => { this.$el.querySelector('input').focus(); }, 300);
    },

    beforeCreate () {
      webviewService.pushBackButtonCallback('classroom.editStudent', () => this.$emit('click-back-button'));
    },
    beforeDestroy () {
      webviewService.popBackButtonCallback('classroom.editStudent');
    }
  };
</script>

<style lang="scss" scoped>
  @import 'src/assets/style/variables';

  .edit-student {
    &__header {
      position: relative;
      height: $header-height;
    }

    &__main {
      margin-top: .3rem;
    }

    &__prompt {
      margin: .1rem .3rem;
      color: $dark-gray;
      font-size: $h5;
      text-align: left;
    }

    &__footer {
      text-align: center;
    }

    &__confirm-button {
      margin-top: .5rem;
      width: 9rem;
    }

    &__remove-student-prompt {
      margin-top: .8rem;
      width: 100%;
      color: $red;
      font-size: $h5;
      text-align: center;
      text-decoration: underline;

      span {
        &:active {
          opacity: .5;
        }
      }
    }
  }
</style>
